const __DEV__ = process.env.REACT_APP_ENV === 'development';

export class LogUtil {
  static BLACK_LIST_TAG: string[] = [
    /* BEGIN: Service Alerts */
    // 'service_alerts',
    // 'service-alerts-listener',
    // 'service-alerts-countryCode-observer',
    // 'service-alerts-payment-observer',
    /* END: Service Alerts */
    /* BEGIN: Smartlook */
    'smartlook',
    /* END: Smartlook */
    /* BEGIN: Push Notify */
    'push_notify',
    /* END: Notify */
    /* BEGIN: Firebase */
    'firebase',
    /* END: Firebase */
    /* BEGIN: IndexedDB */
    'indexed_db',
    /* END: Firebase */
    /* BEGIN: Service Worker */
    'service_worker',
    /* END: Service Worker */
  ];

  static getMessage(
    tag: string,
    message: string,
    type?: 'log' | 'warning' | 'error'
  ) {
    let msg = `🕵️‍♂️ -- ${tag} -- ${message}`;
    switch (type) {
      case 'error': {
        msg += ' -- 🛑🛑🛑';
        break;
      }
      case 'warning': {
        msg += ' -- 🚧🚧🚧🚧';
        break;
      }
      default: {
        break;
      }
    }
    return msg;
  }

  static getDescription(description?: any) {
    if (typeof description === 'string') {
      return description ?? '';
    }
    if (typeof description === 'object') {
      return JSON.stringify(description);
    }
    return description !== undefined ? `${description}` : '';
  }

  static log(
    tag: string,
    message: string,
    description?: any,
    type?: 'log' | 'warning' | 'error'
  ) {
    if (!__DEV__) {
      return;
    }
    if (this.BLACK_LIST_TAG.includes(tag)) {
      return;
    }
    let msg = this.getMessage(tag, message, type);
    const des = this.getDescription(description);
    console.log(msg, des);
  }

  static warning(tag: string, message: string, description?: any) {
    if (!__DEV__) {
      return;
    }
    if (this.BLACK_LIST_TAG.includes(tag)) {
      return;
    }
    let msg = this.getMessage(tag, message, 'warning');
    const des = this.getDescription(description);
    console.log(msg, des);
  }

  static error(tag: string, message: string, description?: any) {
    if (!__DEV__) {
      return;
    }
    if (this.BLACK_LIST_TAG.includes(tag)) {
      return;
    }
    let msg = this.getMessage(tag, message, 'error');
    const des = this.getDescription(description);
    console.log(msg, des);
  }
}
