/* eslint-disable no-unused-vars */
import { api } from 'api';
import CheckboxIcon from 'assets/icons/checkbox-icon.svg';
import CheckedIcon from 'assets/icons/checked-icon.svg';
import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import MasterCardIcon from 'assets/icons/payments/master-card-icon.svg';
import VisaIcon from 'assets/icons/payments/visa-icon.svg';
import PaymentSuccessfullyImage from 'assets/icons/txn-successful.svg';
import { TRANSACTION_PAYLOAD_INIT } from 'components/FormAddTransaction/func';
import { fetchTransactionInformation } from 'components/Transactions/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  ADD_TRANSACTION_MESSAGE_NAME,
  ADD_TRANSACTION_METHOD_BANK_NAME,
  ADD_TRANSACTION_METHOD_EWALLET_NAME,
  ADD_TRANSACTION_METHOD_PICKUP_NAME,
  ADD_TRANSACTION_OCCUPATION_NAME,
  ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME,
  ADD_TRANSACTION_PURPOSE_NAME,
  ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME,
  ADD_TRANSACTION_RECEIVER_GET_NAME,
  ADD_TRANSACTION_RELATIONSHIP_NAME,
  ADD_TRANSACTION_SOURCE_NAME,
  ADD_TRANSACTION_TICK_CHECKBOX_FIELD,
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  CREADIT_CARD_TYPE_MASTERCARD,
  CREADIT_CARD_TYPE_VISA,
  HHMT_ACCOUNT_NUMBER,
  HHMT_BSB,
  HHMT_EMAIL_PAY,
  HHMT_NAME,
  METHOD_DEPOSIT,
  METHOD_EPAYMENT,
  METHOD_PAYID,
  PAYMENT_ACCOUNT_FIELD,
  PAYMENT_ACCOUNT_NUMBER_FIELD,
  PAYMENT_AMOUNT_FIELD,
  PAYMENT_BSB_FIELD,
  PAYMENT_BY_DEPOSIT_NUMBER_NAME,
  PAYMENT_CREADIT_CARD_NO,
  PAYMENT_CREADIT_CARD_TYPE,
  PAYMENT_EMAIL_FIELD,
  PAYMENT_METHOD_TEXT_REFERENCE,
  PAYMENT_REFERENCE_FIELD,
  REMOX_ACCOUNT_NUMBER,
  REMOX_BSB,
  REMOX_EMAIL_PAY,
  REMOX_NAME,
  RESET_ADD_TRANSACTION_INFORMATION_STORE,
  RESET_PAYMENT_BY_DEPOSIT_STORE,
  STATUS_FAIL,
  STATUS_PROCESSING,
  STATUS_SUBMITTED,
  STATUS_SUCCESS,
  TRANSACTIONS_PREFIX,
} from 'constants';
import {
  format,
  formatter,
  masterCardNumberValid,
  visaNumberValid,
} from 'helpers';
import useAuth from 'hooks/useAuth';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import {
  SET_ADD_TRANSACTION_CURRENT_STEP,
  SET_ADD_TRANSACTION_INFORMATION,
  SET_CURRENT_RECEIVER,
  SET_SHOW_LOADING,
  SET_TRANSACTION_DETAILS,
} from 'store/action';
import styled from 'styled-components';
import { numberUtils, stringUtils } from 'utils/func';

import NotifyPayIcon from 'assets/icons/notify-pay-icon.svg';
import {
  default as HowToHHMTIcon,
  default as HowToREMOXIcon,
} from 'assets/ver2/icons/question-fill-icon.svg';
import DiscountsDrawer from 'components/Discount/components/DiscountsDrawer';
import {
  fetchDiscountsAvailable,
  findDiscountDetails,
  getDiscountDefault,
  handleApplyDiscount,
  handleRemoveDiscountApplied,
  renderDiscountImportantPopup,
} from 'components/Discount/func';
import InstructionPopup from 'components/Transactions/components/InstructionPopup';
import InstructionPopupMobile from 'components/Transactions/components/InstructionPopup/mobile';
import InformPopup from 'components/common/InformPopup';
import {
  ADD_TRANSACTION_STEP_REFERENCE,
  ADD_TRANSACTION_TICK_CHECKBOX,
  DESKTOP_MIN_WIDTH,
  HHMT_EMAIL,
  HHMT_PHONE,
  HHMT_PHONE_2,
  HOME_URL,
  REMOX_EMAIL,
  REMOX_PHONE,
  REMOX_PHONE_2,
  SURCHARGE_RATE,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import useCheckServiceAlerts from 'hooks/service-alerts/useCheckServiceAlerts';
import useServiceAlertsConfig from 'hooks/service-alerts/useServiceAlertsConfig';
import useServiceAlertsStore from 'hooks/service-alerts/useServiceAlertsStore';
import useServiceAlertsUtil from 'hooks/service-alerts/useServiceAlertsUtil';
import useScrollIntoView from 'hooks/useScrollIntoView';
import useScrollIntoViewDelay from 'hooks/useScrollIntoViewDelay';
import { isEmpty } from 'lodash';
import BodySectionHeader from '../../BodySectionHeader';
import CalculatorForm from '../../CalculatorForm/ver2';
import FormInputCopy from '../../FormInputCopy';
import FormPaymentByDeposit from '../../FormPaymentByDeposit';
import FormPaymentByDepositMobile from '../../FormPaymentByDeposit/mobile/ver2';
import FormTransactionDetails from '../../FormTransactionDetails';
import FormTransactionDetailsMobile from '../../FormTransactionDetails/mobile';
import PaymentMethodsGrid from '../../PaymentMethodsGrid/ver2';
import {
  ActionWrapper,
  ButtonCommonWrap,
  NextWrap,
} from '../../Remittance/ver2';

const initialFormField = RESET_ADD_TRANSACTION_INFORMATION_STORE.paymentMethod;
const initialFormCard = RESET_PAYMENT_BY_DEPOSIT_STORE;

// eslint-disable-next-line no-useless-escape
const phoneFormat = `[\"(##) #### ####\"]`;

const PaymentMethod = ({
  countryConfig,
  handleOnToggleCancelPopup,
  isDisabled,
}) => {
  const { t, locate } = useLang();
  const { state, dispatch } = useStore();
  const {
    addTransactionInformation,
    currentReceiver,
    addTransactionCurrentStep: currentStep,
  } = state;
  const {
    receiverPayoutMethod,
    remittance,
    additionalInformation,
    paymentMethod: paymentStore,
  } = addTransactionInformation;
  const { token } = useAuth();
  const { isHHMTTheme, theme } = useTheme();
  const query = useQuery();
  const { isMobile, isTablet, isDesktop } = useDetectDevice();
  const navigate = useNavigate();
  const tickRef = useRef(null);

  const { currentRateStore, currentRateAnotherStore } = remittance || {};

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [isTickChecked, setTickChecked] = useState(false);
  const [isShowTransactionDetails, setShowTransactionDetails] = useState(false);
  const [isOpenInstructionPopup, setOpenInstructionPopup] = useState(false);
  const [isShowFormPaymentByDeposit, setShowFormPaymentByDeposit] =
    useState(false);
  const [transactionInformation, setTransactionInformation] = useState(null);
  const [cardInformation, setCardInformation] = useState(initialFormCard);
  const [cardValidation, setCardValidation] = useState(initialFormCard);
  const [isShowDiscountInvalidPopup, setShowDiscountInvalidPopup] =
    useState(false);
  const [isOpenDiscountImportantPopup, setOpenDiscountImportantPopup] =
    useState(false);
  const [informationChangeBackup, setInformationChangeBackup] = useState(null);
  const [errorCode, setErrorCode] = useState('');

  const paymentStatus = query.get('paymentStatus');
  const refNumber = transactionInformation?.refNumber || query.get('refNumber');
  const isCreateNewTran = !isDisabled;
  const isUpdateTran = isDisabled;

  const {
    discountsAvailable,
    discountsInactive,
    discountApplied,
    discountDetails,
  } = paymentStore || {};
  const { discountAmount, totalAmountAfterDiscount } = discountApplied || {};
  const isDiscountApplied = Boolean(discountAmount);
  const discountDefault = getDiscountDefault({
    discountsAvailable,
    discountsInactive,
  });

  const { serviceAlertsByPayments: serviceAlertsStore } =
    useServiceAlertsStore();
  const { checkByPaymentMethod } = useCheckServiceAlerts();
  const { checkServiceAlertsOfPaymentMethodItemDisable } = checkByPaymentMethod;
  const { paymentMethod } = useServiceAlertsConfig();
  const { isPreventNextStep4 } = useServiceAlertsUtil();
  const isNextDisabled = false;
  const isDisplayInstructionAccordionSection =
    paymentMethod &&
    !checkServiceAlertsOfPaymentMethodItemDisable({
      serviceAlertsStore,
      paymentMethod,
    });

  const { isMakePayment } = useFormAddTransactionStore();
  const isHideDiscountSection = isDisabled
    ? isEmpty(discountApplied)
    : (!paymentMethod && isEmpty(discountApplied)) || isEmpty(discountApplied);

  useEffect(() => {
    setInformation(paymentStore);
  }, [paymentStore]);

  useEffect(() => {
    if (refNumber) {
      setTickChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refNumber]);

  const isPaymentSuccessfully = currentStep === 4;

  const countryCode = countryConfig?.countryCode;

  const payoutMethod = receiverPayoutMethod[ADD_TRANSACTION_PAYOUT_METHOD_NAME];

  const currencyCode = remittance?.currentCurrencySelected?.currencyCode;
  const isOnlyCurrency = !remittance.isAddAnotherReceivingCurrency;

  const isChangePaymentMethod =
    paymentStore?.paymentMethodTxn &&
    paymentMethod &&
    paymentStore?.paymentMethodTxn !== paymentMethod;

  const isShowHowToPayButton = [METHOD_DEPOSIT, METHOD_PAYID].includes(
    paymentMethod
  );

  const isShowSuccessfullyDesc = [METHOD_DEPOSIT, METHOD_PAYID].includes(
    paymentMethod
  );

  const sendAmount = numberUtils.mathRound(
    remittance[ADD_TRANSACTION_YOU_SEND_NAME] || 0
  );
  const sendAnotherAmount = numberUtils.mathRound(
    remittance[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] || 0
  );
  const sendTotalAmount = numberUtils.mathRound(sendAmount + sendAnotherAmount);

  const receiverAmount = Number(
    remittance[ADD_TRANSACTION_RECEIVER_GET_NAME] || 0
  );

  const feeAmount = numberUtils.mathRound(
    remittance?.feeAmount?.feeAmount || 0
  );

  const surchargeAmount =
    paymentMethod && paymentMethod === METHOD_EPAYMENT
      ? isDiscountApplied
        ? numberUtils.mathRound(totalAmountAfterDiscount * SURCHARGE_RATE) || 0
        : numberUtils.mathRound(
            numberUtils.mathRound(sendTotalAmount + feeAmount) * SURCHARGE_RATE
          )
      : 0;

  const totalAmount = isDiscountApplied
    ? numberUtils.mathRound(totalAmountAfterDiscount)
    : numberUtils.mathRound(feeAmount + sendTotalAmount);

  const totalPayAmount = isDiscountApplied
    ? paymentMethod === METHOD_EPAYMENT
      ? numberUtils.mathRound(
          Number(totalAmountAfterDiscount) +
            Number(totalAmountAfterDiscount) * SURCHARGE_RATE
        )
      : Number(totalAmountAfterDiscount)
    : numberUtils.mathRound(feeAmount + surchargeAmount + sendTotalAmount);

  const validRef0 = useRef(null);
  useScrollIntoView(
    validRef0,
    validation[ADD_TRANSACTION_PAYMENT_METHOD_NAME],
    [validation]
  );

  const validRef1 = useRef(null);
  useScrollIntoViewDelay(validRef1, paymentMethod && validRef1, [
    paymentMethod,
    validRef1,
  ]);

  useEffect(() => {
    const isAllowFetchDiscountsAvailable =
      !isDisabled &&
      isEmpty(discountApplied) &&
      isEmpty(discountsAvailable) &&
      isEmpty(discountsInactive);
    if (isAllowFetchDiscountsAvailable) {
      fetchDiscountsAvailable({
        token,
        countryCode,
        currencyCode,
        isOnlyCurrency,
        sendAmount: sendTotalAmount,
        receiverAmount,
      });
    }
  }, [isDisabled, discountApplied, discountsAvailable, discountsInactive]);

  useEffect(() => {
    const isAutoTriggerApplyDiscount =
      !isEmpty(discountDefault) && isEmpty(discountApplied);
    if (isAutoTriggerApplyDiscount) {
      handleApplyDiscount({
        token,
        item: discountDefault,
        onApplyError: handleApplyDiscountError,
      });
    }
  }, [discountDefault, discountApplied]);

  const parseAmountValue = (value) =>
    value ? format.toAmountStr(numberUtils.mathRound(parseFloat(value))) : 0;

  const parseAmountFloatValue = (value) => {
    return value && format.toAmountFloatStr(numberUtils.mathRound(value));
  };

  const handleOnChangeTickChecked = () => {
    setTickChecked(!isTickChecked);

    if (!isTickChecked) {
      const newValidation = {
        ...validation,
        [ADD_TRANSACTION_TICK_CHECKBOX]: '',
      };
      setValidation(newValidation);
    }
  };

  const handleOnCloseTransactionDetailsPopup = () => {
    setShowTransactionDetails(false);
  };

  const handleOnToggleInstructionPopup = () => {
    setOpenInstructionPopup(!isOpenInstructionPopup);
  };

  const handleOnCloseFormPaymentByDeposit = () => {
    setShowFormPaymentByDeposit(false);

    setCardValidation(initialFormCard);

    Cookies.set(MAKE_PAYMENT_KEY, true);

    dispatch({ type: SET_CURRENT_RECEIVER, payload: null });

    navigate(`${TRANSACTION_ADD_PREFIX}?refNumber=${refNumber}`);
  };

  const handleOnClickDiscountImportantPopup = () => {
    if (informationChangeBackup) {
      setInformation(informationChangeBackup);

      dispatch({
        type: SET_ADD_TRANSACTION_INFORMATION,
        payload: {
          ...addTransactionInformation,
          paymentMethod: {
            ...paymentStore,
            ...informationChangeBackup,
            discountsAvailable: [],
            discountApplied: null,
          },
        },
      });

      setInformationChangeBackup(null);

      setValidation(initialFormField);
    }
  };

  const handleOnCloseDiscountImportantPopup = () => {
    setInformationChangeBackup(null);
  };

  const handleApplyDiscountError = (errorCode) => {
    setErrorCode(errorCode);
    if (isEmpty(discountDefault)) {
      setShowDiscountInvalidPopup(true);
    }
  };

  const handleDiscardDiscountInvalidPopup = () => {
    setShowDiscountInvalidPopup(false);
    if (isShowTransactionDetails) {
      handleRemoveDiscountApplied();
    }
  };

  const handleCloseDiscountInvalidPopup = () => {
    setShowDiscountInvalidPopup(false);

    if (isDesktop && !errorCode) {
      const discountDrawerCloseBtn =
        document.getElementsByClassName('btn-close')[0];

      if (discountDrawerCloseBtn) {
        discountDrawerCloseBtn.click();
      }
    }

    if (errorCode) {
      setErrorCode('');

      fetchDiscountsAvailable({
        token,
        countryCode,
        payoutMethod,
        currencyCode,
        isOnlyCurrency,
        sendAmount: sendTotalAmount,
        receiverAmount,
        paymentMethod,
      });
    }

    if (isShowTransactionDetails) {
      handleRemoveDiscountApplied();
    }
  };

  const handleNextStep = () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    if (!remittance?.isAddAnotherReceivingCurrency) {
      delete newInformation['rateAnother'];
      delete newValidation['rateAnother'];
    }

    if (!information[PAYMENT_CREADIT_CARD_NO]) {
      delete newInformation[PAYMENT_CREADIT_CARD_NO];
      delete newValidation[PAYMENT_CREADIT_CARD_NO];
    }

    if (!information[PAYMENT_CREADIT_CARD_TYPE]) {
      delete newInformation[PAYMENT_CREADIT_CARD_TYPE];
      delete newValidation[PAYMENT_CREADIT_CARD_TYPE];
    }

    delete newInformation['discountsAvailable'];
    delete newValidation['discountsAvailable'];

    delete newInformation['discountDetails'];
    delete newValidation['discountDetails'];

    delete newInformation['discountsInactive'];
    delete newValidation['discountsInactive'];

    delete newInformation['discountApplied'];
    delete newValidation['discountApplied'];

    if (isTickChecked) {
      delete newInformation[ADD_TRANSACTION_TICK_CHECKBOX];
      delete newValidation[ADD_TRANSACTION_TICK_CHECKBOX];
    }

    const isAllowSubmit =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value) &&
      isTickChecked;

    if (isAllowSubmit) {
      if (isPreventNextStep4) return;

      dispatch({
        type: SET_ADD_TRANSACTION_INFORMATION,
        payload: {
          ...addTransactionInformation,
          [ADD_TRANSACTION_STEP_REFERENCE[currentStep]]: information,
        },
      });

      setShowTransactionDetails(true);
    } else {
      if (!isTickChecked) {
        tickRef.current.scrollIntoView({ behavior: 'smooth' });
        newValidation = {
          ...newValidation,
          [ADD_TRANSACTION_TICK_CHECKBOX]: 'required',
        };
        setValidation(newValidation);
        return;
      }

      if (!paymentMethod) {
        newValidation = {
          ...newValidation,
          [ADD_TRANSACTION_PAYMENT_METHOD_NAME]:
            'form_choose_payment_method_invalid',
        };
      }

      setValidation(newValidation);
    }
  };

  const handlePayProcess = async (refNumber, data) => {
    setTransactionInformation(data);

    if (paymentMethod === METHOD_EPAYMENT) {
      setShowFormPaymentByDeposit(true);
    }

    if (paymentMethod === METHOD_PAYID || paymentMethod === METHOD_DEPOSIT) {
      dispatch({ type: SET_ADD_TRANSACTION_CURRENT_STEP, payload: 4 });
    }

    setShowTransactionDetails(false);

    dispatch({ type: SET_SHOW_LOADING, payload: false });
  };

  const getCurrency = (dmList, currencyCode) => {
    return dmList
      ?.find(
        (dm) =>
          dm.code === receiverPayoutMethod[ADD_TRANSACTION_PAYOUT_METHOD_NAME]
      )
      ?.currencies?.find((currency) => currency.code === currencyCode);
  };

  const getRemittance = () => {
    const remittances = [];

    remittances.push({
      id: isDisabled ? remittance?.remittanceId : 0,
      sendAmount: numberUtils.mathRound(
        remittance[ADD_TRANSACTION_YOU_SEND_NAME] || 0
      ),
      receiveAmount: numberUtils.mathRound(
        remittance[ADD_TRANSACTION_RECEIVER_GET_NAME] || 0
      ),
      currency: getCurrency(
        countryConfig?.dmList,
        remittance.currentCurrencySelected?.currencyCode
      ),
      rate: currentRateStore,
    });

    if (remittance.isAddAnotherReceivingCurrency) {
      remittances.push({
        id: isDisabled ? remittance?.remittanceAnotherId : 0,
        sendAmount: numberUtils.mathRound(
          remittance[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] || 0
        ),
        receiveAmount: numberUtils.mathRound(
          remittance[ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME] || 0
        ),
        currency: getCurrency(
          countryConfig?.dmList,
          remittance.currentCurrencySelectedAnother?.currencyCode
        ),
        rate: currentRateAnotherStore,
      });
    }

    return remittances;
  };

  const getPayout = () => {
    const payout = {
      dmCode: receiverPayoutMethod[ADD_TRANSACTION_PAYOUT_METHOD_NAME],
    };

    if (isUpdateTran) {
      if (receiverPayoutMethod[ADD_TRANSACTION_METHOD_PICKUP_NAME])
        return {
          ...payout,
          pickupOffice:
            receiverPayoutMethod[ADD_TRANSACTION_METHOD_PICKUP_NAME],
        };

      if (receiverPayoutMethod[ADD_TRANSACTION_METHOD_BANK_NAME])
        return {
          ...payout,
          bank: receiverPayoutMethod[ADD_TRANSACTION_METHOD_BANK_NAME],
        };

      if (receiverPayoutMethod[ADD_TRANSACTION_METHOD_EWALLET_NAME])
        return {
          ...payout,
          ewallet: receiverPayoutMethod[ADD_TRANSACTION_METHOD_EWALLET_NAME],
        };
    }

    if (isCreateNewTran) {
      if (receiverPayoutMethod[ADD_TRANSACTION_METHOD_PICKUP_NAME])
        return {
          ...receiverPayoutMethod[ADD_TRANSACTION_METHOD_PICKUP_NAME],
          ...payout,
        };

      if (receiverPayoutMethod[ADD_TRANSACTION_METHOD_BANK_NAME])
        return {
          ...receiverPayoutMethod[ADD_TRANSACTION_METHOD_BANK_NAME],
          ...payout,
        };

      if (receiverPayoutMethod[ADD_TRANSACTION_METHOD_EWALLET_NAME])
        return {
          ...receiverPayoutMethod[ADD_TRANSACTION_METHOD_EWALLET_NAME],
          ...payout,
        };
    }

    return payout;
  };

  const transactionPayload = {
    ...TRANSACTION_PAYLOAD_INIT,
    purpose: additionalInformation[ADD_TRANSACTION_PURPOSE_NAME]?.key,
    source: additionalInformation[ADD_TRANSACTION_SOURCE_NAME]?.key,
    job: additionalInformation[ADD_TRANSACTION_OCCUPATION_NAME]?.key,
    prevJob: additionalInformation[ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]?.key,
    message: additionalInformation[ADD_TRANSACTION_MESSAGE_NAME],
    subTotal: Number(sendTotalAmount),
    surcharge: Number(surchargeAmount),
    total: Number(totalAmount),
    totalPay: Number(totalPayAmount),
    receiverId: currentReceiver?.receiverId,
    payout: getPayout(),
    remittance: getRemittance(),
    fee: remittance.feeAmount,
    paymentMethod,
    relationship: additionalInformation[ADD_TRANSACTION_RELATIONSHIP_NAME]?.key,
    discount: isDiscountApplied ? discountApplied : null,
    discountAmount: isDiscountApplied ? discountAmount : 0,
    discountDetails: isDiscountApplied
      ? isUpdateTran
        ? discountDetails
        : findDiscountDetails(discountsAvailable, discountApplied?.code)
      : null,
  };

  const handleOnPay = async () => {
    dispatch({ type: SET_SHOW_LOADING, payload: true });

    if (isUpdateTran) {
      if (
        isDisabled &&
        !isChangePaymentMethod &&
        (paymentMethod === METHOD_PAYID || paymentMethod === METHOD_DEPOSIT)
      ) {
        dispatch({ type: SET_ADD_TRANSACTION_CURRENT_STEP, payload: 4 });

        setShowTransactionDetails(false);

        dispatch({ type: SET_SHOW_LOADING, payload: false });

        return;
      }

      if (
        isDisabled &&
        !isChangePaymentMethod &&
        paymentMethod === METHOD_EPAYMENT
      ) {
        try {
          const { data } = await api.getTran(token, refNumber);

          if (data) {
            handlePayProcess(refNumber, data);
          }
        } catch (error) {
          console.error(error?.message);

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }

        return;
      }

      try {
        const { data } = await api.updateTran(token, refNumber, {
          ...transactionPayload,
          refNumber,
          status: transactionInformation?.status || STATUS_SUBMITTED,
        });

        if (data) {
          handlePayProcess(refNumber, data);
        }
      } catch (error) {
        console.error(error?.message);

        if (isDiscountApplied) {
          setErrorCode(error?.response?.data?.errorCode);

          setShowDiscountInvalidPopup(true);
        }
      }

      return;
    }

    if (isCreateNewTran) {
      try {
        const payloadOccupation = {
          job: transactionPayload.job,
          previousJob: transactionPayload.prevJob,
        };

        const { data } = await api.createTran(token, transactionPayload);

        // handle save profile occupation
        const { data: dataOccupation } = await api.getOccupation(token);

        if (dataOccupation) {
          const profileJob = dataOccupation?.job;
          const profilePrevJob = dataOccupation?.previousJob;

          const isChangeOccupation = profileJob !== payloadOccupation?.job;
          const isChangePrevOccupation =
            profilePrevJob &&
            payloadOccupation?.previousJob &&
            profilePrevJob !== payloadOccupation?.previousJob;

          if (
            transactionPayload.job &&
            (isChangeOccupation || isChangePrevOccupation)
          ) {
            await api.saveOccupation(token, payloadOccupation);
          }
        }

        // handle save receiver relationship
        const receiverRelationship = currentReceiver?.relationship;

        const isChangeRelationship =
          receiverRelationship !== transactionPayload?.relationship;

        if (isChangeRelationship) {
          await api.updateRelationship(
            token,
            transactionPayload.receiverId,
            transactionPayload?.relationship
          );
        }

        if (data) {
          const { refNumber } = data;

          handlePayProcess(refNumber, data);
        }
      } catch (error) {
        console.error(error?.message);

        dispatch({ type: SET_SHOW_LOADING, payload: false });

        if (isDiscountApplied) {
          setErrorCode(error?.response?.data?.errorCode);

          setShowDiscountInvalidPopup(true);
        }
      }

      return;
    }

    try {
      const { data } = await api.getTran(token, refNumber);

      if (data) {
        handlePayProcess(refNumber, data);
      }
    } catch (error) {
      console.error(error?.message);

      dispatch({ type: SET_SHOW_LOADING, payload: false });
    }
  };

  const handleOnPayByDeposit = async (urlRedirect) => {
    const homeUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    const failPath = `${homeUrl}/tran?refNumber=${refNumber}&&paymentStatus=${STATUS_FAIL}`;

    if (urlRedirect) {
      window.location.href = urlRedirect;

      return;
    }

    window.location.href = failPath;

    handleOnCloseFormPaymentByDeposit();
  };

  const renderPaymentImage = (cardNumber) => {
    if (
      paymentStore[PAYMENT_CREADIT_CARD_TYPE] === CREADIT_CARD_TYPE_VISA ||
      visaNumberValid(cardNumber)
    ) {
      return isMobile ? (
        <img src={VisaIcon} width={37} height={12} alt="visa" />
      ) : (
        <img src={VisaIcon} width={49.54} height={18} alt="visa" />
      );
    }

    if (
      paymentStore[PAYMENT_CREADIT_CARD_TYPE] ===
        CREADIT_CARD_TYPE_MASTERCARD ||
      masterCardNumberValid(cardNumber)
    ) {
      return isMobile ? (
        <img src={MasterCardIcon} width={37} height={12} alt="master-card" />
      ) : (
        <img src={MasterCardIcon} width={49.54} height={18} alt="master-card" />
      );
    }

    return null;
  };

  const renderLoginToTextReference = {
    [METHOD_PAYID]: t('add_transaction_submitted_form_text_2'),
    [METHOD_DEPOSIT]: t('add_transaction_submitted_form_text_3'),
  };

  const renderSubmitedFormReference = {
    [METHOD_PAYID]: (
      <>
        <FormInputCopy
          field={PAYMENT_EMAIL_FIELD}
          value={isHHMTTheme ? HHMT_EMAIL_PAY : REMOX_EMAIL_PAY}
          inputStyles={{ textTransform: 'lowercase' }}
        />
        <FormInputCopy
          field={PAYMENT_AMOUNT_FIELD}
          value={Number(totalPayAmount)}
        />
        <FormInputCopy field={PAYMENT_REFERENCE_FIELD} value={refNumber} />
      </>
    ),
    [METHOD_DEPOSIT]: (
      <>
        <FormInputCopy
          field={PAYMENT_ACCOUNT_FIELD}
          value={isHHMTTheme ? HHMT_NAME : REMOX_NAME}
          inputStyles={{ textTransform: 'capitalize' }}
        />
        <FormInputCopy
          field={PAYMENT_BSB_FIELD}
          value={isHHMTTheme ? HHMT_BSB : REMOX_BSB}
        />
        <FormInputCopy
          field={PAYMENT_ACCOUNT_NUMBER_FIELD}
          value={isHHMTTheme ? HHMT_ACCOUNT_NUMBER : REMOX_ACCOUNT_NUMBER}
        />
        <FormInputCopy
          field={PAYMENT_AMOUNT_FIELD}
          value={Number(totalPayAmount)}
        />
        <FormInputCopy field={PAYMENT_REFERENCE_FIELD} value={refNumber} />
      </>
    ),
    [METHOD_EPAYMENT]: (
      <FieldsWrap style={{ marginBottom: isMobile ? '16px' : '32px' }}>
        <FieldWrap styles={{ alignItems: 'start' }}>
          <FieldLabel>{t('label_you_paid')}</FieldLabel>
          <FieldValue>
            {`${parseAmountValue(totalPayAmount)} AUD`}
            <br />
            <span className="surcharge">
              {`${t('label_includes_surcharge')}
              `}
            </span>
          </FieldValue>
        </FieldWrap>
        <FieldWrap>
          <FieldLabel>{t('label_payment_method')}</FieldLabel>
          <FieldValue>
            {renderPaymentImage(
              cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME]
            )}
            <span className="credit-card">
              {`${t('label_visa_end_with')} ${
                paymentStore[PAYMENT_CREADIT_CARD_NO] ||
                cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME].slice(-4)
              }
              `}
            </span>
          </FieldValue>
        </FieldWrap>
        <FieldWrap>
          <FieldLabel>{t('label_reference_number')}</FieldLabel>
          <FieldValue className="ref-number">{refNumber}</FieldValue>
        </FieldWrap>
      </FieldsWrap>
    ),
  };

  const getTransactionStatus = () => {
    if (paymentStatus === STATUS_SUCCESS) {
      return STATUS_PROCESSING;
    }

    return STATUS_SUBMITTED;
  };

  const handleViewYourTransaction = async () => {
    const transactionInformation = await fetchTransactionInformation(
      token,
      refNumber,
      getTransactionStatus()
    );

    dispatch({
      type: SET_TRANSACTION_DETAILS,
      payload: transactionInformation,
    });

    dispatch({
      type: SET_ADD_TRANSACTION_INFORMATION,
      payload: RESET_ADD_TRANSACTION_INFORMATION_STORE,
    });

    dispatch({
      type: SET_CURRENT_RECEIVER,
      payload: null,
    });

    dispatch({
      type: SET_ADD_TRANSACTION_CURRENT_STEP,
      payload: 0,
    });

    Cookies.remove(MAKE_PAYMENT_KEY);

    navigate(TRANSACTIONS_PREFIX);
  };

  const renderCalculatorFormMobile = () => {
    if (isDesktop) return null;
    return (
      <CalculatorForm
        countryConfig={countryConfig}
        isCalculatorFormAccordion={true}
      />
    );
  };

  const renderActionsButton = () => (
    <NextWrap style={{ width: isDesktop && '666px' }}>
      {renderCalculatorFormMobile()}
      <ActionWrapper>
        <ButtonCommonWrap>
          <ButtonCommon
            value={t('button_back')}
            onClick={handleBackStep}
            styles={{
              margin: '0px',
              width: !isDesktop && '100%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
            borderNameVariable={isDesktop && '--border-filter'}
            isFill={false}
          />
        </ButtonCommonWrap>
        <ButtonCommon
          value={t('button_review_and_pay')}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
          styles={{
            margin: '0px',
            width: isDesktop ? '206px' : '50%',
          }}
          onClick={handleNextStep}
          isDisabled={isNextDisabled}
          isUseKeyDown={!isNextDisabled}
        />
      </ActionWrapper>
    </NextWrap>
  );

  const renderDiscountInvalidPopup = (isOpen) => {
    /* Figma: https://www.figma.com/proto/xpUlBfRSnMMgPtyDAUAKzv/Profile?type=design&node-id=4135-81769&t=PwqxGUgCtP9mwarN-0&scaling=scale-down&page-id=1%3A16&starting-point-node-id=4135%3A81443&show-proto-sidebar=1 */
    // if (isShowTransactionDetails) {
    //   return (
    //     <InformPopup
    //       isOpen={isOpen}
    //       imageSrc={NoticeIcon}
    //       imagesStyles={{
    //         width: '124px',
    //         height: '100px',
    //         marginBottom: '8px',
    //       }}
    //       text={`${t('discount_check_invalid_title_1')}<br/>${t(
    //         'discount_check_invalid_title_2'
    //       )}`}
    //       content={[
    //         t('discount_check_invalid_desc_1'),
    //         t('discount_check_invalid_desc_2'),
    //       ]}
    //       buttonLabel1={t('button_discard')}
    //       onClose={handleDiscardDiscountInvalidPopup}
    //       buttonLabel2={t('button_ok')}
    //       onClick={handleCloseDiscountInvalidPopup}
    //       isBackdropOverlap={true}
    //       zIndexBackdropOverlap={1055}
    //     />
    //   );
    // }

    return (
      <InformPopup
        isOpen={isOpen}
        imageSrc={NoticeIcon}
        imagesStyles={{
          width: '124px',
          height: '100px',
          marginBottom: '8px',
        }}
        text={t('label_important')}
        content={t(
          errorCode
            ? `popup_discount_invalid_error_msg_${errorCode.toLowerCase()}`
            : `popup_discount_invalid_error_msg`
        )}
        alignContent="center"
        buttonLabel2={t('button_ok')}
        onClick={handleCloseDiscountInvalidPopup}
        isBackdropOverlap={true}
        zIndexBackdropOverlap={1055}
      />
    );
  };

  const handleBackStep = () => {
    dispatch({
      type: SET_ADD_TRANSACTION_CURRENT_STEP,
      payload: currentStep - 1,
    });
  };

  if (isPaymentSuccessfully) {
    return (
      <>
        {isOpenInstructionPopup && (
          <>
            {isMobile || isTablet ? (
              <InstructionPopupMobile
                isOpen={isOpenInstructionPopup}
                onClose={handleOnToggleInstructionPopup}
                method={paymentMethod}
                isBackdropTransparent={false}
              />
            ) : (
              <InstructionPopup
                isOpen={isOpenInstructionPopup}
                onClose={handleOnToggleInstructionPopup}
                method={paymentMethod}
                isBackdropTransparent={false}
              />
            )}
          </>
        )}

        <PaymentMethodSuccessStyled>
          <TitleImage src={PaymentSuccessfullyImage} />
          <TitleSubmitted>
            {stringUtils.replaceKeyword(
              t('add_transaction_submitted_successfully'),
              [
                {
                  key: 'refNumber',
                  value: refNumber,
                },
              ]
            )}
          </TitleSubmitted>
          {isShowSuccessfullyDesc && (
            <NotiPaySection>
              <img src={NotifyPayIcon} width={50} height={40.59} />
              <Label
                style={{
                  fontWeight: 700,
                }}
              >
                {t('add_transaction_submitted_form_text_1')}
              </Label>
            </NotiPaySection>
          )}
          {Boolean(renderLoginToTextReference[paymentMethod]) && (
            <Label style={{ marginBlock: isMobile ? '8px' : '16px' }}>
              {renderLoginToTextReference[paymentMethod]}
            </Label>
          )}
          {renderSubmitedFormReference[paymentMethod]}
          {isShowHowToPayButton && (
            <HowToPay onClick={handleOnToggleInstructionPopup}>
              {stringUtils.replaceKeyword(t('add_transaction_how_to_pay_by'), [
                {
                  key: 'paymentMethod',
                  value: t(PAYMENT_METHOD_TEXT_REFERENCE[paymentMethod]),
                },
              ])}
              <img
                src={isHHMTTheme ? HowToHHMTIcon : HowToREMOXIcon}
                width={24}
                height={24}
              />
            </HowToPay>
          )}
          <ContactInfoSection
            dangerouslySetInnerHTML={{
              __html: t('add_transaction_submitted_form_contact_info', {
                email: isHHMTTheme ? HHMT_EMAIL : REMOX_EMAIL,
                phone1: isHHMTTheme ? HHMT_PHONE : REMOX_PHONE,
                phone1_format: formatter.mask(
                  isHHMTTheme ? HHMT_PHONE : REMOX_PHONE,
                  formatter.formatParse(phoneFormat)
                ),
                phone2: isHHMTTheme ? HHMT_PHONE_2 : REMOX_PHONE_2,
                phone2_format: formatter.mask(
                  isHHMTTheme ? HHMT_PHONE_2 : REMOX_PHONE_2,
                  formatter.formatParse(phoneFormat)
                ),
              }),
            }}
          />
          <NextWrap
            style={{
              justifyContent: 'end',
              marginTop: '40px',
            }}
          >
            <ActionWrapper>
              <ButtonCommon
                value={t('button_view_your_transaction')}
                color="var(--c-primary)"
                background="var(--bg-primary)"
                isFill={true}
                styles={{
                  margin: '0px',
                  marginInline: 'auto',
                  width: isMobile ? '100%' : '211px',
                }}
                onClick={handleViewYourTransaction}
                isUseKeyDown
              />
            </ActionWrapper>
          </NextWrap>
        </PaymentMethodSuccessStyled>
      </>
    );
  }

  return (
    <>
      {isShowTransactionDetails && (
        <>
          {isMobile || isTablet ? (
            <FormTransactionDetailsMobile
              isOpen={isShowTransactionDetails}
              onClose={handleOnCloseTransactionDetailsPopup}
              onClick={handleOnPay}
            />
          ) : (
            <FormTransactionDetails
              isOpen={isShowTransactionDetails}
              onClose={handleOnCloseTransactionDetailsPopup}
              onClick={handleOnPay}
            />
          )}
        </>
      )}

      {isShowFormPaymentByDeposit && (
        <>
          {isMobile || isTablet ? (
            <FormPaymentByDepositMobile
              isOpen={isShowFormPaymentByDeposit}
              transactionInformation={transactionInformation}
              cardInformation={cardInformation}
              setCardInformation={setCardInformation}
              cardValidation={cardValidation}
              setCardValidation={setCardValidation}
              onClose={handleOnCloseFormPaymentByDeposit}
              onClick={handleOnPayByDeposit}
            />
          ) : (
            <FormPaymentByDeposit
              isOpen={isShowFormPaymentByDeposit}
              transactionInformation={transactionInformation}
              cardInformation={cardInformation}
              setCardInformation={setCardInformation}
              cardValidation={cardValidation}
              setCardValidation={setCardValidation}
              onClose={handleOnCloseFormPaymentByDeposit}
              onClick={handleOnPayByDeposit}
            />
          )}
        </>
      )}

      {isShowDiscountInvalidPopup &&
        renderDiscountInvalidPopup({
          isOpen: isShowDiscountInvalidPopup,
        })}

      {isOpenDiscountImportantPopup &&
        renderDiscountImportantPopup({
          t,
          isOpen: isOpenDiscountImportantPopup,
          setOpen: setOpenDiscountImportantPopup,
          paymentMethod,
          onCloseExt: handleOnCloseDiscountImportantPopup,
          onClickExt: handleOnClickDiscountImportantPopup,
          contentReplace:
            isUpdateTran &&
            t('discount_check_invalid_desc_3', {
              discountPaymentMethod: discountDetails?.paymentMethod,
              paymentMethodSelected:
                informationChangeBackup?.[ADD_TRANSACTION_PAYMENT_METHOD_NAME],
            }),
        })}

      <BodySectionHeader
        titleBodySection={
          isMakePayment
            ? t('add_transaction_title_make_payment')
            : t('add_transaction_title_new_transaction')
        }
        titleSection={t('add_transaction_title_receiver_payment')}
        CalculatorComponent={
          isDesktop && <CalculatorForm countryConfig={countryConfig} />
        }
        onToggleCancelPopup={handleOnToggleCancelPopup}
      />
      <PaymentMethodStyled $currentStep={currentStep}>
        <PaymentMethodsGrid
          countryConfig={countryConfig}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          informationChangeBackup={informationChangeBackup}
          setInformationChangeBackup={setInformationChangeBackup}
          setOpenDiscountImportantPopup={setOpenDiscountImportantPopup}
          isSelectRequired={validation[ADD_TRANSACTION_PAYMENT_METHOD_NAME]}
        />
        {!isHideDiscountSection && (
          <DiscountWrapper>
            <DiscountsDrawer onApplyError={handleApplyDiscountError} />
          </DiscountWrapper>
        )}
        <ParagraphCheckbox
          ref={tickRef}
          $isError={validation?.[ADD_TRANSACTION_TICK_CHECKBOX]}
          onClick={handleOnChangeTickChecked}
        >
          <FormCheckStyled
            name={ADD_TRANSACTION_TICK_CHECKBOX_FIELD.name}
            type={ADD_TRANSACTION_TICK_CHECKBOX_FIELD.type}
            label={ADD_TRANSACTION_TICK_CHECKBOX_FIELD.label}
            checked={isTickChecked}
            readOnly={ADD_TRANSACTION_TICK_CHECKBOX_FIELD.isReadOnly}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: stringUtils.replaceKeyword(
                t('form_checkbox_acknowledge_required'),
                [
                  {
                    key: 'lang',
                    value: locate,
                  },
                  {
                    key: 'host',
                    value: HOME_URL[theme],
                  },
                ]
              ),
            }}
          />
        </ParagraphCheckbox>
        {renderActionsButton()}
      </PaymentMethodStyled>
    </>
  );
};

const PaymentMethodStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -8px;
  padding-inline: 0px;
  padding-bottom: 200px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 100%;
    margin-top: 0px;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;
const PaymentMethodSuccessStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 24px;
  padding-inline: 0px;
  padding-bottom: 140px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    display: block;
    width: 437px;
    margin-top: -32px;
    margin-inline: auto;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

const TitleSubmitted = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: var(--ds-c-green-default);
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 16px;
  }
`;

const Label = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  padding: 0;
  margin: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const HowToPay = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-blue-hyperlink-default);
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 16px;

  & img {
    margin-left: 4px;
  }

  &:hover {
    color: var(--ds-c-blue-hyperlink-hover);

    & img {
      opacity: 0.6;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    margin-block: 32px;
  }
`;

const ParagraphCheckbox = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: ${(props) =>
    props.$isError ? 'var(--ds-c-red)' : 'var(--ds-c-grey-dark)'};
  padding: 0;
  margin: 0;
  margin-left: 0px;
  margin-top: 24px !important;
  cursor: pointer !important;
  user-select: none;
  width: 100%;

  a {
    color: ${(props) =>
      props.$isError ? 'var(--ds-c-red)' : 'var(--ds-c-blue)'};
    text-decoration: none;

    &:hover {
      color: ${(props) =>
        props.$isError ? 'var(--ds-c-red-hover)' : 'var(--ds-c-blue-hover)'};
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 0px;
    width: 666px;

    font-size: 14px;
    line-height: 17px;
  }
`;

const FormCheckStyled = styled(Form.Check)`
  width: fit-content;
  float: left;
  margin-bottom: 32px;
  margin-right: 10px;

  input:checked[type='checkbox'] {
    background-image: url(${CheckedIcon});
    background-size: cover;
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: url(${CheckboxIcon});
    background-size: cover;
    width: 26px;
    height: 26px;
    cursor: pointer !important;
    margin-top: 0px;

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 32px;
  }
`;

const FieldsWrap = styled.div`
  width: 100%;
  height: fit-content;
  padding: 8px 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
`;
const FieldWrap = styled.div`
  display: flex;
  align-items: ${(props) => props.styles?.alignItems || 'center'};
  justify-content: start;
  min-height: 40px;
  height: fit-content;
  padding: ${(props) => props.styles?.padding};
`;
const FieldLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  margin: 0;
  padding: 0;
  min-width: 140px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #0e1012;

  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 10px;

  & span {
    color: #7a8189;
  }

  & span.credit-card {
    margin-left: 8px;
    text-transform: initial;
    color: var(--ds-c-grey-dark);
  }

  & span.surcharge {
    font-size: 12px;
    font-weight: 500;
    text-transform: initial;
    color: var(--ds-c-grey-dark);
  }

  &.ref-number {
    font-family: var(--ff-secondary);
    font-weight: 700;
    color: var(--ds-c-blue);
  }
`;

const ContactInfoSection = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: normal;

  color: #0e1012;

  padding: 8px 20px;
  width: 100%;
  height: fit-content;
  background: var(--ds-c-grey-disabled);
  border-radius: 12px;

  & a {
    color: var(--ds-c-blue-hyperlink-default);
    text-decoration: none;
    &:hover {
      color: var(--ds-c-blue-hyperlink-hover);
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const NotiPaySection = styled.div`
  display: flex;
  gap: 10px;
  border-radius: 8px;
  padding: 8px 10px;
  background-color: var(--ds-c-yellow-light);
`;

const DiscountWrapper = styled.div`
  border-top: 1px dashed var(--ds-bg-2);
  border-bottom: 1px dashed var(--ds-bg-2);
  padding-block: 12px;
  margin-top: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 666px;
  }
`;

const TitleImage = styled.img`
  display: block;
  margin-inline: auto;
  margin-bottom: 32px;
`;

export default PaymentMethod;
