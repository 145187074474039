import { api } from 'api';
import DeleteIcon from 'assets/icons/delete-outline-icon.svg';
import EditIcon from 'assets/icons/edit-outline-icon.svg';
import {
  fetchReceiverInformation,
  fetchReceiversFiltered,
  getBodyPayloadFiltered,
} from 'components/Receivers/func';
import NoResult from 'components/Transactions/components/NoResult';
import ButtonLinkCommon from 'components/common/ButtonLinkCommon';
import SortIcon from 'components/common/Icons/SortIcon';
import {
  DASHBOARD_PREFIX,
  FAV_RECEIVER_TABLE_HEADING,
  PAGE_DEFAULT,
  RECEIVERS_EDIT_SUFIX,
  RECEIVERS_PREFIX,
  RECEIVER_TABLE_HEADING,
  RECEIVER_TABLE_HEADING_TRANSLATE,
  RECEIVER_TABLE_HEADING_UNABLE_SORT,
  RECEIVER_TABLE_SORT_ORDER_INITIAL,
  SORT_ASC,
  SORT_DESC,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import { sort } from 'helpers';
import Cookies from 'helpers/cookies';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useLocation, useNavigate } from 'react-router-dom';
import { SET_CURRENT_RECEIVER, SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import { arrayUtils, objectUtils } from 'utils';
import FormInputFavoriteCheckbox from '../FormInputFavoriteCheckbox';
import NoReceiver from '../NoReceiver';
import Pagination from '../Pagination';

import FormReceiverDetails from 'components/Receivers/components/FormReceiverDetails';
import { BUSINESS_OF_RECEIVER_TYPE, RECEIVER_FILTER_INITIAL } from 'constants';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import { isNull } from 'lodash';
import DeleteReceiverPopup from '../DeleteReceiverPopup';

const ReceiverTableSort = ({
  currentPage,
  totalPages,
  receivers,
  setReceivers,
  searchValue,
  applyFiltered,
  setTotalPages,
  setCurrentPage,
  sortOrder,
  setSortOrder,
  totalElements,
  setTotalElements,
  isFetching,
  setFetching,
  currentTab,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { currentReceiver } = state;
  const { token } = useAuth();
  const { pathname } = useLocation();
  const isDashboardPage = pathname.includes(DASHBOARD_PREFIX);
  const navigate = useNavigate();

  const [current, setCurrent] = useState('');
  const [isOpenReceiverDetailsPopup, setOpenReceiverDetailsPopup] =
    useState(false);
  const [isOpenDeleteReceiverPopup, setOpenDeleteReceiverPopup] =
    useState(false);

  const isAbleSort = (heading) =>
    !RECEIVER_TABLE_HEADING_UNABLE_SORT.includes(heading);

  const isShowNoResult =
    !isFetching &&
    isNull(receivers) &&
    (!objectUtils.isObjectEmpty(applyFiltered) || searchValue);
  const isShowNoTransaction = arrayUtils.isArrayEmpty(receivers);
  const isShowBlank = isNull(receivers);

  const handleFavReceiversSort = (receivers, order, property) => {
    order = order === SORT_ASC ? SORT_DESC : SORT_ASC;

    receivers = sort.arrayObjectByString(receivers, order, property);

    return [receivers, order];
  };

  const handleReceiversSort = (order, property) => {
    order = order === SORT_ASC ? SORT_DESC : SORT_ASC;

    const sortPayload = {
      sortOrder: order,
      sortField: property,
    };
    let bodyPayload = getBodyPayloadFiltered(applyFiltered);

    bodyPayload = {
      ...bodyPayload,
      name: searchValue,
      ...(currentTab === 1 && { gender: BUSINESS_OF_RECEIVER_TYPE }),
    };

    setReceivers(null);
    fetchReceiversFiltered({
      token,
      body: bodyPayload,
      sort: sortPayload,
      page: PAGE_DEFAULT,
      setReceivers,
      setCurrentPage,
      setTotalPages,
      setTotalElements,
    });

    return order;
  };

  const handleSortByHeading = (heading, order) => {
    let newReceivers = [];

    if (isDashboardPage) {
      [newReceivers, order] = handleFavReceiversSort(
        receivers,
        order,
        FAV_RECEIVER_TABLE_HEADING[heading]
      );

      setReceivers(newReceivers);
    } else {
      setReceivers([]);

      order = handleReceiversSort(order, RECEIVER_TABLE_HEADING[heading]);
    }

    const newSortOrder = {
      ...RECEIVER_TABLE_SORT_ORDER_INITIAL,
      [heading]: order,
    };
    setSortOrder(newSortOrder);
  };

  const handleOnOpenReceiverDetailsPopup = async (receiver) => {
    dispatch({ type: SET_SHOW_LOADING, payload: true });

    await fetchReceiverInformation(token, receiver?.receiverId);

    setOpenReceiverDetailsPopup(true);

    dispatch({ type: SET_SHOW_LOADING, payload: false });
  };

  const handleOnCloseReceiverDetailsPopup = () => {
    setOpenDeleteReceiverPopup(false);

    setOpenReceiverDetailsPopup(false);

    dispatch({ type: SET_CURRENT_RECEIVER, payload: null });
  };

  const handleOnSendReceiver = async (receiver) => {
    await fetchReceiverInformation(token, receiver?.receiverId);

    Cookies.remove(MAKE_PAYMENT_KEY);

    navigate(`${TRANSACTION_ADD_PREFIX}?rid=${receiver?.receiverId}`);
  };

  const handleOnEditReceiver = async (receiver) => {
    await fetchReceiverInformation(token, receiver?.receiverId);

    navigate(`${RECEIVERS_EDIT_SUFIX}/${receiver?.receiverId}`);
  };

  const handleOnOpenDeleteReceiverPopup = async (receiver) => {
    await fetchReceiverInformation(token, receiver?.receiverId);

    setOpenDeleteReceiverPopup(true);
  };

  const handleOnCloseDeleteReceiverPopup = () => {
    setOpenDeleteReceiverPopup(false);

    if (!isOpenReceiverDetailsPopup) {
      dispatch({ type: SET_CURRENT_RECEIVER, payload: null });
    }
  };

  const handleOnDeleteReceiver = async (receiverId) => {
    try {
      await api.deleteReceiver(token, receiverId);

      let newFiltered = objectUtils.deletePropIsFalsy(applyFiltered);

      if (objectUtils.isObjectEmpty(newFiltered)) {
        newFiltered = RECEIVER_FILTER_INITIAL;
      }

      let bodyPayload = getBodyPayloadFiltered(newFiltered);

      bodyPayload = {
        ...bodyPayload,
        name: searchValue,
        ...(currentTab === 1 && { gender: BUSINESS_OF_RECEIVER_TYPE }),
      };

      setReceivers(null);
      await fetchReceiversFiltered({
        token,
        body: bodyPayload,
        page: currentPage,
        setReceivers,
        setCurrentPage,
        setTotalPages,
        setTotalElements,
      });

      dispatch({ type: SET_CURRENT_RECEIVER, payload: null });

      setOpenDeleteReceiverPopup(false);

      setOpenReceiverDetailsPopup(false);
    } catch (error) {
      console.error(error?.message);
    }
  };

  if (isShowNoResult) {
    return <NoResult />;
  }
  if (isShowNoTransaction) {
    return <NoReceiver />;
  }
  if (isShowBlank) {
    return null;
  }
  return (
    <>
      {currentReceiver && isOpenDeleteReceiverPopup && (
        <DeleteReceiverPopup
          isOpen={currentReceiver && isOpenDeleteReceiverPopup}
          isBackdropOverlap={true}
          onClose={handleOnCloseDeleteReceiverPopup}
          onClick={handleOnDeleteReceiver}
        />
      )}

      {currentReceiver && isOpenReceiverDetailsPopup && (
        <FormReceiverDetails
          isOpen={currentReceiver && isOpenReceiverDetailsPopup}
          onClose={handleOnCloseReceiverDetailsPopup}
          onClick={handleOnSendReceiver}
          onDelete={handleOnOpenDeleteReceiverPopup}
        />
      )}

      <ReceiverTableSortStyled>
        <Table responsive>
          <thead>
            <THeadTR>
              {(isDashboardPage
                ? Object.keys(FAV_RECEIVER_TABLE_HEADING)
                : Object.keys(RECEIVER_TABLE_HEADING)
              )?.map((heading, index) => (
                <THeadTH
                  key={index}
                  $isAbleSort={isAbleSort(heading)}
                  onClick={
                    isAbleSort(heading)
                      ? () => handleSortByHeading(heading, sortOrder[heading])
                      : () => {}
                  }
                >
                  {isAbleSort(heading) ? (
                    <>
                      {t(RECEIVER_TABLE_HEADING_TRANSLATE[heading])}
                      <SortIcon order={sortOrder[heading]} />
                    </>
                  ) : (
                    ''
                  )}
                </THeadTH>
              ))}
            </THeadTR>
          </thead>
          <tbody>
            {receivers?.map((receiver) => (
              <TBodyTR
                key={receiver.receiverId}
                isDashboardPage={isDashboardPage}
              >
                <TBodyTD
                  className="fullname"
                  onClick={() => handleOnOpenReceiverDetailsPopup(receiver)}
                >
                  <FormInputFavoriteCheckbox
                    id={receiver.receiverId}
                    isChecked={receiver.favorited}
                  />
                  <span>{receiver.fullname}</span>
                </TBodyTD>
                <TBodyTD
                  onClick={() => handleOnOpenReceiverDetailsPopup(receiver)}
                >
                  {receiver.countryName}
                </TBodyTD>
                <TBodyTD className="action">
                  <SendText onClick={() => handleOnSendReceiver(receiver)}>
                    {t('button_send')}{' '}
                  </SendText>
                  <EditText onClick={() => handleOnEditReceiver(receiver)}>
                    <EditIconStyled
                      src={EditIcon}
                      width={24}
                      height={24}
                      alt=""
                    />
                  </EditText>
                  <DeleteText
                    onClick={() => handleOnOpenDeleteReceiverPopup(receiver)}
                  >
                    <DeleteIconStyled
                      src={DeleteIcon}
                      width={24}
                      height={24}
                      alt=""
                    />
                  </DeleteText>
                </TBodyTD>
              </TBodyTR>
            ))}
          </tbody>
        </Table>
        {!isDashboardPage && Boolean(receivers.length) && (
          <Pagination
            totalPages={totalPages}
            applyFiltered={applyFiltered}
            searchValue={searchValue}
            sortOrder={sortOrder}
            totalElements={totalElements}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            receivers={receivers}
            setReceivers={setReceivers}
            current={current}
            setCurrent={setCurrent}
            setFetching={setFetching}
            currentTab={currentTab}
          />
        )}
        {isDashboardPage && (
          <ButtonLinkCommon
            href={RECEIVERS_PREFIX}
            value={t('receivers_view_all_receivers')}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
            styles={{
              marginInline: 'auto',
              height: 'fit-content',
              fontWeight: 'normal',
            }}
          />
        )}
      </ReceiverTableSortStyled>
    </>
  );
};

const ReceiverTableSortStyled = styled.div`
  width: 100%;
  height: 100%;

  & .table-responsive {
    width: 100%;
    height: 100%;

    & table {
      margin: 0;
    }
  }
`;

const THeadTR = styled.tr`
  display: flex;
  justify-content: space-between;
`;
const THeadTH = styled.th`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #7a8189;

  padding-inline: 16px !important;
  box-shadow: none;
  border: none;
  cursor: ${(props) => (props.$isAbleSort ? 'pointer' : 'default')};
  user-select: none;

  &:nth-child(1) {
    width: 55%;
  }
  &:nth-child(2) {
    width: 25%;
  }
  &:last-child {
    display: flex;
    justify-content: end;
    width: 20%;
  }

  & img {
    margin-left: 8px;
  }
`;

const TBodyTR = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 16px;
  margin-inline: 3px;
  cursor: pointer;

  &:last-child {
    margin-bottom: ${(props) => !props.isDashboardPage && '4px'};
  }
`;
const TBodyTD = styled.td`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #0e1012;

  display: flex;
  align-items: center;
  padding-inline: 16px !important;
  min-height: 50px;
  height: fit-content;
  border-bottom: none;
  border-radius: 12px;

  &:nth-child(1) {
    width: 55%;
  }
  &:nth-child(2) {
    width: 25%;
  }
  &:last-child {
    width: 20%;
  }

  &.fullname {
    font-weight: 700;
  }

  &.action {
    font-weight: 700;
    color: #4965a0;
    display: flex;
    justify-content: end;
    padding: 0;
  }

  & span {
    margin-left: 16px;
  }
`;

const SendText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  color: var(--ds-c-blue);

  margin: 0;
  margin-right: 12px;
  padding: 0;
  padding-inline: 8px;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 40px;
  background: #ffffff;
  border: 1px solid var(--ds-c-blue);
  border-radius: 12px;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: var(--ds-c-blue-hover);
    border: 1px solid var(--ds-c-blue-hover);
    background: #ffffff;

    & img {
      filter: var(--ds-f-c-blue-hover);
    }
  }
`;
const EditText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  color: #0e1012;

  margin: 0;
  margin-inline: 12px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
  user-select: none;
`;
const EditIconStyled = styled.img`
  &:hover {
    filter: var(--ds-f-c-grey-hover);
  }
`;

const DeleteText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  color: #e72631;

  margin: 0;
  margin-inline: 12px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
  user-select: none;
`;
const DeleteIconStyled = styled.img`
  &:hover {
    filter: var(--ds-f-c-grey-hover);
  }
`;

export default ReceiverTableSort;
