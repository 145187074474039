import { api } from 'api';
import { PAGE_DEFAULT, SIZE_PAGE_LIMIT_DEFAULT } from 'constants';

const { objectUtils, arrayUtils } = require('utils');

export const fetchTransactionsFiltered = async ({
  token = null,
  body = null,
  page = PAGE_DEFAULT,
  size = SIZE_PAGE_LIMIT_DEFAULT,
  transactions = null,
  setTransactions = null,
  setCurrentPage = null,
  setTotalPages = null,
  setTotalElements = null,
  setFetching = null,
}) => {
  if (token) {
    try {
      let bodyPayload = body || {};

      bodyPayload = objectUtils.deletePropIsFalsy(bodyPayload);

      console.log('>>> fetchTransactionsFiltered: ', bodyPayload, page, size);
      const { data } = await api.searchFXTrans(token, bodyPayload, page, size);
      if (data) {
        const { content, totalPages, totalElements } = data;

        if (transactions?.length) {
          setTransactions && setTransactions([...transactions, ...content]);
        } else if (!arrayUtils.isArrayEmpty(content)) {
          setTransactions && setTransactions(content);
        } else if (arrayUtils.isArrayEmpty(content)) {
          if (isArray(transactions)) {
            setTransactions([]);
          } else {
            setTransactions(null);
          }
        }

        setCurrentPage && setCurrentPage(page);

        setTotalPages && setTotalPages(totalPages);

        setTotalElements && setTotalElements(totalElements);

        setFetching && setFetching(false);
      }
    } catch (error) {
      console.error(error?.message);
    }
  }
};

export const getBodyPayloadFiltered = (filtered) => {
  return {
    status: filtered?.statusSelected?.map((status) => status.code),
    sendDateFrom: filtered?.dateFromSelected
      ? datePicker.dateUTCToDateStr(new Date(filtered?.dateFromSelected))
      : '',
    sendDateTo: filtered?.dateToSelected
      ? datePicker.dateUTCToDateStr(new Date(filtered?.dateToSelected))
      : '',
    amountFrom: filtered?.amountFrom ? parseFloat(filtered?.amountFrom) : null,
    amountTo: filtered?.amountTo ? parseFloat(filtered?.amountTo) : null,
  };
};

export const fetchTransactionInformation = async (token, orderRef, status) => {
  try {
    const { data } = await api.getOrder(token, orderRef);
    return data;
  } catch (error) {
    console.error(error?.message);
  }
};

export const fetchTransactionInformationAsGuest = async (orderRef, email) => {
  try {
    const { data } = await api.getOrderAsGuest(orderRef, email);
    return data;
  } catch (error) {
    console.error(error?.message);
  }
};
