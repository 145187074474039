import {
  fetchTransactionsFiltered,
  getBodyPayloadFiltered,
} from 'components/FXTransactions/func';
import { THROTTLE_SEC } from 'constants';
import useAuth from 'hooks/useAuth';
import useDetectEndpointVisibleOnScreen from 'hooks/useDetectEndpointVisibleOnScreen';
import useThrottle from 'hooks/useThrottle';
import { useEffect } from 'react';

const PaginationMobile = ({
  applyFiltered,
  searchValue,
  currentSearchBy,
  currentPage,
  setCurrentPage,
  transactions,
  setTransactions,
}) => {
  const { token } = useAuth();
  const throttle = useThrottle(THROTTLE_SEC);
  const isIntersecting = useDetectEndpointVisibleOnScreen();

  const fetchTransactionsPagination = (page) => {
    try {
      let bodyPayload = getBodyPayloadFiltered(applyFiltered);

      bodyPayload = {
        ...bodyPayload,
        [currentSearchBy]: searchValue,
      };

      fetchTransactionsFiltered({
        token,
        body: bodyPayload,
        page,
        transactions,
        setTransactions,
        setCurrentPage,
      });
    } catch (error) {
      console.error(error?.message);
    }
  };
  const handleNextPage = (page) => {
    throttle(() => fetchTransactionsPagination(page));
  };

  useEffect(() => {
    if (isIntersecting) {
      handleNextPage(currentPage + 1);
    }
  }, [isIntersecting, currentPage]);

  return null;
};

export default PaginationMobile;
