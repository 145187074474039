/* eslint-disable no-unused-vars */
// import countries from '@/data/countries.js'
import { HEADER_AGENT_CODE_REFERENCE, HHMT_THEME, SORT_DESC } from 'constants';
import { isImage, resize } from 'helpers/image';

const agentCode =
  HEADER_AGENT_CODE_REFERENCE[
    process.env.REACT_APP_APP_NAME || HHMT_THEME
  ].toLowerCase();

export default (axios) => ({
  /* Remit */
  getCountries() {
    return axios.get(`/public/remit/countries`);
  },
  getCurrencies(countryCode, dmCode) {
    return axios.get(`/public/remit/currencies/${countryCode}/${dmCode}`);
  },
  getDeliveryMethods(countryCode) {
    return axios.get(`/public/remit/delivery-methods/${countryCode}`);
  },
  getPickupOffices(countryCode) {
    return axios.get(`/public/remit/pickup-offices/${countryCode}`);
  },
  getBranches() {
    return axios.get(`/public/remit/branches`);
  },
  getBanks(countryCode) {
    return axios.get(`/public/remit/banks/${countryCode}`);
  },
  lookupRate(countryCode, dmCode, currencyCode) {
    return axios.post(`/public/remit/rates/lookup`, {
      countryCode,
      dmCode,
      currencyCode,
    });
  },
  lookupWwRate(currencyCode) {
    return axios.get(`/public/remit/rates/worldwide/${currencyCode}`);
  },
  lookupFee(countryCode, dmCode, amount, currencyCode) {
    return axios.post(`/public/remit/fees/lookup`, {
      countryCode,
      dmCode,
      amount,
      currencyCode,
    });
  },
  lookupOriginalFee(countryCode, dmCode, amount, currencyCode) {
    return axios.post(`/public/remit/fees/lookup-original`, {
      countryCode,
      dmCode,
      amount,
      currencyCode,
    });
  },
  ifsc(bankCode) {
    return axios.get(`/public/remit/ifsc/${bankCode}`);
  },
  getEwallets(countryCode) {
    return axios.get(`/public/remit/ewallets/${countryCode}`); // modified to countryCode
  },
  /* Configs */
  getCountryConfig(countryCode) {
    return axios.get(`/public/config/${countryCode}`);
  },
  getAppUUID() {
    return axios.get(`/public/config/app-uuid`);
  },
  lookupSp(countryCode, dmCode, currencyCode) {
    return axios.post(`/public/config/sp-lookup`, {
      countryCode,
      dmCode,
      currencyCode,
    });
  },
  getConfigOccupation(locate) {
    return axios.get(`/public/config/occupation`, {
      headers: { lang: locate },
    });
  },
  getConfigRelationship(locate, path) {
    return axios.get(`/public/config/relationship${path || ''}`, {
      headers: { lang: locate },
    });
  },
  getConfigPurpose(locate, path) {
    return axios.get(`/public/config/purpose${path || ''}`, {
      headers: { lang: locate },
    });
  },
  getConfigSourcesOfWealth(locate) {
    return axios.get(`/public/config/sources-of-wealth`, {
      headers: { lang: locate },
    });
  },
  /* Location */
  getCities(countryCode) {
    return axios.get(`/public/remit/cities/${countryCode}`);
  },
  getSuburbs(cityId) {
    return axios.get(`/public/remit/suburbs/${cityId}`);
  },
  /* Tran */
  getTrans(token, page = 1, size = 10) {
    return axios.get(`/trans?page=${page - 1}&&size=${size}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  searchTrans(token, body = {}, sort = {}, page = 1, size = 10) {
    const sortPayload = {
      sortField: sort?.sortField || 'createDate',
      sortOrder: sort?.sortOrder || SORT_DESC,
      ...sort,
    };
    const bodyPayload = body;

    return axios.patch(
      `/trans?page=${page - 1}&&size=${size}&&sortField=${
        sortPayload.sortField
      }&&sortOrder=${sortPayload.sortOrder}`,
      bodyPayload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  getRecentTrans(token, size = 10) {
    return axios.get(`/trans/recent?size=${size}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getTran(token, refNumber) {
    return axios.get(`/trans/${refNumber}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getEditableTran(refNumber) {
    return axios.get(`/trans/editable/${refNumber}`);
  },
  createTran(token, tran) {
    return axios.post(`/trans`, tran, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  updateTran(token, refNumber, tran) {
    return axios.put(`/trans/${refNumber}`, tran, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getTransCountries(token) {
    return axios.get(`/trans/countries`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  /* Payment */
  // Poli
  getPoliBanks() {
    return axios.get(`/payments/poli/banks`);
  },
  payPoli(token, refNumber, successPath, failPath) {
    const homeUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

    return axios.post(
      `/payments/poli/${refNumber}`,
      {
        homeUrl,
        successPath,
        failPath,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getPoliTran(token) {
    return axios.get(`/payments/poli/${token}`);
  },
  // Wirecard
  payWirecard(refNumber, homeUrl) {
    const successPath = 'tran-success';
    const failPath = 'tran';
    return axios.post(`/payments/wirecard/${refNumber}`, {
      homeUrl,
      successPath,
      failPath,
    });
  },
  // Mint payment
  payMint(
    token,
    refNumber,
    number,
    expiryMonth,
    expiryYear,
    cvc,
    holderName,
    savedForFuture
  ) {
    return axios.post(
      `/payments/mint/${refNumber}`,
      {
        number,
        expiryMonth,
        expiryYear,
        cvc,
        holderName,
        savedForFuture,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  processMint(token, refNumber, tokenTransaction) {
    return axios.post(
      `/payments/mint/process`,
      { refNumber, token: tokenTransaction },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  // I Have Paid - DELETE
  ihavepaid(token, refNumber) {
    return axios.get(`/payments/ihavepaid/${refNumber}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  /* Receiver */
  getAllReceivers(token) {
    return axios.get(`/receivers/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getReceivers(token, page = 1, size = 10) {
    return axios.get(`/receivers?page=${page - 1}&&size=${size}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  searchReceivers(token, body = {}, sort = {}, page = 1, size = 10) {
    const sortPayload = {
      sortField: sort?.sortField || 'createDate',
      sortOrder: sort?.sortOrder || SORT_DESC,
      ...sort,
    };
    const bodyPayload = body;

    return axios.patch(
      `/receivers?page=${page - 1}&&size=${size}&&sortField=${
        sortPayload.sortField
      }&&sortOrder=${sortPayload.sortOrder}`,
      bodyPayload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  getReceiversCountries(token, path = '') {
    return axios.get(`/receivers/countries${path || ''}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getFavReceivers(token, page = 1, size = 10) {
    return axios.get(`/receivers/favorited?page=${page - 1}&&size=${size}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getReceiver(token, id) {
    return axios.get(`/receivers/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  createReceiver(token, receiver) {
    return axios.post(`/receivers`, receiver, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateDetails(token, id, details) {
    return axios.put(`/receivers/${id}`, details, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  deleteReceiver(token, id) {
    return axios.delete(`/receivers/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  /* Receiver - Record */
  getReceiverRecord(token, refNumber) {
    return axios.get(`/receivers/record/${refNumber}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  /* Receiver - Contact */
  addContact(token, id, type, contact) {
    return axios.post(`/receivers/${id}/contact/${type}`, contact, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateContact(token, id, contactId, contact) {
    return axios.put(`/receivers/${id}/contact/${contactId}`, contact, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  deleteContact(token, id, contactId) {
    return axios.delete(`/receivers/${id}/contact/${contactId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  /* Receiver - Address */
  updateAddress(token, id, addressId, address) {
    return axios.put(`/receivers/${id}/address/${addressId}`, address, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  /* Receiver - Bank */
  addBank(token, id, bank) {
    return axios.post(`/receivers/${id}/bank`, bank, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateBank(token, id, bankId, bank) {
    return axios.put(`/receivers/${id}/bank/${bankId}`, bank, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  deleteBank(token, id, bankId) {
    return axios.delete(`/receivers/${id}/bank/${bankId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  /* Receiver - pref */
  setFavorited(token, id, favorited) {
    return axios.get(`/receivers/${id}/fav/${favorited}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  /* Receiver - relationship */
  updateRelationship(token, id, relationship) {
    return axios.post(
      `/receivers/${id}/relationship`,
      { relationship },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  /* Receiver - Ewallet */
  addEwallet(token, id, ewallet) {
    return axios.post(`/receivers/${id}/ewallet`, ewallet, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateEwallet(token, id, receiverEwalletId, ewallet) {
    return axios.put(`/receivers/${id}/ewallet/${receiverEwalletId}`, ewallet, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  deleteEwallet(token, id, receiverEwalletId) {
    return axios.delete(`/receivers/${id}/ewallet/${receiverEwalletId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  /* Receiver - Verification */
  addReceiverVerification(id, verification) {
    return axios.post(`/receivers/${id}/verification`, verification);
  },
  updateReceiverVerification(id, receiverVerificationId, verification) {
    return axios.put(
      `/receivers/${id}/verification/${receiverVerificationId}`,
      verification
    );
  },
  getReceiverVerification(type, entityId) {
    return axios.get(
      `/receivers/verification?type=${type}&entityId=${entityId}`
    );
  },

  /* Profile */
  getProfile(token) {
    return axios.get(`/profile`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateProfile(token, profile) {
    return axios.put(`/profile`, profile, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updatePassword(token, currentPassword, newPassword) {
    return axios.put(
      `/profile/password`,
      { currentPassword, newPassword },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getOptin(token) {
    return axios.get(`/profile/optin`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  saveOptin(token, type, optin) {
    return axios.get(`/profile/optin/${type}/${optin}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getRiskLevel() {
    return axios.get(`/profile/risk-level`);
  },
  getOccupation(token) {
    return axios.get(`/profile/occupation`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  saveOccupation(token, occupation) {
    return axios.put(`/profile/occupation`, occupation, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getBirthCountry(token) {
    return axios.get(`/profile/cob`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  saveBirthCountry(birthCountry) {
    return axios.put(`/profile/cob`, birthCountry);
  },
  saveDeviceToken(token, deviceToken, language, active) {
    return axios.post(
      `/profile/device-tokens`,
      {
        token: deviceToken,
        language,
        active,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  /* Signup */
  getSignup(signupId) {
    return axios.get(`/public/signups/${signupId}`);
  },
  saveSignup(signup) {
    return axios.post(`/public/signups`, signup);
  },
  isExistingCustomer(firstName, otherNames, lastName, gender, dob) {
    const name = otherNames ? `${firstName} ${otherNames}` : firstName;
    return axios.post(`/public/signups/is-existing-customer`, {
      firstName: name,
      lastName,
      gender,
      dob,
    });
  },
  isUsernameValid(username) {
    return axios.post(`/public/signups/is-username-valid`, { username });
  },
  async uploadDocument(docType, file) {
    let fileUpload = file;
    if (isImage(file)) {
      fileUpload = await resize(file);
    }
    const formData = new FormData();
    formData.append('docType', docType);
    formData.append('file', fileUpload);
    return axios.post(`/public/signups/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  async fxUploadDocument(docType, idType, file) {
    let fileUpload = file;
    if (isImage(file)) {
      fileUpload = await resize(file);
    }
    const formData = new FormData();
    formData.append('docType', docType);
    formData.append('idType', idType);
    formData.append('file', fileUpload);
    return axios.post(`/public/fx/documents/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  /* Discount */
  applyDiscount({
    token,
    code,
    countryCode,
    currencies,
    deliveryMethod,
    paymentMethod,
    receivingAmount,
    txnAmount,
    txnFee,
  }) {
    const payload = {
      code,
      countryCode,
      currencies,
      deliveryMethod,
      paymentMethod,
      receivingAmount,
      txnAmount,
      txnFee,
    };

    return axios.post(`/discounts`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
  getDiscountsActive(token) {
    return axios.get(`/profile/discounts/active`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getAllDiscounts(token) {
    return axios.get(`/profile/discounts`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  /* Auth */
  login(username, password) {
    return axios.post(
      `/public/login`,
      { username, password },
      { withCredentials: true }
    );
  },
  activate(token, password) {
    return axios.post(`/public/activate`, { token, password });
  },
  forgotPassword(username) {
    return axios.post(`/public/password/forgot`, { username });
  },
  resetPassword(token, password) {
    return axios.post(`/public/password/reset`, { token, password });
  },
  refreshToken(token) {
    return axios.get(`/auth/refresh`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  logout() {
    return axios.post(`/public/logout`, {}, { withCredentials: true });
  },

  /* Validation */
  validatePhone(countryCode, number) {
    const payload = {
      number,
      country_code: countryCode,
    };
    return axios.post(`/public/validation/phone`, payload);
  },
  validateAccNumber(countryCode, bankId, bankAccNumber, rFirstName, rLastName) {
    const timeout = 60000; // 10 seconds
    return axios.post(
      `/public/validation/bank-acc`,
      {
        countryCode,
        bankId,
        bankAccNumber,
        rFirstName: rFirstName || '',
        rLastName: rLastName || '',
      },
      { timeout }
    );
  },
  validateIBAN(iban) {
    return axios.get(`/public/validation/iban?value=${iban}`);
  },
  validateSWIFT(swiftCode) {
    return axios.get(`/public/validation/swift?value=${swiftCode}`);
  },
  validateRoutingNumber(routingNumber) {
    return axios.get(
      `/public/validation/routing-number?value=${routingNumber}`
    );
  },
  validateBSB(bsb) {
    return axios.get(`/public/validation/bsb?value=${bsb}`);
  },
  validateSortCode(sortCode) {
    return axios.get(`/public/validation/sort-code?value=${sortCode}`);
  },
  validateEmail(email) {
    return axios.get(`/public/validation/email/${email}`);
  },
  /* Money Gram */
  lookupMGFeeInfo(
    countryCode,
    dmCode,
    currencyCode,
    sendAmount,
    receiveAmount
  ) {
    return axios.post(`/mg/fee-lookup`, {
      countryCode,
      dmCode,
      currencyCode,
      sendAmount,
      receiveAmount,
    });
  },
  getFieldsForProduct(countryCode, dmCode, currencyCode, sendAmount) {
    return axios.post(`/mg/gffp`, {
      countryCode,
      dmCode,
      currencyCode,
      sendAmount,
    });
  },
  isMg(countryCode, dmCode, currencyCode) {
    return axios.post(`/mg/is-mg`, { countryCode, dmCode, currencyCode });
  },
  sendValidation(tran) {
    return axios.post(`/mg/send-validation`, tran);
  },
  stagTranx(tran) {
    return axios.post(`/mg/stag-tranx`, tran);
  },
  getStateList(countryCode) {
    return axios.get(`/public/remit/mg/states/?countryCode=${countryCode}`);
  },
  getCityList(countryCode, stateCode) {
    return axios.get(
      `/public/remit/mg/cities/?countryCode=${countryCode}&stateCode=${stateCode}`
    );
  },
  getLocationList(countryCode, stateCode, city) {
    return axios.get(
      `/public/remit/mg/agents/?countryCode=${countryCode}&stateCode=${stateCode}&city=${city}`
    );
  },
  /* Harmony */
  lookupAddresses(address, transactionID) {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL}/public/validation/au-address/v1/lookup?address=${address}&transactionID=${transactionID}`
    );
  },
  lookupPostcodes(postcode, transactionID) {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL}/public/validation/au-address/v1/lookup/postcode?postcode=${postcode}&transactionID=${transactionID}`
    );
  },
  lookupAddressLocality(
    postcodeOrCityValue,
    transactionID = 'd6fe8ff05352dfff3c3a3367d7140364'
  ) {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL}/public/validation/au-address/v1/lookup/locality?locality=${postcodeOrCityValue}&transactionID=${transactionID}`
    );
  },
  lookupPickupLocationByAustraliaPost(value) {
    const payload = {
      payload: [
        {
          fullAddress: value,
        },
      ],
      sourceOfTruth: 'AUPAF',
    };
    return axios.post(
      `https://hosted.mastersoftgroup.com/harmony/rest/au/locality`,
      payload
    );
  },
  /* i18n-controller */
  getTranslationLocate(locate) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/public/i18n`, {
      headers: { lang: locate },
    });
  },
  /* event-controller */
  getEventsAvailable(token) {
    return axios.get(`/profiles/events`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  addEventRecordData(token, event) {
    return axios.post(`/profiles/events/record`, event, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getBuildVersion() {
    return axios.get(`/public/build-version`);
  },
  /* fx-controller */
  getPickupLocationsViaAusPost(postcode) {
    return axios.get(`/public/fx/pickup-location/auspost/${postcode}`);
  },
  getPickupDates(payload = {}) {
    return axios.post(`/public/fx/pickup-date`, payload);
  },
  getOrder(token, orderRef) {
    return axios.get(`/fx/order/${orderRef}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getOrderAsGuest(orderRef, email) {
    return axios.get(`/public/fx/order/${orderRef}?email=${email}`);
  },
  createOrder(token, payload) {
    return axios.post(`/fx`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  createOrderAsGuest(payload) {
    return axios.post(`/public/fx`, payload);
  },
  updateOrder(token, payload) {
    return axios.post(`/fx`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  updateOrderAsGuest(payload) {
    return axios.post(`/public/fx`, payload);
  },
  getFxSellRate(currencyCode = '') {
    return axios.get(`/public/fx/rates/${currencyCode}`);
  },
  getFxSellCurrencies() {
    return axios.get(`/public/fx/currencies`);
  },
  getFxPickupLocationBranches() {
    return axios.get(`/public/fx/pickup-location`);
  },
  // FX online Discount
  getFxDiscountsActive({
    token,
    currency,
    receivingAmount,
    txnAmount,
    txnFee,
  }) {
    const payload = {
      code: '',
      countryCode: '', // currencyOrders[0]
      currencies: [
        '', // currencyOrders[0]
      ],
      deliveryMethod: 'PICK UP',
      orgId: 0,
      paymentMethod: [
        '', // paymentMethodName
      ],
      sender: {
        dob: '',
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
      },
      receivingAmount,
      senderId: '',
      serviceType: 'FX',
      txnAmount,
      txnFee,
    };
    return axios.post(`/profile/fx/discounts/active`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getFxDiscountsActiveAsGuest({ receivingAmount, txnAmount, txnFee }) {
    const payload = {
      code: '',
      countryCode: '', // currencyOrders[0]
      currencies: [
        '', // currencyOrders[0]
      ],
      deliveryMethod: 'PICK UP',
      orgId: 0,
      paymentMethod: [
        '', // paymentMethodName
      ],
      sender: {
        dob: '',
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
      },
      receivingAmount,
      senderId: '',
      serviceType: 'FX',
      txnAmount,
      txnFee,
    };
    return axios.post(`/public/fx/discounts`, payload);
  },
  applyFxDiscount({
    token,
    discount,
    currentUser,
    receivingAmount,
    txnAmount,
    txnFee,
  }) {
    const { code } = discount || {};
    const { firstName, lastName, mobile, email, dob } = currentUser || {};
    const payload = {
      code,
      countryCode: '',
      currencies: [''],
      deliveryMethod: '',
      orgId: 0,
      paymentMethod: [''],
      sender: {
        dob: dob ? new Date(dob) : new Date(),
        email,
        firstName,
        lastName,
        phone: mobile,
      },
      receivingAmount,
      senderId: '',
      serviceType: 'FX',
      txnAmount,
      txnFee,
    };
    return axios.post(`/public/fx/discounts/apply`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  // FX online Mint payment
  fxPayMint(
    token,
    refNumber,
    number,
    expiryMonth,
    expiryYear,
    cvc,
    holderName,
    savedForFuture
  ) {
    return axios.post(
      `/fx/payments/mint/pay/${refNumber}`,
      {
        number,
        expiryMonth,
        expiryYear,
        cvc,
        holderName,
        savedForFuture,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  fxPayMintAsGuest(
    senderId,
    refNumber,
    number,
    expiryMonth,
    expiryYear,
    cvc,
    holderName,
    savedForFuture
  ) {
    return axios.post(`/public/fx/payments/mint/pay/${senderId}/${refNumber}`, {
      number,
      expiryMonth,
      expiryYear,
      cvc,
      holderName,
      savedForFuture,
    });
  },
  fxProcessMint(refNumber, tokenTransaction) {
    return axios.post(`/public/fx/payments/mint/process`, {
      refNumber,
      token: tokenTransaction,
    });
  },
  /* FX rates */
  getAllFXRates() {
    return axios.get(`/public/fx/rates`);
  },
  searchFXTrans(token, body = {}, page, size) {
    const bodyPayload = body;
    return axios.patch(`/fx?page=${page - 1}&&size=${size}`, bodyPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
});
