/* eslint-disable no-unused-vars */
import ArrowLeftIcon from 'assets/ver2/icons/arrow-left-circle-fill-icon.svg';
import ArrowRightIcon from 'assets/ver2/icons/arrow-right-circle-fill-icon.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import BodySectionHeader from 'components/fx/components/BodySectionHeader';
import CustomPickupDateDatepickerSelected from 'components/fx/components/CustomPickupDateDatepickerSelected';
import { DESKTOP_MIN_WIDTH } from 'constants';
import {
  FX_ADD_TRANSACTION_PICKUP_DATE,
  RESET_FX_ADD_TRANSACTION_PICKUP_DATE,
} from 'constants/fx';
import { Fade } from 'helpers/animation';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useScrollIntoViewDelay from 'hooks/useScrollIntoViewDelay';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import CalculatorForm from '../CalculatorForm';

const initialFormField = RESET_FX_ADD_TRANSACTION_PICKUP_DATE;

const PickupDate = ({
  handleOnToggleCancelPopup,
  handleOnViewPickupLocation,
}) => {
  const { t } = useLang();
  const { isDesktop, isMobile, isTablet } = useDetectDevice();

  const [validation, setValidation] = useState(initialFormField);
  const [pickupDates, setPickupDates] = useState([]);
  const [datesOfCurrentMonth, setDatesOfCurrentMonth] = useState([]);
  const [disabledDatesOfCurrentMonth, setDisabledDatesOfCurrentMonth] =
    useState([]);
  const [datesOfNextMonth, setDatesOfNextMonth] = useState([]);
  const [disabledDatesOfNextMonth, setDisabledDatesOfNextMonth] = useState([]);
  const [isNextMonth, setNextMonth] = useState(false);

  const {
    addTransactionCurrentStep: currentStep,
    setAddTransactionCurrentStep,
    paymentMethodName,
    pickupLocationSelected,
    pickupLocationType,
    pickupDateSelected,
    setPickupDate,
  } = useFxFormAddTransactionStore();
  const {
    getTitleBodySection,
    setPickupDateDates,
    dateTransform,
    checkDateSelectedInCurrentDates,
  } = useFxAddTransaction();

  const isNextDisabled = isEmpty(pickupDateSelected);

  // scroll to calculator form
  const validRef = useRef(null);
  useScrollIntoViewDelay(validRef, validRef, []);

  useEffect(() => {
    setPickupDateDates({
      pickupLocation: pickupLocationSelected,
      pickupLocationType,
      paymentMethodName,
      setPickupDates,
      setDatesOfCurrentMonth,
      setDisabledDatesOfCurrentMonth,
      setDatesOfNextMonth,
      setDisabledDatesOfNextMonth,
      pickupDate: pickupDateSelected,
      setNextMonth,
    });
  }, []);

  useEffect(() => {
    if (isEmpty(pickupDateSelected)) return;
    if (isEmpty(pickupDates)) return;

    const { isDateInCurrentDates } = getCurrentMonthDatepickerConfig();
    const { isDateInCurrentDates: isDateInNextDates } =
      getNextMonthDatepickerConfig();
    if (!isDateInCurrentDates && !isDateInNextDates) setPickupDate();
  }, [pickupDateSelected, pickupDates]);

  const handleOnClearValidation = () => {
    setValidation(initialFormField);
  };

  const handleNextMonth = () => {
    setNextMonth(true);
  };
  const getCurrentMonthDatepickerConfig = () => {
    const pickupDateSelectedTransform = dateTransform(pickupDateSelected);
    const startDate = datesOfCurrentMonth[0];
    const endDate = datesOfCurrentMonth[datesOfCurrentMonth.length - 1];
    const isDateInCurrentDates = checkDateSelectedInCurrentDates({
      dateSelected: pickupDateSelectedTransform,
      startDate,
      endDate,
    });
    return {
      pickupDateSelectedTransform,
      startDate,
      endDate,
      isDateInCurrentDates,
    };
  };
  const renderDatesOfCurrentMonthDatepicker = () => {
    if (isEmpty(datesOfCurrentMonth)) return;
    const {
      pickupDateSelectedTransform,
      startDate,
      endDate,
      isDateInCurrentDates,
    } = getCurrentMonthDatepickerConfig();
    return (
      <DatepickerItem $display={isNextMonth && !isDesktop && 'none'}>
        {!isDesktop && (
          <ArrowIcon
            className="next-month"
            src={ArrowRightIcon}
            onClick={handleNextMonth}
          />
        )}
        <CustomPickupDateDatepickerSelected
          dates={pickupDates}
          date={isDateInCurrentDates ? pickupDateSelectedTransform : startDate}
          minDate={startDate}
          maxDate={endDate}
          disabledDates={disabledDatesOfCurrentMonth}
          isDateInCurrentDates={isDateInCurrentDates}
          onClearValidation={handleOnClearValidation}
        />
      </DatepickerItem>
    );
  };

  const handlePrevMonth = () => {
    setNextMonth(false);
  };
  const getNextMonthDatepickerConfig = () => {
    const pickupDateSelectedTransform = dateTransform(pickupDateSelected);
    const startDate = datesOfNextMonth[0];
    const endDate = datesOfNextMonth[datesOfNextMonth.length - 1];
    const isDateInCurrentDates = checkDateSelectedInCurrentDates({
      dateSelected: pickupDateSelectedTransform,
      startDate,
      endDate,
    });
    return {
      pickupDateSelectedTransform,
      startDate,
      endDate,
      isDateInCurrentDates,
    };
  };
  const renderDatesOfNextMonthDatepicker = () => {
    if (isEmpty(datesOfNextMonth)) return;
    const {
      pickupDateSelectedTransform,
      startDate,
      endDate,
      isDateInCurrentDates,
    } = getNextMonthDatepickerConfig();
    return (
      <DatepickerItem $display={!isNextMonth && !isDesktop && 'none'}>
        {!isDesktop && (
          <ArrowIcon
            className="prev-month"
            src={ArrowLeftIcon}
            onClick={handlePrevMonth}
          />
        )}
        <CustomPickupDateDatepickerSelected
          dates={pickupDates}
          date={isDateInCurrentDates ? pickupDateSelectedTransform : startDate}
          minDate={startDate}
          maxDate={endDate}
          disabledDates={disabledDatesOfNextMonth}
          isDateInCurrentDates={isDateInCurrentDates}
          onClearValidation={handleOnClearValidation}
        />
      </DatepickerItem>
    );
  };

  const renderDatepickerSection = () => {
    if (isEmpty(datesOfCurrentMonth) && isEmpty(datesOfNextMonth)) return null;
    return (
      <>
        <DescValidation $isError={validation[FX_ADD_TRANSACTION_PICKUP_DATE]}>
          {t('fx_add_transaction_choose_pickup_date')}:
        </DescValidation>
        <DatepickerSection>
          {renderDatesOfCurrentMonthDatepicker()}
          {renderDatesOfNextMonthDatepicker()}
        </DatepickerSection>
      </>
    );
  };

  const handleBackStep = () => {
    setAddTransactionCurrentStep(currentStep - 1);
  };
  const handleNextStep = () => {
    const isPickupDateSelectedInvalid = isEmpty(pickupDateSelected);
    const isAllowSubmit = !isPickupDateSelectedInvalid;

    if (isPickupDateSelectedInvalid) {
      const newValidation = {
        ...validation,
        [FX_ADD_TRANSACTION_PICKUP_DATE]:
          'fx_add_transaction_choose_pickup_date',
      };
      setValidation(newValidation);
      return;
    }

    if (isAllowSubmit) {
      setAddTransactionCurrentStep(currentStep + 1);
    }
  };
  const renderCalculatorFormMobile = () => {
    if (isDesktop) return null;
    return (
      <CalculatorForm
        ref={validRef}
        isCalculatorFormAccordion={true}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    );
  };
  const renderActionsButton = () => {
    return (
      <NextWrap>
        {renderCalculatorFormMobile()}
        <ActionWrapper>
          <ButtonCommonWrap>
            <ButtonCommon
              value={t('button_back')}
              onClick={handleBackStep}
              styles={{
                margin: '0px',
                width: !isDesktop && '100%',
              }}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
              borderNameVariable={isDesktop && '--border-filter'}
              isFill={false}
            />
          </ButtonCommonWrap>
          <ButtonCommon
            value={t('button_confirm')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: isDesktop ? '150px' : '50%',
            }}
            onClick={handleNextStep}
            isDisabled={false}
            isUseKeyDown={!isNextDisabled}
          />
        </ActionWrapper>
      </NextWrap>
    );
  };

  return (
    <>
      <BodySectionHeader
        titleBodySection={getTitleBodySection()}
        titleSection={t('fx_add_transaction_pickup_date')}
        CalculatorComponent={isDesktop && <CalculatorForm />}
        onToggleCancelPopup={handleOnToggleCancelPopup}
        onViewPickupLocation={handleOnViewPickupLocation}
      />
      <PickupDateStyledStyled>
        {renderDatepickerSection()}
        {renderActionsButton()}
      </PickupDateStyledStyled>
    </>
  );
};

const PickupDateStyledStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -8px;
  padding-inline: 0px;
  padding-bottom: 204px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 774px;
    margin-top: 0px;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

export const DescValidation = styled.p`
  ${paragraphMixin};
  font-weight: 500;
  margin-bottom: 12px;
  color: ${(props) =>
    props.$isError ? 'var(--ds-c-red)' : 'var(--ds-c-grey-dark)'};

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 20px;
  }
`;

const DatepickerSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    justify-content: start;
  }
`;
const DatepickerItem = styled.div`
  position: relative;
  display: ${(props) => props.$display};
  ${Fade};
`;
const ArrowIcon = styled.img`
  position: absolute;
  top: 15px;
  &.next-month {
    right: 16px;
  }
  &.prev-month {
    left: 16px;
  }
`;

export const NextWrap = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: fit-content;
  margin-inline: -16px;
  padding-inline: 0px;
  background: transparent;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    width: 774px;
    height: fit-content;
    border-top: unset;
  }
`;
export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  width: 100%;
  background: var(--ds-c-white);
  border-top: 1px solid var(--ds-bg-2);
  padding-inline: 16px;
  margin: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 100%;
    height: 44px;
    border-top: unset;
    padding-inline: unset;
    margin: 0px;
  }
`;
export const ButtonCommonWrap = styled.div`
  width: 50%;
`;

export default PickupDate;
