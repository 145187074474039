import { api } from 'api';
import {
  CALCULATOR_DESKTOP_WIDTH,
  CALCULATOR_FORM_PAYOUT_METHOD_NAME,
  CALCULATOR_FORM_RECEIVER_GET_NAME,
  CALCULATOR_FORM_SEND_TO_NAME,
  METHOD_EPAYMENT,
  SENDING_AMOUNT_DISCOUNT_PERCENTS,
  SURCHARGE_RATE,
} from 'constants';
import { format } from 'helpers';
import useLang from 'hooks/useLang';
import useThrottle from 'hooks/useThrottle';
import { lazy, useEffect, useState } from 'react';
import styled from 'styled-components';
import { numberUtils } from 'utils';

const TooltipCommon = lazy(() => import('components/common/TooltipCommon'));

const TotalPayForm = ({ information, countryConfig }) => {
  const { t } = useLang();
  const throttle = useThrottle();

  const [originalFee, setOriginalFee] = useState(null);

  const { currentCurrencySelected } = information || {};

  const { code: dmCode } =
    information[CALCULATOR_FORM_PAYOUT_METHOD_NAME] || {};

  const onlyCurrencySelectedByVND =
    currentCurrencySelected?.currencyCode === 'VND';

  const isShowSurchargeField = dmCode && dmCode === METHOD_EPAYMENT;

  const parseAmountValue = (value) => {
    return value && format.toAmountFloatStr(numberUtils.mathRound(value));
  };

  const sendAmount = numberUtils.mathRound(
    information[CALCULATOR_FORM_SEND_TO_NAME] || 0
  );
  const getAmount = numberUtils.mathRound(
    information[CALCULATOR_FORM_RECEIVER_GET_NAME] || 0
  );
  const sendTotalAmount = numberUtils.mathRound(sendAmount);

  const feeOriginAmount = numberUtils.mathRound(originalFee?.feeAmount || 0);

  const feeAmount = numberUtils.mathRound(
    information['feeAmount']?.feeAmount || 0
  );

  const surchargeAmount =
    dmCode && dmCode === METHOD_EPAYMENT
      ? numberUtils.mathRound(
          numberUtils.mathRound(sendTotalAmount + feeAmount) * SURCHARGE_RATE
        )
      : 0;

  const totalPayAmount = numberUtils.mathRound(
    feeAmount + surchargeAmount + sendTotalAmount
  );

  const discountPercent = () => {
    return SENDING_AMOUNT_DISCOUNT_PERCENTS.find((o) => {
      if (o.range.length === 1 && sendTotalAmount >= o.range[0]) return o;

      if (sendTotalAmount >= o.range[0] && sendTotalAmount <= o.range[1])
        return o;

      return null;
    });
  };

  const isShowDiscountPercent = Boolean(
    onlyCurrencySelectedByVND &&
      feeAmount &&
      discountPercent() &&
      feeOriginAmount !== feeAmount
  );

  const fetchOriginalFee = async (
    countryCode,
    dmCode,
    sendAmount,
    isAddAnotherReceivingCurrency
  ) => {
    try {
      const { data } = await api.lookupOriginalFee(
        countryCode,
        dmCode,
        sendAmount,
        isAddAnotherReceivingCurrency
          ? 'AUD'
          : currentCurrencySelected?.currencyCode
      );

      if (data) {
        setOriginalFee(data);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  const discountedAmount = (feeOriginAmount, feeAmount) => {
    return feeOriginAmount - feeAmount;
  };

  useEffect(() => {
    if (countryConfig && sendTotalAmount && dmCode) {
      throttle(() => {
        fetchOriginalFee(
          countryConfig?.countryCode,
          dmCode,
          sendTotalAmount,
          false
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryConfig, sendTotalAmount, getAmount, dmCode]);

  return (
    <CalculatorFormStyled>
      <CalculatorFieldWrap>
        <CalculatorFieldLabel>{t('form_you_send_2')}</CalculatorFieldLabel>
        <CalculatorFieldValue>{`${parseAmountValue(
          sendTotalAmount
        )} AUD`}</CalculatorFieldValue>
      </CalculatorFieldWrap>
      <CalculatorFieldWrap>
        <CalculatorFieldLabel>{t('label_fee')}</CalculatorFieldLabel>
        <CalculatorFieldValue className="fee-value">
          {`${parseAmountValue(feeOriginAmount)} AUD`}
        </CalculatorFieldValue>
      </CalculatorFieldWrap>
      {sendTotalAmount > 0 &&
        discountedAmount(feeOriginAmount, feeAmount) > 0 && (
          <CalculatorFieldWrap>
            <CalculatorFieldLabel className="fee-value">
              {t('label_fee_discount')}
              {isShowDiscountPercent && (
                <CalculatorFieldValue className="summary-discount">
                  {discountPercent() && (
                    <TooltipCommon
                      text={t(discountPercent().tooltip)}
                      icon={discountPercent().imgSrc}
                      isPopover
                      isDangerouslySetInnerHTML={true}
                    />
                  )}
                </CalculatorFieldValue>
              )}
            </CalculatorFieldLabel>
            <CalculatorFieldValue>
              {`-${parseAmountValue(
                discountedAmount(feeOriginAmount, feeAmount)
              )} AUD`}
            </CalculatorFieldValue>
          </CalculatorFieldWrap>
        )}
      {/* <Learnmore href="/">Learn more about fee</Learnmore> */}
      {isShowSurchargeField && (
        <CalculatorFieldWrap>
          <CalculatorFieldLabel>
            {t('label_surcharge_label')} <span>{t('label_credit_card')}</span>
          </CalculatorFieldLabel>
          <CalculatorFieldValue>{`${parseAmountValue(
            surchargeAmount
          )} AUD`}</CalculatorFieldValue>
        </CalculatorFieldWrap>
      )}
      <Divider />
      <CalculatorTotal>
        <CalculatorTotalLabel>{t('label_total_to_pay')}</CalculatorTotalLabel>
        <CalculatorTotalValue>{`${parseAmountValue(
          totalPayAmount
        )} AUD`}</CalculatorTotalValue>
      </CalculatorTotal>
    </CalculatorFormStyled>
  );
};

const CalculatorFormStyled = styled.div`
  width: 100%;
  background: var(--ds-bg-2);
  border-radius: 10px;
  padding: 16px;

  @media screen and (width: ${CALCULATOR_DESKTOP_WIDTH}px) {
    padding: 13px 16px;
  }
`;
const CalculatorFieldWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  margin-bottom: 0px;
`;
const CalculatorFieldLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);
  display: flex;
  align-items: center;

  margin: 0;
  margin-bottom: 8px;
  padding: 0;

  & span {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
`;
const CalculatorFieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-grey-dark);

  margin: 0;
  padding: 0;

  &.fee-value {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &.summary-discount {
    color: var(--ds-c-grey-hover);
    display: flex;
    align-items: center;
  }

  & span {
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;

    background-color: var(--ds-c-green-default);
    padding: 2px 8px;
    border-radius: 2px;
    margin-right: 8px;
  }

  & button.btn-primary {
    display: flex;
  }
`;

const CalculatorTotal = styled.div`
  margin-inline: -8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  padding: 0px 8px;
  background: transparent;
  border-radius: 8px;
`;
const CalculatorTotalLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: var(--ds-c-blue);

  margin: 0;
  padding: 0;
`;
const CalculatorTotalValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: var(--ds-c-blue);

  margin: 0;
  padding: 0;
`;

// const Learnmore = styled.a`
//   color: var(--ds-c-blue);
//   font-size: 12px;
//   font-weight: 500;
//   line-height: 15px;
//   text-decoration: none;
// `;

const Divider = styled.div`
  height: 1px;
  background-color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 20px;
`;

export default TotalPayForm;
