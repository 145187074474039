import styled from 'styled-components';
import { arrayUtils } from 'utils';
import ReceiverTableSort from './components/ReceiverTableSort';

const Receivers = ({
  currentPage,
  totalPages,
  receivers,
  setReceivers,
  searchValue,
  applyFiltered,
  setTotalPages,
  setCurrentPage,
  sortOrder,
  setSortOrder,
  totalElements,
  setTotalElements,
  isFetching,
  setFetching,
  currentTab,
}) => {
  return (
    <ReceiversStyled $isEmpty={arrayUtils.isArrayEmpty(receivers)}>
      <ReceiverTableSort
        currentPage={currentPage}
        totalPages={totalPages}
        receivers={receivers}
        setReceivers={setReceivers}
        searchValue={searchValue}
        applyFiltered={applyFiltered}
        setTotalPages={setTotalPages}
        setCurrentPage={setCurrentPage}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        totalElements={totalElements}
        setTotalElements={setTotalElements}
        isFetching={isFetching}
        setFetching={setFetching}
        currentTab={currentTab}
      />
    </ReceiversStyled>
  );
};

const ReceiversStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: ${(props) => (props.$isEmpty ? '24px' : '24px')};
  height: ${(props) => (props.$isEmpty ? '690px' : 'fit-content')};
  align-items: ${(props) => (props.$isEmpty ? 'center' : 'start')};
`;

export default Receivers;
