import styled from 'styled-components';
import { arrayUtils } from 'utils';
import { DASHBOARD_PREFIX } from 'constants';
import { useLocation } from 'react-router-dom';
import TransactionTableSort from './components/TransactionTableSort';

const Transactions = ({
  currentPage,
  totalPages,
  transactions,
  setTransactions,
  searchValue,
  currentSearchBy,
  applyFiltered,
  setTotalPages,
  setCurrentPage,
  sortOrder,
  setSortOrder,
  totalElements,
  setTotalElements,
  isFetching,
  setFetching,
}) => {
  const { pathname } = useLocation();
  const isDashboardPage = pathname.includes(DASHBOARD_PREFIX);

  return (
    <TransactionsStyled $isEmpty={arrayUtils.isArrayEmpty(transactions)} $isDashboardPage={isDashboardPage}>
      <TransactionTableSort
        currentPage={currentPage}
        totalPages={totalPages}
        transactions={transactions}
        setTransactions={setTransactions}
        searchValue={searchValue}
        currentSearchBy={currentSearchBy}
        applyFiltered={applyFiltered}
        setTotalPages={setTotalPages}
        setCurrentPage={setCurrentPage}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        totalElements={totalElements}
        setTotalElements={setTotalElements}
        isFetching={isFetching}
        setFetching={setFetching}
      />
    </TransactionsStyled>
  );
};

const TransactionsStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: ${(props) => (props.$isDashboardPage ? '60px' : '0')};
  padding-top: ${(props) => {
    if (props.$isDashboardPage && props.$isEmpty) {
      return '0px';
    } else if (!props.$isDashboardPage && props.$isEmpty) {
      return '0px';
    } else {
      return '16px';
    }
  }};
  height: ${(props) => {
    if (props.$isDashboardPage && props.$isEmpty) {
      return '330px';
    } else if (!props.$isDashboardPage && props.$isEmpty) {
      return '690px';
    } else {
      return 'fit-content';
    }
  }};
  align-items: ${(props) => (props.$isEmpty ? 'center' : 'start')};
`;

export default Transactions;
