import CustomSearchDropdown from 'components/common/CustomSearchDropdown';
import { getOccupationOptions } from 'components/common/func';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  REGISTER_OCCUPATION_NAME,
  REGISTER_PERSONAL_DETAILS_OCCUPATION_FIELDS,
  REGISTER_PREVIOUS_EMPLOYED_NAME,
} from '../../../../../constants';
import { CONFIG_OCCUPATION_EXTRAS } from '../../../../../constants/common';
import useStore from '../../../../../hooks/useStore';

const CustomOccupationDropdown = ({
  information,
  validation,
  setInformation,
  setValidation,
}) => {
  const { state } = useStore();
  const { configOccupation } = state;

  const [occupations, setOccupations] = useState(null);

  useEffect(() => {
    setOccupations({
      [REGISTER_OCCUPATION_NAME]: getOccupationOptions(configOccupation),
      [REGISTER_PREVIOUS_EMPLOYED_NAME]: configOccupation,
    });
  }, [configOccupation]);

  const isPreviousOccupationField = (field) =>
    field.name === REGISTER_PREVIOUS_EMPLOYED_NAME;

  const isHidePreviousOccupation = (field) =>
    isPreviousOccupationField(field) &&
    !CONFIG_OCCUPATION_EXTRAS.find(
      (occupation) => occupation === information[REGISTER_OCCUPATION_NAME]?.key
    );

  return (
    <>
      {REGISTER_PERSONAL_DETAILS_OCCUPATION_FIELDS.map((field) => (
        <CustomOccupationDropdownStyled
          key={field.name}
          $isHidden={isHidePreviousOccupation(field)}
        >
          <CustomSearchDropdown
            field={field}
            data={occupations?.[field.name]}
            keyFind="key"
            keyTranslate="value"
            noResultText="form_text_no_result"
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
          />
        </CustomOccupationDropdownStyled>
      ))}
    </>
  );
};

const CustomOccupationDropdownStyled = styled.div`
  position: relative;
  width: 100%;

  display: ${(props) => props.$isHidden && 'none'};
`;

export default CustomOccupationDropdown;
