/* eslint-disable no-extra-boolean-cast */
import { checkFormInputValidation } from 'components/common/func';
import TooltipCommon from 'components/common/TooltipCommon';
import TooltipCommonMobile from 'components/common/TooltipCommon/mobile';
import { DEBOUNCE_SEC, DESKTOP_MIN_WIDTH } from 'constants';
import {
  FX_ADD_TRANSACTION_ENTER_INFORMATION_FISTNAME_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_LASTNAME_NAME,
} from 'constants/fx';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useDebounceCallback from 'hooks/useDebounceCallback';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import { isEmpty, isString } from 'lodash';
import { useRef } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { stringUtils } from 'utils';

const FormInputValidation = ({
  field,
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();
  const { isMobile, isTablet, isDesktop } = useDetectDevice();

  const { isMakePayment } = useFxAddTransaction();

  const isDisabled = isMakePayment;

  const validRef = useRef(null);
  // useScrollIntoView(validRef, validation[field.name], [validation]);

  const { debounce: handleStringTrim } = useDebounceCallback(
    (information, setInformation) => {
      let newInformation = {
        ...information,
        [field.name]: stringUtils.trimAll(information[field.name]),
      };
      setInformation(newInformation);
    },
    DEBOUNCE_SEC
  );

  const handleOnChange = (e, field, isStringTrim = true) => {
    let { value, name } = e.target;

    let newValidation = { ...validation };

    let newInformation = {
      ...information,
      [name]: value,
    };

    if (
      name === FX_ADD_TRANSACTION_ENTER_INFORMATION_FISTNAME_NAME ||
      name === FX_ADD_TRANSACTION_ENTER_INFORMATION_LASTNAME_NAME
    ) {
      if (!Boolean(value?.trim())) {
        newInformation = {
          ...newInformation,
          ...(name === FX_ADD_TRANSACTION_ENTER_INFORMATION_FISTNAME_NAME && {
            [FX_ADD_TRANSACTION_ENTER_INFORMATION_FISTNAME_NAME]: '',
          }),
          ...(name === FX_ADD_TRANSACTION_ENTER_INFORMATION_LASTNAME_NAME && {
            [FX_ADD_TRANSACTION_ENTER_INFORMATION_LASTNAME_NAME]: '',
          }),
        };
        setInformation(newInformation);

        newValidation = checkFormInputValidation('', field, newValidation);
        setValidation(newValidation);
        return;
      }
    }

    newValidation = checkFormInputValidation(
      newInformation[name],
      field,
      newValidation
    );

    setInformation(newInformation);

    const isAllowStringTrim =
      isStringTrim &&
      isString(value) &&
      !isEmpty(value) &&
      (name === FX_ADD_TRANSACTION_ENTER_INFORMATION_FISTNAME_NAME ||
        name === FX_ADD_TRANSACTION_ENTER_INFORMATION_LASTNAME_NAME);
    if (isAllowStringTrim) {
      handleStringTrim(newInformation, setInformation);
    }

    setValidation(newValidation);
  };

  const handleOnBlur = (e, field) => {
    const isStringTrim = false;
    handleOnChange(e, field, isStringTrim);
  };

  const handleFormLabelOnClick = (e, name) => {
    e.preventDefault();

    if (isDesktop) {
      const inputEle = document.getElementsByName(name)[0];

      if (inputEle) {
        inputEle.focus();
      }
    }
  };

  return (
    <FormGroupStyled ref={validRef}>
      <FormLabel onClick={(e) => handleFormLabelOnClick(e, field.name)}>
        {t(field.label)}
        {field.isRequired && <span>*</span>}
        {!isDisabled && field.tooltip && (
          <>
            {isMobile || isTablet ? (
              <TooltipCommonMobile
                text={t(field.tooltip)}
                name={field.name}
                label={t(field.label)}
              />
            ) : (
              <TooltipCommon text={t(field.tooltip)} />
            )}
          </>
        )}
      </FormLabel>
      <FormControl
        type={field.type}
        name={field.name}
        value={information ? information[field.name] : ''}
        placeholder={t(field.placeholder)}
        isInvalid={validation[field.name]}
        onChange={(e) => handleOnChange(e, field)}
        onBlur={(e) => handleOnBlur(e, field)}
        readOnly={isDisabled}
        $isDisabled={isDisabled}
        className={field.name}
      />
      <FormControlFeedback hidden={false} type="invalid">
        {stringUtils.replaceKeyword(t(validation[field.name]), [
          {
            key: 'length',
            value: 200,
          },
        ])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: ${(props) =>
    props.$isDisabled ? 'var(--ds-c-grey-neutral)' : 'var(--ds-c-grey-dark)'};
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: ${(props) =>
    props.$isDisabled ? 'var(--ds-c-grey-disabled)' : 'var(--ds-c-white)'};
  cursor: ${(props) => props.$isDisabled && 'not-allowed'};

  &:focus {
    color: ${(props) =>
      props.$isDisabled ? 'var(--ds-c-grey-neutral)' : 'var(--ds-c-grey-dark)'};
    background: ${(props) =>
      props.$isDisabled && 'var(--ds-c-grey-disabled)!important'};
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-inline: 10px !important;
  }

  &[readonly] {
    background: ${(props) =>
      props.$isDisabled && 'var(--ds-c-grey-disabled)!important'};
    border: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputValidation;
