import SortIcon from 'components/common/Icons/SortIcon';
import TransactionStatus from 'components/FXTransactions/components/TransactionStatus';
import { fetchTransactionInformation } from 'components/FXTransactions/func';
// import {
//   PAGE_DEFAULT,
//   SORT_ASC,
//   SORT_DESC,
// } from 'constants';
import {
  FX_TRANSACTION_TABLE_HEADING,
  FX_TRANSACTION_TABLE_HEADING_TRANSLATE,
  FX_TRANSACTION_TABLE_HEADING_UNABLE_SORT,
} from 'constants/fx';
import { format } from 'helpers';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';
import { arrayUtils, objectUtils } from 'utils';
import NoResult from '../NoResult';
import NoTransaction from '../NoTransaction';
import Pagination from '../Pagination';

import FormTransactionDetails from 'components/fx/components/FormTransactionDetails';
import { FX_TRANSACTION_ADD_PREFIX } from 'constants';
import Cookies from 'helpers/cookies';
import { FX_MAKE_PAYMENT_KEY } from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useCommonStore from 'hooks/useCommonStore';
import { isEmpty, isNull } from 'lodash';
import { useNavigate } from 'react-router-dom';

const TransactionTableSort = ({
  currentPage,
  totalPages,
  transactions,
  setTransactions,
  searchValue,
  // currentSearchBy,
  applyFiltered,
  // setTotalPages,
  setCurrentPage,
  sortOrder,
  // setSortOrder,
  totalElements,
  // setTotalElements,
  isFetching,
  setFetching,
}) => {
  const { t } = useLang();
  const { token } = useAuth();
  const navigate = useNavigate();

  const [information, setInformation] = useState(null);
  const [current, setCurrent] = useState('');

  const { transactionDetails, setTransactionDetails } =
    useFxFormAddTransactionStore();
  const { setLoading } = useCommonStore();

  const isAbleSort = (heading) =>
    !FX_TRANSACTION_TABLE_HEADING_UNABLE_SORT.includes(heading);

  const isShowNoResult =
    !isFetching &&
    isNull(transactions) &&
    (!objectUtils.isObjectEmpty(applyFiltered) || searchValue);
  const isShowNoTransaction = arrayUtils.isArrayEmpty(transactions);
  const isShowBlank = isNull(transactions);

  const handleOnCloseTransactionDetailsPopup = () => {
    setTransactionDetails(null);
  };
  const handleOnOpenTransactionDetailsPopup = async (transaction) => {
    if (isEmpty(transaction)) return;

    const { orderRef, orderStatus } = transaction || {};
    setLoading(true);
    const transactionDetails = await fetchTransactionInformation(
      token,
      orderRef,
      orderStatus
    );
    setTransactionDetails(transactionDetails);
    setLoading(false);
  };
  const handleOnMakePayment = () => {
    if (isEmpty(transactionDetails)) return;
    const { orderRef } = transactionDetails;
    navigate(`${FX_TRANSACTION_ADD_PREFIX}?orderRef=${orderRef}`);
    Cookies.set(FX_MAKE_PAYMENT_KEY, true);
  };

  if (isShowNoResult) {
    return <NoResult />;
  }
  if (isShowNoTransaction) {
    return <NoTransaction />;
  }
  if (isShowBlank) {
    return null;
  }
  return (
    <>
      {!isEmpty(transactionDetails) && (
        <FormTransactionDetails
          isOpen={!isEmpty(transactionDetails)}
          onClose={handleOnCloseTransactionDetailsPopup}
          onClick={handleOnMakePayment}
        />
      )}

      <TransactionTableSortStyled>
        <Table responsive>
          <thead>
            <THeadTR>
              {Object.keys(FX_TRANSACTION_TABLE_HEADING)?.map(
                (heading, index) => (
                  <THeadTH
                    key={index}
                    $isAbleSort={isAbleSort(heading)}
                    // onClick={''}
                  >
                    {t(FX_TRANSACTION_TABLE_HEADING_TRANSLATE[heading])}
                    {isAbleSort(heading) && <SortIcon />}
                  </THeadTH>
                )
              )}
            </THeadTR>
          </thead>
          <tbody>
            {transactions?.map((tran) => (
              <TBodyTR
                key={tran?.orderRef}
                onClick={() => handleOnOpenTransactionDetailsPopup(tran)}
              >
                <TBodyTD className="orderRef">{tran?.orderRef}</TBodyTD>
                <TBodyTD>
                  <span>{tran.currencies}</span>
                </TBodyTD>
                <TBodyTD>
                  <TransactionStatus transaction={tran} />
                </TBodyTD>
                <TBodyTD className="createDateStr">{tran.createdDate}</TBodyTD>
                <TBodyTD className="amount">{`${format.toAmountStr(
                  tran.orderTotal
                )}`}</TBodyTD>
              </TBodyTR>
            ))}
          </tbody>
        </Table>
        {Boolean(transactions.length) && (
          <Pagination
            totalPages={totalPages}
            // applyFiltered={applyFiltered}
            // searchValue={searchValue}
            // currentSearchBy={currentSearchBy}
            sortOrder={sortOrder}
            totalElements={totalElements}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            transactions={transactions}
            setTransactions={setTransactions}
            current={current}
            setCurrent={setCurrent}
            setFetching={setFetching}
          />
        )}
      </TransactionTableSortStyled>
    </>
  );
};

const TransactionTableSortStyled = styled.div`
  width: 100%;
  height: 100%;

  & .table-responsive {
    width: 100%;
    height: 100%;

    & table {
      margin: 0;
    }
  }
`;

const THeadTR = styled.tr`
  display: flex;
  justify-content: space-between;
`;
const THeadTH = styled.th`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--c-sub-info);

  padding-inline: 16px !important;
  box-shadow: none;
  border: none;
  cursor: ${(props) => (props.$isAbleSort ? 'pointer' : 'default')};
  user-select: none;

  &:nth-child(1) {
    width: 15%;
  }
  &:nth-child(2) {
    width: 25%;
  }
  &:nth-child(3) {
    width: 15%;
    display: flex;
    justify-content: center;
  }
  &:nth-child(4) {
    width: 20%;
    display: flex;
    justify-content: end;
  }
  &:last-child {
    width: 25%;
    display: flex;
    justify-content: end;
    padding-right: 0px !important;
  }
`;

const TBodyTR = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 16px;
  margin-inline: 3px;
  cursor: pointer;

  &:last-child {
    margin-bottom: ${(props) => !props.isDashboardPage && '4px'};
  }
`;
const TBodyTD = styled.td`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  padding-inline: 16px !important;
  min-height: 50px;
  height: fit-content;
  border-bottom: none;
  border-radius: 12px;

  &:nth-child(1) {
    width: 15%;
  }
  &:nth-child(2) {
    width: 25%;
    span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:nth-child(3) {
    width: 15%;
    display: flex;
    justify-content: center;
  }
  &:nth-child(4) {
    width: 20%;
    display: flex;
    justify-content: end;
  }
  &:last-child {
    width: 25%;
  }

  &.orderRef {
    font-family: var(--ff-secondary);
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--ds-c-blue-hyperlink-default);
  }

  &.createDateStr {
    color: var(--ds-c-black);
  }

  &.amount {
    font-weight: 700;
    color: var(--ds-c-blue);
    display: flex;
    justify-content: end;
    text-align: right;
  }
`;

export default TransactionTableSort;
