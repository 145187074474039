/* eslint-disable no-unused-vars */
import { fetchTransactionInformation } from 'components/Transactions/func';
import ButtonLinkCommon from 'components/common/ButtonLinkCommon';
import {
  DASHBOARD_PREFIX,
  TRANSACTIONS_PREFIX,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import Cookies from 'helpers/cookies';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SET_SHOW_LOADING, SET_TRANSACTION_DETAILS } from 'store/action';
import styled from 'styled-components';
import { arrayUtils, objectUtils } from 'utils';
import NoResult from '../NoResult';
import NoTransaction from '../NoTransaction';
import TransactionItem from './components/TransactionItem';

import Endpoint from 'components/common/Endpoint';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import { isNull } from 'lodash';
import FormTransactionDetailsMobile from '../FormTransactionDetails/mobile/ver2';

const TransactionList = ({
  transactions,
  setTransactions,
  searchValue,
  applyFiltered,
  currentSearchBy,
  currentPage,
  setCurrentPage,
  setTotalElements,
  setTotalPages,
  isFetching,
  setFetching,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { transactionDetails } = state;
  const { token } = useAuth();
  const { pathname } = useLocation();
  const isDashboardPage = pathname.includes(DASHBOARD_PREFIX);
  const navigate = useNavigate();

  const [information, setInformation] = useState(null);

  const isShowNoResult =
    !isFetching &&
    isNull(transactions) &&
    (!objectUtils.isObjectEmpty(applyFiltered) || searchValue);
  const isShowNoTransaction =
    (Array.isArray(transactions) && arrayUtils.isArrayEmpty(transactions)) ||
    (isDashboardPage && isNull(transactions));
  const isShowBlank = isNull(transactions);

  useEffect(() => {
    if (transactionDetails) {
      setInformation(transactionDetails);
    }
  }, [transactionDetails]);

  const handleOnOpenTransactionDetailsPopup = async (transaction) => {
    const { refNumber, status } = transaction;

    dispatch({ type: SET_SHOW_LOADING, payload: true });

    const newInformation = await fetchTransactionInformation(
      token,
      refNumber,
      status
    );

    setInformation(newInformation);

    dispatch({ type: SET_SHOW_LOADING, payload: false });
  };

  const handleOnCloseTransactionDetailsPopup = () => {
    setInformation(null);

    dispatch({ type: SET_TRANSACTION_DETAILS, payload: null });
  };

  const handleOnMakePayment = () => {
    navigate(`${TRANSACTION_ADD_PREFIX}?refNumber=${information?.refNumber}`);

    Cookies.set(MAKE_PAYMENT_KEY, true);
  };

  if (isShowNoResult) {
    return <NoResult />;
  }
  if (isShowNoTransaction) {
    return <NoTransaction />;
  }
  if (isShowBlank) {
    return null;
  }
  return (
    <>
      {Boolean(information) && (
        <FormTransactionDetailsMobile
          isOpen={Boolean(information)}
          information={information}
          setInformation={setInformation}
          transactions={transactions}
          setTransactions={setTransactions}
          applyFiltered={applyFiltered}
          searchValue={searchValue}
          currentSearchBy={currentSearchBy}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setTotalElements={setTotalElements}
          setTotalPages={setTotalPages}
          onClose={handleOnCloseTransactionDetailsPopup}
          onClick={handleOnMakePayment}
        />
      )}

      <TransactionListStyled>
        {transactions?.map((tran) => (
          <TransactionItem
            key={tran?.refNumber}
            transaction={tran}
            onClick={() => handleOnOpenTransactionDetailsPopup(tran)}
          />
        ))}
        <Endpoint />
        {isDashboardPage && (
          <ButtonLinkCommon
            href={TRANSACTIONS_PREFIX}
            value={t('transactions_view_all_transactions')}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
            styles={{
              marginInline: 'auto',
              marginTop: '24px',
              height: 'fit-content',
              fontWeight: 'normal',
            }}
          />
        )}
      </TransactionListStyled>
    </>
  );
};

const TransactionListStyled = styled.div`
  width: 100%;
  height: fit-content;
`;

export default TransactionList;
