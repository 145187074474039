import {
  default as BankHHMTIcon,
  default as BankREMOXIcon,
} from 'assets/ver2/icons/payment/bank-transfer-tag-icon.svg';
import VisaMasterCardIcon from 'assets/ver2/icons/payment/cc-tag-icon.svg';
import PayidIcon from 'assets/ver2/icons/payment/payid-tag-icon.svg';
import AddTxnStep1Icon from 'components/common/Icons/ver2/fx-form-add-transaction/AddTxnStep1Icon';
import AddTxnStep2Icon from 'components/common/Icons/ver2/fx-form-add-transaction/AddTxnStep2Icon';
import AddTxnStep3Icon from 'components/common/Icons/ver2/fx-form-add-transaction/AddTxnStep3Icon';
import AddTxnStep4Icon from 'components/common/Icons/ver2/fx-form-add-transaction/AddTxnStep4Icon';
import AddTxnStep5Icon from 'components/common/Icons/ver2/fx-form-add-transaction/AddTxnStep5Icon';
import AddTxnStep5IconAsGuest from 'components/common/Icons/ver2/fx-form-add-transaction/AddTxnStep5IconAsGuest';
import AddTxnStep6IconAsGuest from 'components/common/Icons/ver2/fx-form-add-transaction/AddTxnStep6IconAsGuest';
import { LogUtil } from 'components/common/LogUtil';
import {
  fetchAllFXRates,
  fetchOrderDetails,
  fetchOrderDetailsAsGuest,
  fetchPickupDateDates,
  fetchPickupLocationBranches,
  fetchPickupLocationViaAusPost,
} from 'components/fx/func';
import {
  METHOD_DEPOSIT,
  METHOD_EPAYMENT,
  METHOD_PAYID,
  ORDER_EMAIL,
  STATUS_CANCELLED,
  STATUS_FAIL,
  STATUS_PROCESSING,
  STATUS_SUBMITTED,
  STATUS_SUCCESS,
  VOUCHER,
} from 'constants';
import {
  AS_GUEST,
  AS_REGISTER,
  PAY_BY_CC_AMOUNT_MAX_LIMIT,
  PAY_BY_CC_AMOUNT_MIN_LIMIT,
  PICKUP_LOCATION_NAME_REFERENCE,
  PICKUP_LOCATION_STATES,
  PICKUP_LOCATION_TYPE_1,
  PICKUP_LOCATION_TYPE_2,
} from 'constants/fx';
import { datePicker, format } from 'helpers';
import Cookies from 'helpers/cookies';
import useAuth from 'hooks/useAuth';
import useCommonStore from 'hooks/useCommonStore';
import useLang from 'hooks/useLang';
import usePage from 'hooks/usePage';
import useQuery from 'hooks/useQuery';
import useTheme from 'hooks/useTheme';
import { isArray, isEmpty } from 'lodash';
import { arrayUtils, numberUtils } from 'utils';

export const FX_MAKE_PAYMENT_KEY = 'isFxMakePayment';
export const FX_ADD_TRANSACTION_TOTAL_NUMBER_OF_STEPS_AS_REGISTER = 5;
export const FX_ADD_TRANSACTION_TOTAL_NUMBER_OF_STEPS_AS_GUEST = 6;
export const AMOUNT_MAX_REQUIRE_ADDITIONAL_INFORMATION = 10000;

// eslint-disable-next-line no-useless-escape
export const phoneFormat = `[\"(##) #### ####\"]`;

const useFxAddTransaction = () => {
  const { t } = useLang();
  const { isFxAddTransactionPage, isFxAddTransactionAsGuestPage } = usePage();
  const query = useQuery();
  const { isHHMTTheme } = useTheme();
  const { isAuthenticated } = useAuth();

  const { setLoading } = useCommonStore();

  const rid =
    (isFxAddTransactionPage || isFxAddTransactionAsGuestPage) &&
    query.get('rid');
  const orderRef =
    (isFxAddTransactionPage || isFxAddTransactionAsGuestPage) &&
    query.get('orderRef');
  const paymentStatus =
    (isFxAddTransactionPage || isFxAddTransactionAsGuestPage) &&
    query.get('paymentStatus');
  const userType = query.get('as');
  const guestCurrencyCode = query.get('currencyCode');
  const guestBuyAmount = query.get('buyAmount');
  const guestPayAmount = query.get('payAmount');
  const isFxAsGuest = !isAuthenticated && userType === AS_GUEST;
  const isFxAsRegister = isAuthenticated && userType === AS_REGISTER;
  const guestEmail = query.get('email');
  const guestMobile = query.get('mobile');

  const isMakePayment = Cookies.get(FX_MAKE_PAYMENT_KEY) || orderRef;
  const isDisabled =
    paymentStatus === STATUS_FAIL ||
    paymentStatus === STATUS_CANCELLED ||
    isMakePayment;

  const isCreateNewTran = !isDisabled;
  const isUpdateTran = isDisabled;

  const fxAddTransactionTotalNumberOfSteps = !isFxAsGuest
    ? FX_ADD_TRANSACTION_TOTAL_NUMBER_OF_STEPS_AS_REGISTER
    : FX_ADD_TRANSACTION_TOTAL_NUMBER_OF_STEPS_AS_GUEST;

  const getNavbarsAndBodyContentOfSidebar = () => {
    return [
      {
        Icon: AddTxnStep1Icon,
        label: t('fx_add_transaction_your_order'),
      },
      {
        Icon: AddTxnStep2Icon,
        label: t('fx_add_transaction_pickup_location'),
      },
      {
        Icon: AddTxnStep3Icon,
        label: t('add_transaction_title_payment_method'),
      },
      {
        Icon: AddTxnStep4Icon,
        label: t('fx_add_transaction_pickup_date'),
      },
      {
        Icon: AddTxnStep5Icon,
        label: t('fx_add_transaction_confirm_information'),
      },
    ];
  };

  const getNavbarsAndBodyContentOfSidebarAsGuest = () => {
    return [
      {
        Icon: AddTxnStep1Icon,
        label: t('fx_add_transaction_your_order'),
      },
      {
        Icon: AddTxnStep2Icon,
        label: t('fx_add_transaction_pickup_location'),
      },
      {
        Icon: AddTxnStep3Icon,
        label: t('add_transaction_title_payment_method'),
      },
      {
        Icon: AddTxnStep4Icon,
        label: t('fx_add_transaction_pickup_date'),
      },
      {
        Icon: AddTxnStep5IconAsGuest,
        label: t('fx_add_transaction_enter_information'),
      },
      {
        Icon: AddTxnStep6IconAsGuest,
        label: t('fx_add_transaction_identifications'),
      },
    ];
  };

  const setPickupLocationBranches = async (setBranches) => {
    try {
      const branches = await fetchPickupLocationBranches();
      setBranches(branches || null);
    } catch (error) {
      LogUtil.error('setPickupLocationBranches', error);
    }
  };

  const setPickupLocationViaAusPost = async ({
    postcode,
    setAusPost,
    setError = () => {},
  }) => {
    try {
      const ausPost = await fetchPickupLocationViaAusPost(postcode);
      if (isEmpty(ausPost)) {
        setError('form_search_location_empty');
      }
      setAusPost(ausPost || null);
    } catch (error) {
      LogUtil.error('setPickupLocationViaAusPost', error);
      setError('form_search_location_empty');
    }
  };

  const setPickupDateDates = async ({
    pickupLocation,
    pickupLocationType,
    paymentMethodName,
    setPickupDates,
    setDatesOfCurrentMonth,
    setDisabledDatesOfCurrentMonth,
    setDatesOfNextMonth,
    setDisabledDatesOfNextMonth,
    pickupDate,
    setNextMonth,
  }) => {
    try {
      let datesOfCurrentMonth = [];
      let disabledDatesOfCurrentMonth = [];
      let datesOfNextMonth = [];
      let disabledDatesOfNextMonth = [];

      const getMonth = (date) => date.split('/')[1];

      const getDatesTransform = (dates) =>
        dates.map(({ date }) => dateTransform(date));

      const { code, entity, state, postcode, rural } = pickupLocation || {};
      const payload = {
        address: {
          code,
          entity,
          fullAddress: '',
          rural:
            pickupLocationType === PICKUP_LOCATION_TYPE_2 ? rural === 'Y' : '',
          state,
          statepostcode: postcode,
        },
        paymentMethod: paymentMethodName,
      };

      let dates = await fetchPickupDateDates(payload);

      if (!isEmpty(dates) && isArray(dates)) {
        setPickupDates(dates);

        const openDates = dates.filter((date) => date.status === 'open');
        const disabledDates = dates.filter((date) => date.status !== 'open');

        const months = dates.map(({ date }) => {
          return getMonth(date);
        });
        const monthsUnique = [...new Set(months)];

        if (!isEmpty(openDates)) {
          datesOfCurrentMonth = openDates.filter(
            ({ date }) => getMonth(date) === monthsUnique?.[0]
          );
          datesOfCurrentMonth = getDatesTransform(datesOfCurrentMonth);

          datesOfNextMonth = openDates.filter(
            ({ date }) => getMonth(date) === monthsUnique?.[1]
          );
          datesOfNextMonth = getDatesTransform(datesOfNextMonth);
        }

        if (!isEmpty(disabledDates)) {
          disabledDatesOfCurrentMonth = disabledDates.filter(
            ({ date }) => getMonth(date) === monthsUnique?.[0]
          );
          disabledDatesOfCurrentMonth = getDatesTransform(
            disabledDatesOfCurrentMonth
          );

          disabledDatesOfNextMonth = disabledDates.filter(
            ({ date }) => getMonth(date) === monthsUnique?.[0]
          );
          disabledDatesOfNextMonth = getDatesTransform(
            disabledDatesOfNextMonth
          );
        }
      }

      setDatesOfCurrentMonth(datesOfCurrentMonth);
      setDisabledDatesOfCurrentMonth(disabledDatesOfCurrentMonth);
      setDatesOfNextMonth(datesOfNextMonth);
      setDisabledDatesOfNextMonth(disabledDatesOfNextMonth);

      const pickupLocationInNextMonth =
        !isEmpty(datesOfNextMonth) &&
        !isEmpty(pickupDate) &&
        datesOfNextMonth?.includes(dateTransform(pickupDate));
      if (pickupLocationInNextMonth) {
        setNextMonth(true);
      }
    } catch (error) {
      LogUtil.error('setPickupDateDates', error);
    }
  };

  const getPickupLocationFullAddress = (item) => {
    const { street, suburb, state, postcode } = item || {};
    return `${street}, ${suburb} ${state} ${postcode}`;
  };

  const getPickupLocationOpeningHours = (item) => {
    const { openingHours } = item || {};
    const openingHoursArray = openingHours?.split('/');
    const openingHoursArrayTrim = openingHoursArray?.map((o) => o.trim());
    const openingHoursTransform = openingHoursArrayTrim?.join('<br/>');
    return openingHoursTransform;
  };

  const getPayLimitRanger = () => ({
    [METHOD_EPAYMENT]: {
      payLimitMin: PAY_BY_CC_AMOUNT_MIN_LIMIT,
      payLimitMax: PAY_BY_CC_AMOUNT_MAX_LIMIT,
      feesIncluded: false,
    },
  });

  const getPaymentMethodIcon = () => ({
    [METHOD_DEPOSIT]: isHHMTTheme ? BankHHMTIcon : BankREMOXIcon,
    [METHOD_PAYID]: PayidIcon,
    [METHOD_EPAYMENT]: VisaMasterCardIcon,
  });

  const parseAmountValue = (value) => {
    return value && format.toAmountFloatStr(numberUtils.mathRound(value));
  };

  // date = 'DD/MM/YYYY'
  const getDateFormatPath = (date) => {
    if (!date) {
      date = datePicker.dateUTCToDateStr(datePicker.getToday());
    }
    return date?.split('/');
  };
  const dateTransform = (date) => {
    if (!date) return '';
    const [dd, MM, yyyy] = getDateFormatPath(date);
    return datePicker.dateUTCToDateISO(
      datePicker.getDateUTC(Number(dd), Number(MM) - 1, Number(yyyy))
    );
  };
  const checkDateSelectedInCurrentDates = ({
    dateSelected,
    startDate,
    endDate,
  }) => {
    const d1Milliseconds = new Date(dateSelected).getTime();
    const d2Milliseconds = new Date(startDate).getTime();
    const d3Milliseconds = new Date(endDate).getTime();
    return d1Milliseconds >= d2Milliseconds && d1Milliseconds <= d3Milliseconds;
  };

  const getAllFXRates = async (setAllFXRates) => {
    try {
      const rates = await fetchAllFXRates();
      setAllFXRates(rates || null);
      setLoading(false);
    } catch (error) {
      LogUtil.error('getAllFXRates', error);
      setLoading(false);
    }
  };

  const getTransactionStatus = () => {
    if (paymentStatus === STATUS_SUCCESS) {
      return STATUS_PROCESSING;
    }
    return STATUS_SUBMITTED;
  };

  const getTitleBodySection = () => {
    return isMakePayment
      ? t('add_transaction_title_make_payment')
      : t('add_transaction_title_new_order');
  };

  const getStatesOfPickupLocationSwiperSorted = ({
    data,
    currentSelected,
    pickupLocationSelected,
  }) => {
    if (isEmpty(data)) return [];
    if (isEmpty(currentSelected)) return data;
    if (isEmpty(pickupLocationSelected)) return data;
    if (pickupLocationSelected?.state !== data[0]?.value) {
      const stateFirst =
        PICKUP_LOCATION_STATES.find(
          ({ value }) => value === pickupLocationSelected?.state
        ) || PICKUP_LOCATION_STATES[0];
      const dataDeleted = arrayUtils.deleteItem({
        array: data,
        item: stateFirst,
      });
      const dataSorted = [stateFirst, ...dataDeleted];
      return dataSorted;
    }
    return data;
  };

  const removeFxMakePayment = () => {
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);
  };
  const setFxMakePayment = () => {
    Cookies.set(FX_MAKE_PAYMENT_KEY, true);
  };

  const getFxTransactionInformation = async ({ token, orderRef, email }) => {
    try {
      if (isFxAsGuest) {
        Cookies.set(ORDER_EMAIL, email);
      }
      return !isFxAsGuest
        ? await fetchOrderDetails(token, orderRef)
        : await fetchOrderDetailsAsGuest(orderRef, email);
    } catch (error) {
      LogUtil.error('getFxTransactionInformation', 'error', error);
      return null;
    }
  };

  const renderDiscountFieldLabel = (type, code) => {
    if (type === VOUCHER)
      return (
        <>
          {t('label_voucher')}{' '}
          <span className="discount-code">{`(${code})`}</span>
        </>
      );
    return <>{t('label_promotion')}</>;
  };

  const getPickupLocationName = (
    pickupLocationSelected,
    pickupLocationType
  ) => {
    if (isEmpty(pickupLocationSelected)) return '';
    if (pickupLocationType === PICKUP_LOCATION_TYPE_1) {
      return t(
        PICKUP_LOCATION_NAME_REFERENCE[pickupLocationSelected?.entity || ''],
        {
          suburb: pickupLocationSelected?.suburb,
        }
      )?.toUpperCase();
    }
    if (pickupLocationSelected?.name && !pickupLocationSelected?.suburb)
      return `${pickupLocationSelected?.name}`;
    return `${pickupLocationSelected?.name} - ${pickupLocationSelected?.suburb}`;
  };

  return {
    rid,
    orderRef,
    paymentStatus,
    isFxAsGuest,
    isFxAsRegister,
    guestEmail,
    guestMobile,
    guestCurrencyCode,
    guestBuyAmount,
    guestPayAmount,
    isMakePayment,
    isDisabled,
    isCreateNewTran,
    isUpdateTran,
    fxAddTransactionTotalNumberOfSteps,
    getNavbarsAndBodyContentOfSidebar,
    getNavbarsAndBodyContentOfSidebarAsGuest,
    setPickupLocationBranches,
    setPickupLocationViaAusPost,
    setPickupDateDates,
    getPickupLocationFullAddress,
    getPickupLocationOpeningHours,
    getPayLimitRanger,
    getPaymentMethodIcon,
    parseAmountValue,
    dateTransform,
    checkDateSelectedInCurrentDates,
    getAllFXRates,
    getTransactionStatus,
    getTitleBodySection,
    getStatesOfPickupLocationSwiperSorted,
    removeFxMakePayment,
    setFxMakePayment,
    getFxTransactionInformation,
    renderDiscountFieldLabel,
    getPickupLocationName,
  };
};

export default useFxAddTransaction;
