import ArrowIconImg from 'assets/ver2/icons/double-right-arrow.svg';
import {
  DASHBOARD_PREFIX,
  DESKTOP_COLLAPSE_MIN_WIDTH,
  DESKTOP_MIN_WIDTH,
  EXCHANGE_NAV,
  EXCHANGE_TITLE,
  FX_DASHBOARD_PREFIX,
  FX_TRANSACTIONS_PREFIX,
  ORDER_EMAIL,
  RECEIVERS_EDIT_SUFIX,
  RECEIVERS_PREFIX,
  RECEIVERS_SUB_MENU,
  RECEIVERS_TITLE,
  REMITTANCE_NAV,
  REMITTANCE_TITLE,
  TRANSACTIONS_PREFIX,
} from 'constants';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import { FX_MAKE_PAYMENT_KEY } from 'hooks/fx/useFxAddTransaction';
import useLang from 'hooks/useLang';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useStore from '../../../hooks/useStore';
import {
  SET_MENU_EXPAND,
  SET_REFRESH_RECEIVER_LIST,
} from '../../../store/action';

const CollapsibleSideMenu = () => {
  const { t } = useLang();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { dispatch, state } = useStore();
  const { isMenuExpand } = state;

  const [title, setTitle] = useState({});
  const [navs, setNavs] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const isDashboardPage = pathname.includes(DASHBOARD_PREFIX);
  const isReceiversPage = pathname.includes(RECEIVERS_PREFIX);
  const isTransactionsPage = pathname.includes(TRANSACTIONS_PREFIX);
  const isEditReceiverPage = pathname.includes(RECEIVERS_EDIT_SUFIX);
  // const isAddReceiverPage = pathname.includes(RECEIVERS_ADD_SUFIX);
  // const isAddTransactionPage = pathname === `${TRANSACTION_ADD_PREFIX}`;
  // const isEventPage = pathname === `${EVENT_PREFIX}`;
  const isFxDashboardPage = pathname.includes(FX_DASHBOARD_PREFIX);
  const isFxTransactionsPage = pathname.includes(FX_TRANSACTIONS_PREFIX);

  const isNavLinkActive = (href) => pathname.includes(href);

  useEffect(() => {
    if (
      isDashboardPage ||
      isReceiversPage ||
      isEditReceiverPage ||
      isTransactionsPage
    ) {
      setTitle(REMITTANCE_TITLE);
      setNavs(REMITTANCE_NAV);
    } else if (isFxDashboardPage || isFxTransactionsPage) {
      setTitle(EXCHANGE_TITLE);
      setNavs(EXCHANGE_NAV);
    } else {
      setTitle({});
      setNavs([]);
    }
  }, [pathname]);

  const handleTabOnClick = (tabIndex) => {
    dispatch({ type: SET_REFRESH_RECEIVER_LIST, payload: tabIndex });
    setCurrentTab(tabIndex);
  };

  const toggleMenu = () => {
    dispatch({ type: SET_MENU_EXPAND, payload: !isMenuExpand });
  };

  return (
    <SideMenu isMenuExpand={isMenuExpand}>
      <SideMenuContainer>
        <PageTitle $isMenuExpand={isMenuExpand}>
          <Icon
            src={title.icon}
            width={40}
            height={40}
            $isMenuExpand={isMenuExpand}
          />
          <span>{t(title.text) || null}</span>
        </PageTitle>
        <NavLinks>
          {navs.map((nav) => (
            <NavLink
              key={nav.href}
              to={nav.href}
              onClick={() => {
                Cookies.remove(MAKE_PAYMENT_KEY);
                Cookies.remove(FX_MAKE_PAYMENT_KEY);
                Cookies.remove(ORDER_EMAIL);
                navigate(nav.href);
              }}
              $isMenuExpand={isMenuExpand}
              $isActive={isNavLinkActive(nav.href)}
            >
              {isNavLinkActive(nav.href) ? (
                <>
                  {nav.iconActive && (
                    <Icon src={nav.iconActive} width={24} height={24} />
                  )}
                </>
              ) : (
                <>
                  {nav.icon && <Icon src={nav.icon} width={24} height={24} />}
                </>
              )}
              <span>{t(nav.text) || null}</span>
            </NavLink>
          ))}

          {(isDashboardPage || isTransactionsPage) && (
            <NavLink
              key={RECEIVERS_TITLE.href}
              to={RECEIVERS_TITLE.href}
              onClick={() => {
                Cookies.remove(MAKE_PAYMENT_KEY);
                navigate(RECEIVERS_TITLE.href);
                handleTabOnClick(0);
              }}
              $isMenuExpand={isMenuExpand}
            >
              <Icon src={RECEIVERS_TITLE.icon} width={24} height={24} />
              <span>{t(RECEIVERS_TITLE.text) || null}</span>
            </NavLink>
          )}

          {isReceiversPage && (
            <ReceiversMenu>
              <MenuTitle $isMenuExpand={isMenuExpand}>
                <Icon src={RECEIVERS_TITLE.iconActive} width={24} height={24} />
                <span>{t(RECEIVERS_TITLE.text) || null}</span>
              </MenuTitle>
              <SubMenu $isMenuExpand={isMenuExpand}>
                {RECEIVERS_SUB_MENU.map((nav, index) => (
                  <SubMenuItem
                    key={nav.text}
                    onClick={() => handleTabOnClick(index)}
                    $isMenuExpand={isMenuExpand}
                    $isActive={currentTab === index}
                  >
                    {currentTab === index ? (
                      <>
                        {nav.iconActive && (
                          <Icon1
                            src={nav.iconActive}
                            width={20}
                            height={20}
                            $isMenuExpand={isMenuExpand}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {nav.icon && (
                          <Icon1
                            src={nav.icon}
                            width={24}
                            height={24}
                            $isMenuExpand={isMenuExpand}
                          />
                        )}
                      </>
                    )}
                    <span>{t(nav.text) || null}</span>

                    {currentTab === index ? (
                      <>
                        {nav.iconActive && (
                          <Icon2
                            src={nav.arrowActive}
                            width={16}
                            height={16}
                            $isMenuExpand={isMenuExpand}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {nav.icon && (
                          <Icon2
                            src={nav.arrow}
                            width={16}
                            height={16}
                            $isMenuExpand={isMenuExpand}
                          />
                        )}
                      </>
                    )}
                  </SubMenuItem>
                ))}
              </SubMenu>
            </ReceiversMenu>
          )}
        </NavLinks>

        <ToggleButton $isMenuExpand={isMenuExpand} onClick={toggleMenu}>
          <ArrowIcon
            src={ArrowIconImg}
            width={24}
            height={24}
            $isMenuExpand={isMenuExpand}
          />
        </ToggleButton>
      </SideMenuContainer>
    </SideMenu>
  );
};

export default CollapsibleSideMenu;

const SideMenu = styled.div`
  position: fixed;
  top: 72px;
  left: 0;
  height: 100vh;
  width: ${(props) => (props.isMenuExpand ? '300px' : '98px')};
  transition: width 0.3s ease-in-out;
  display: flex;
  flex-direction: column;

  background-color: var(--ds-c-bg-light-grey);

  padding: 40px 24px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    left: calc(50% - ${DESKTOP_MIN_WIDTH / 2}px);
  }

  @media screen and (min-width: ${DESKTOP_COLLAPSE_MIN_WIDTH}px) {
    left: calc(50% - ${DESKTOP_COLLAPSE_MIN_WIDTH / 2}px);
  }
`;

const SideMenuContainer = styled.div``;

const PageTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 0 40px 0;
  margin: 0 0 40px 0;
  border-bottom: 1px solid var(--ds-c-blue-remox-light);

  span {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: var(--ds-c-blue);
  }
  ${(props) =>
    props.$isMenuExpand
      ? `
        span {
          opacity: 1;
          visibility: visible;
        }
      `
      : `
        span {
          // display: none;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.1s ease-in-out, visibility 0.1s ease-in-out;
        }
    `};
`;

const Icon = styled.img``;

const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const NavLink = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  width: 100%;
  height: 50px;
  padding: 0 10px;
  border-radius: 8px;
  background-color: rgba(212, 220, 229, 0.15);

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--ds-c-blue-remox-hover);
  text-decoration: none;
  cursor: pointer;

  ${(props) =>
    props.$isActive
      ? `
        background-color: var(--ds-c-white);
        box-shadow: var(--ds-bs-4);
        color: var(--ds-c-blue-dark);
      `
      : ``};

  ${(props) =>
    props.$isMenuExpand
      ? `
        span {
          display: inline-block;
          opacity: 1;
          visibility: visible;
        }
      `
      : `
        padding-left: 13px;
        span {
          display: none;
          opacity: 0;
          visibility: hidden;
          transition: display 0.1s , ease-in-outopacity 0.1s ease-in-out, visibility 0.1s ease-in-out;
        }
    `};
`;

const ReceiversMenu = styled.div`
  // display: flex;
  // flex-direction: column;
  padding: 0 10px;
  border-radius: 8px;
  background-color: var(--ds-c-white);
  box-shadow: var(--ds-bs-4);
  padding-bottom: 10px;
`;

const MenuTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  width: 100%;
  height: 50px;
  line-height: 50px;
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--ds-c-blue-remox-hover);
  color: var(--ds-c-blue-dark);

  ${(props) =>
    props.$isMenuExpand
      ? `
        display: inline-block;
        opacity: 1;
        visibility: visible;
      `
      : `
        display: none;
        opacity: 0;
        visibility: hidden;
        transition: display 0.1s , ease-in-outopacity 0.1s ease-in-out, visibility 0.1s ease-in-out;
      `};

  img {
    margin-right: 10px;
  }
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 0 24px;
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  ${(props) =>
    props.$isMenuExpand
      ? ``
      : `
        padding-left: 0;
        padding-top: 10px;
    `};
`;

const SubMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  height: 30px;
  cursor: pointer;
  padding: 0 10px;

  ${(props) =>
    props.$isActive
      ? `
        background-color: var(--ds-c-blue-remox-hover);
        color: var(--ds-c-white);
      `
      : ``};

  ${(props) =>
    props.$isMenuExpand
      ? `
        span {
          display: inline-block;
          opacity: 1;
          visibility: visible;
        }
      `
      : `
        justify-content: center;
        padding: 0;
        span {
          display: none;
          opacity: 0;
          visibility: hidden;
          transition: display 0.1s , ease-in-outopacity 0.1s ease-in-out, visibility 0.1s ease-in-out;
        }
    `};
`;

const Icon1 = styled.img`
  ${(props) =>
    props.$isMenuExpand
      ? `
      display: none;
      opacity: 0;
      visibility: hidden;
      transition: display 0.1s , ease-in-outopacity 0.1s ease-in-out, visibility 0.1s ease-in-out;
    `
      : `
      display: inline-block;
      opacity: 1;
      visibility: visible;
  `};
`;

const Icon2 = styled.img`
  ${(props) =>
    props.$isMenuExpand
      ? `
      display: inline-block;
      opacity: 1;
      visibility: visible;
    `
      : `
      display: none;
      opacity: 0;
      visibility: hidden;
      transition: display 0.1s , ease-in-outopacity 0.1s ease-in-out, visibility 0.1s ease-in-out;
  `};
`;

const ToggleButton = styled.button`
  background: transparent;
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 120px;
  right: 24px;

  left: ${(props) => (props.$isMenuExpand ? '252px' : '37px')};

  border: none;
  cursor: pointer;
`;

const ArrowIcon = styled.img`
  transform: ${(props) =>
    props.$isMenuExpand ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: 0.3s ease;
`;
