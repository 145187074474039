/* eslint-disable no-unused-vars */
import CloseIcon from 'assets/ver2/icons/close-black-icon.svg';
import DoneIcon from 'assets/ver2/icons/done-small-icon.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import BodySectionHeader from 'components/fx/components/BodySectionHeader';
import { DESKTOP_MIN_WIDTH, FX_TRANSACTION_ADD_PREFIX } from 'constants';
import {
  FX_ADD_TRANSACTION_PICKUP_LOCATION,
  PICKUP_LOCATION_TYPE_1,
  PICKUP_LOCATION_TYPE_2,
  RESET_FX_ADD_TRANSACTION_PICKUP_LOCATION,
} from 'constants/fx';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useScrollIntoViewDelay from 'hooks/useScrollIntoViewDelay';
import useTheme from 'hooks/useTheme';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import { domUtils } from 'utils';
import CalculatorForm from '../CalculatorForm';
import PickupLocationDetails from '../PickupLocationDetails';
import PickupLocationTypes from '../PickupLocationTypes';

const initialFormField = RESET_FX_ADD_TRANSACTION_PICKUP_LOCATION;

const PickupLocation = ({ handleOnToggleCancelPopup }) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const { isDesktop } = useDetectDevice();

  const [information, setInformation] = useState(initialFormField);
  const [branches, setBranches] = useState(null);
  const [
    isTriggerOpenSelectPickupLocationDrawer,
    setTriggerOpenSelectPickupLocationDrawer,
  ] = useState(false);

  const { getTitleBodySection, setPickupLocationBranches, isFxAsRegister } =
    useFxAddTransaction();
  const {
    pickupLocation,
    addTransactionCurrentStep: currentStep,
    setAddTransactionCurrentStep,
    pickupLocationType,
    pickupLocationSelected,
    setPickupLocation,
  } = useFxFormAddTransactionStore();

  const isNextDisabled = isEmpty(pickupLocationSelected);

  // scroll to calculator form
  const validRef = useRef(null);
  useScrollIntoViewDelay(validRef, validRef, []);

  useEffect(() => {
    if (isEmpty(branches)) {
      setPickupLocationBranches(setBranches);
    }
  }, [branches]);

  useEffect(() => {
    if (isEmpty(information[FX_ADD_TRANSACTION_PICKUP_LOCATION])) {
      setInformation(pickupLocation);
    }
  }, [pickupLocation]);

  useEffect(() => {
    if (isTriggerOpenSelectPickupLocationDrawer) {
      if (pickupLocationType === PICKUP_LOCATION_TYPE_2) {
        const navbarToggleEle = document.getElementsByClassName(
          `${PICKUP_LOCATION_TYPE_2}-navbar-toggle`
        )[0];
        if (navbarToggleEle) {
          navbarToggleEle.click();
        }
      } else if (pickupLocationType === PICKUP_LOCATION_TYPE_1) {
        const navbarToggleEle = document.getElementsByClassName(
          `${PICKUP_LOCATION_TYPE_1}-navbar-toggle`
        )[0];
        if (navbarToggleEle) {
          navbarToggleEle.click();
        }
      }
      setTriggerOpenSelectPickupLocationDrawer(false);
    }
  }, [pickupLocationType, isTriggerOpenSelectPickupLocationDrawer]);

  const LoginToaster = ({ closeToast }) => (
    <LoginToastStyled>
      <img src={DoneIcon} />
      <p>{t('login_success_label')}</p>
      <img
        src={CloseIcon}
        onClick={closeToast}
        style={{
          cursor: 'pointer',
          filter: isHHMTTheme ? 'var(--ds-f-c-yellow)' : 'var(--ds-f-c-blue)',
        }}
      />
    </LoginToastStyled>
  );
  const triggerLoginToaster = () => toast(<LoginToaster />);
  useEffect(() => {
    if (isFxAsRegister) {
      triggerLoginToaster();
      domUtils.replaceUrlWithoutReload({ path: FX_TRANSACTION_ADD_PREFIX });
    }
  }, [isFxAsRegister]);

  const getTitleSection = () => {
    if (isEmpty(pickupLocationSelected))
      return t('fx_add_transaction_pickup_location');
    return t('fx_add_transaction_confirm_pickup_location');
  };

  const handleBackStep = () => {
    setAddTransactionCurrentStep(currentStep - 1);

    const isCachePickupLocation =
      pickupLocationType !== '' &&
      !isEmpty(information[FX_ADD_TRANSACTION_PICKUP_LOCATION]);
    if (isCachePickupLocation) {
      setPickupLocation(information);
    }
  };
  const handleNextStep = () => {
    const isAllowSubmit = true;
    if (isAllowSubmit) {
      setAddTransactionCurrentStep(currentStep + 1);
    }
  };
  const renderCalculatorFormMobile = () => {
    if (isDesktop) return null;
    return <CalculatorForm ref={validRef} isCalculatorFormAccordion={true} />;
  };
  const renderActionsButton = () => {
    return (
      <NextWrap>
        {renderCalculatorFormMobile()}
        <ActionWrapper>
          <ButtonCommonWrap
            style={{
              width: isEmpty(pickupLocationSelected) && '100%',
            }}
          >
            <ButtonCommon
              value={t('button_back')}
              onClick={handleBackStep}
              styles={{
                margin: '0px',
                width: !isDesktop && '100%',
              }}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
              borderNameVariable={isDesktop && '--border-filter'}
              isFill={false}
            />
          </ButtonCommonWrap>
          <ButtonCommon
            value={t('button_confirm')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: isDesktop ? '150px' : '50%',
              ...(isEmpty(pickupLocationSelected) && {
                opacity: 0,
                cursor: 'default',
                display: !isDesktop && 'none',
              }),
            }}
            onClick={handleNextStep}
            isDisabled={isNextDisabled}
            isUseKeyDown={!isNextDisabled}
          />
        </ActionWrapper>
      </NextWrap>
    );
  };

  const renderPickupLocationBodyContent = () => {
    if (isEmpty(pickupLocationSelected))
      return (
        <PickupLocationTypes
          branches={isEmpty(branches) ? [] : branches}
          information={information}
          setInformation={setInformation}
        />
      );
    return (
      <PickupLocationDetails
        branches={isEmpty(branches) ? [] : branches}
        information={information}
        setInformation={setInformation}
        setTriggerOpenSelectPickupLocationDrawer={
          setTriggerOpenSelectPickupLocationDrawer
        }
      />
    );
  };

  return (
    <>
      <ToastContainerStyled
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={true}
      />
      <BodySectionHeader
        titleBodySection={getTitleBodySection()}
        titleSection={getTitleSection()}
        CalculatorComponent={isDesktop ? <CalculatorForm /> : null}
        onToggleCancelPopup={handleOnToggleCancelPopup}
      />
      <PickupLocationStyled>
        {renderPickupLocationBodyContent()}
        {renderActionsButton()}
      </PickupLocationStyled>
    </>
  );
};

const PickupLocationStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -8px;
  padding-inline: 0px;
  padding-bottom: 140px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 437px;
    margin-top: 0px;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

export const NextWrap = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: fit-content;
  margin-inline: -16px;
  padding-inline: 0px;
  background: transparent;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    width: 437px;
    height: fit-content;
    border-top: unset;
  }
`;
export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  width: 100%;
  background: var(--ds-c-white);
  border-top: 1px solid var(--ds-bg-2);
  padding-inline: 16px;
  margin: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 100%;
    height: 44px;
    border-top: unset;
    padding-inline: unset;
    margin: 0px;
  }
`;
export const ButtonCommonWrap = styled.div`
  width: 50%;
`;

const ToastContainerStyled = styled(ToastContainer)`
  padding: 0;
  margin: 0;
  min-width: fit-content !important;
  width: fit-content !important;
  min-height: fit-content !important;
  height: fit-content !important;
  box-shadow: 0px 4px 15px 0px #0000001f;

  & .Toastify__toast--default {
    padding: 0;
    margin: 0;
    min-width: fit-content !important;
    width: fit-content !important;
    min-height: fit-content !important;
    height: fit-content !important;

    & .Toastify__toast-body {
      padding: 0;
      margin: 0;
      min-width: fit-content !important;
      width: fit-content !important;
      min-height: fit-content !important;
      height: fit-content !important;
    }

    & .Toastify__close-button {
      display: none;
    }

    & .Toastify__progress-bar--wrp
  }
`;
const LoginToastStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  padding: 4px 10px;
  border-radius: 8px;
  margin: 0;

  & p {
    ${paragraphMixin};
    font-weight: 500;
  }
`;

export default PickupLocation;
