import { useState, useEffect } from 'react';
import useLang from 'hooks/useLang';
import styled from 'styled-components';
import ExchangeRateItemMobile from '../../../ExchangeRateItem/mobile/ver2';
import ActiveItem from '../../../ExchangeRateItem/components/ActiveItem';

const ExchangeRateSectionMobile = ({ remittanceRatesData  }) => {
  const { t } = useLang();
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    if (remittanceRatesData) {
      const countryCodes = Object.keys(remittanceRatesData);
      if (countryCodes.length > 0) {
        setCurrent(countryCodes[0]);
      }
    }
  }, [remittanceRatesData]);

  if (!remittanceRatesData) {
    return null;
  }

  return (
    <Wrapper>
      <ActiveItem
        country={current}
        rates={remittanceRatesData[current]}
      />
      <RatesWrap>
        {t('label_exchange_rate_per_aud')}
        <Rates>
        {Object.keys(remittanceRatesData).map((country) => (
          <>
            <ExchangeRateItemMobile
              key={country}
              country={country}
              rates={remittanceRatesData[country]}
              isActive={country === current}
              onClick={() => setCurrent(country)}
            />
          </>
        ))}
        </Rates>
      </RatesWrap>

    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  height: fit-content;
  padding-bottom: 20px;

  font-family: var(--ff-primary);
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
`;

const RatesWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const Rates = styled.div`
  display: flex;
  gap: 8px;
`;

export default ExchangeRateSectionMobile;
