import SearchIcon from 'assets/ver2/icons/search-icon.svg';
import {
  fetchTransactionsFiltered,
  getBodyPayloadFiltered,
} from 'components/Transactions/func';
import {
  DEBOUNCE_SEC,
  DESKTOP_MIN_WIDTH,
  ENTER_KEY_DOWN,
  PAGE_DEFAULT,
  TRANSACTION_TABLE_SORT_ORDER_INITIAL,
} from 'constants';
import { refNumberPhoneValid } from 'helpers';
import useAuth from 'hooks/useAuth';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { arrayUtils } from 'utils';
import CustomSearchDropdown from './components/CustomSearchDropdown';

const TRANSACTION_SEARCH_INPUT_FIELD = 'transaction-search-input-field';

const REF_NUMBER_LENGTH_MAX_OLD = 6;
const REF_NUMBER_LENGTH_MAX = 7;

const SearchTransaction = ({
  applyFiltered,
  currentSearchBy,
  setCurrentSearchBy,
  searchValue,
  setSearchValue,
  transactions,
  setTransactions,
  setTotalPages,
  setCurrentPage,
  setSortOrder,
  setTotalElements,
  setFetching = () => {},
}) => {
  const { token } = useAuth();
  const { t } = useLang();

  const { debounce } = useDebounceCallback((value) => {
    if (currentSearchBy === 'receiverName') {
      if (value) {
        value = value.trim();

        let values = value.split(' ');

        if (!arrayUtils.isArrayEmpty(values)) {
          values = arrayUtils.removeElement(values, '');

          value = values.join(' ');
        }

        setSearchValue(value);
      }
    }

    if (!value) {
      if (isEmpty(transactions)) return;

      const bodyPayload = getBodyPayloadFiltered(applyFiltered);

      setTransactions(null);
      fetchTransactionsFiltered({
        token,
        body: bodyPayload,
        page: PAGE_DEFAULT,
        setTransactions,
        setCurrentPage,
        setTotalPages,
        setTotalElements,
      });
    }
  }, DEBOUNCE_SEC);

  const handleOnChange = (e) => {
    const { value } = e.target;
    if (
      currentSearchBy === 'refNumber' &&
      value?.length < REF_NUMBER_LENGTH_MAX + 1
    ) {
      setSearchValue(value);

      if (!value) {
        debounce(value);
      }
    }

    if (currentSearchBy === 'receiverName') {
      setSearchValue(value);

      debounce(value);
    }
  };

  const searchByRefNumber = async (value) => {
    if (
      currentSearchBy === 'refNumber' &&
      (value?.length === REF_NUMBER_LENGTH_MAX ||
        value?.length === REF_NUMBER_LENGTH_MAX_OLD)
    ) {
      const valueValid = refNumberPhoneValid(value);

      if (valueValid) {
        let bodyPayload = getBodyPayloadFiltered(applyFiltered);

        bodyPayload = {
          ...bodyPayload,
          refNumber: valueValid,
        };

        setTransactions(null);
        fetchTransactionsFiltered({
          token,
          body: bodyPayload,
          page: PAGE_DEFAULT,
          setTransactions,
          setCurrentPage,
          setTotalPages,
          setTotalElements,
          setFetching,
        });
      }
    }
  };
  const searchByReceiverName = async (value) => {
    if (currentSearchBy === 'receiverName') {
      let bodyPayload = getBodyPayloadFiltered(applyFiltered);

      bodyPayload = {
        ...bodyPayload,
        receiverName: value,
      };

      setTransactions(null);
      fetchTransactionsFiltered({
        token,
        body: bodyPayload,
        page: PAGE_DEFAULT,
        setTransactions,
        setCurrentPage,
        setTotalPages,
        setTotalElements,
        setFetching,
      });
    }
  };
  const handleSearchTransaction = () => {
    setFetching(true);

    searchByRefNumber(searchValue);

    searchByReceiverName(searchValue);

    setSortOrder(TRANSACTION_TABLE_SORT_ORDER_INITIAL);
  };

  const handleOnKeyDown = (e) => {
    const { key } = e;

    if (key === ENTER_KEY_DOWN) {
      handleSearchTransaction();
    }
  };

  return (
    <SearchTransactionStyled>
      <CustomSearchDropdown
        searchBy={currentSearchBy}
        setSearchBy={setCurrentSearchBy}
        setSearchValue={setSearchValue}
      />
      <SearchInput
        name={TRANSACTION_SEARCH_INPUT_FIELD}
        type="text"
        value={searchValue || ''}
        placeholder={t('label_search_input')}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        readOnly={!currentSearchBy}
      />
      <SearchIconStyled
        src={SearchIcon}
        width={24}
        height={24}
        alt=""
        onClick={handleSearchTransaction}
      />
    </SearchTransactionStyled>
  );
};

const SearchTransactionStyled = styled.div`
  position: relative;
  height: 44px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: unset;
  }
`;

const SearchInput = styled.input`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-black);
  border: 1px solid var(--ds-c-blue-remox-light);
  border-radius: 8px;
  background: var(--ds-c-white);
  width: calc(100% - 52px);
  height: 44px;
  padding: 10px;
  padding-left: 128px;
  padding-right: 40px;
  float: left;

  &:focus,
  &:focus-visible {
    border: 1px solid #b0b6bd !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-blue-disabled);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    width: 300px;
  }
`;
const SearchIconStyled = styled.img`
  position: absolute;
  top: 10px;
  left: calc(100% - 84px);
  cursor: pointer;
  user-select: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    left: 266px;
  }
`;

export default SearchTransaction;
