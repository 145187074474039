const { REGISTER_ADDRESS_UNITNAME_NAME } = require('constants');
const { REGISTER_MIDDLENAME_NAME } = require('constants');

const handleFieldValidation = (
  value,
  name,
  validation,
  msgInvalid,
  checkValidFn
) => {
  if (value) {
    const isValid = Boolean(checkValidFn(value));

    if (isValid) {
      validation = {
        ...validation,
        [name]: '',
      };
    }

    if (!isValid) {
      validation = {
        ...validation,
        [name]: msgInvalid,
      };
    }
  }

  return validation;
};

const handleFieldEmptyValidation = (value, name, validation, msgRequired) => {
  if (!value) {
    validation = {
      ...validation,
      [name]: msgRequired,
    };
  }

  if (name === REGISTER_MIDDLENAME_NAME) {
    validation = {
      ...validation,
      [name]: '',
    };
  }

  if (name === REGISTER_ADDRESS_UNITNAME_NAME) {
    validation = {
      ...validation,
      [name]: '',
    };
  }

  return validation;
};

export const checkFormInputValidation = (value, field, validation) => {
  const { name, checkValidFn, msgInvalid, msgRequired } = field;

  validation = handleFieldEmptyValidation(value, name, validation, msgRequired);

  validation = handleFieldValidation(
    value,
    name,
    validation,
    msgInvalid,
    checkValidFn
  );

  return validation;
};
