import { api } from 'api';
import {
  CONFIG_OCCUPATION_EXTRAS,
  COUNTRIES,
  GREETING_AFTERNOON_TEXT,
  GREETING_EVENING_TEXT,
  GREETING_MORNING_TEXT,
} from 'constants';

export const getItemsConfig = (
  items = [],
  countryCode = null,
  dmCode = null,
  spCode = null,
  currencyCode = null
) => {
  let itemConfig;

  const configByCountryCode = countryCode
    ? items?.find((i) => i?.countryCode === countryCode) || items[0]
    : items[0];

  if (countryCode && dmCode && spCode && currencyCode)
    return (itemConfig =
      items?.find(
        (i) =>
          i?.countryCode === countryCode &&
          i?.dmCode === dmCode &&
          i?.spCode === spCode &&
          i?.currencyCode === currencyCode
      ) || configByCountryCode);

  if (countryCode && dmCode && spCode)
    return (itemConfig =
      items?.find(
        (i) =>
          i?.countryCode === countryCode &&
          i?.dmCode === dmCode &&
          i?.spCode === spCode
      ) || configByCountryCode);

  if (countryCode && dmCode && currencyCode)
    return (itemConfig =
      items?.find(
        (i) =>
          i?.countryCode === countryCode &&
          i?.dmCode === dmCode &&
          i?.currencyCode === currencyCode
      ) || configByCountryCode);

  if (countryCode && spCode && currencyCode)
    return (itemConfig =
      items?.find(
        (i) =>
          i?.countryCode === countryCode &&
          i?.spCode === spCode &&
          i?.currencyCode === currencyCode
      ) || configByCountryCode);

  if (countryCode && dmCode)
    return (itemConfig =
      items?.find(
        (i) => i?.countryCode === countryCode && i?.dmCode === dmCode
      ) || configByCountryCode);

  if (countryCode && spCode)
    return (itemConfig =
      items?.find(
        (i) => i?.countryCode === countryCode && i?.spCode === spCode
      ) || configByCountryCode);

  if (countryCode && !dmCode && !spCode && !currencyCode)
    itemConfig = configByCountryCode;

  return itemConfig || configByCountryCode;
};

export const getOccupationOptions = (occupations) => [
  /* eslint-disable-next-line no-unsafe-optional-chaining */
  ...occupations?.filter((o) => CONFIG_OCCUPATION_EXTRAS?.includes(o?.key)),
  ...occupations,
];

export const fetchCountries = async (callback) => {
  try {
    const { data } = await api.getCountries();

    if (data) {
      callback(data);
    }
  } catch (error) {
    console.error(error?.message);
  }
};

export const getGreetingText = () => {
  const currentHours = new Date().getHours();

  if (currentHours >= 5 && currentHours < 12) return GREETING_MORNING_TEXT;

  if (currentHours >= 12 && currentHours < 17) return GREETING_AFTERNOON_TEXT;

  return GREETING_EVENING_TEXT;
};

export const ENTER_CODE = 13;
export const BACKSPACE_CODE = 8;
export const CTRL_CODE = 17;
export const CTRLA_CODE = 65;
export const DELETE_CODE = 46;
export const NEXT_ARROW_CODE = 39;
export const PREV_ARROW_CODE = 37;

export const preventKeyDownEnter = (e) => {
  if (e.which === ENTER_CODE) {
    e.preventDefault();
    e.stopPropagation();
  }
};

export const preventOnClick = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export const checkPaymentNumberLengthValid = (value, length) => {
  if (length?.min === length?.max && value.length === length?.max) return true;

  if (value.length >= length?.min && value.length <= length?.max) return true;

  return false;
};

const handleFieldValidation = (
  value,
  name,
  validation,
  msgInvalid,
  checkValidFn
) => {
  if (value) {
    const isValid = checkValidFn ? Boolean(checkValidFn(value)) : true;

    if (isValid) {
      validation = {
        ...validation,
        [name]: '',
      };
    }

    if (!isValid) {
      validation = {
        ...validation,
        [name]: msgInvalid,
      };
    }
  }

  return validation;
};
const handleFieldEmptyValidation = (value, name, validation, msgRequired) => {
  if (!value) {
    validation = {
      ...validation,
      [name]: msgRequired,
    };
  }

  return validation;
};
export const checkFormInputValidation = (value, field, validation) => {
  const { name, checkValidFn, msgInvalid, msgRequired } = field;

  validation = handleFieldEmptyValidation(value, name, validation, msgRequired);

  validation = handleFieldValidation(
    value,
    name,
    validation,
    msgInvalid,
    checkValidFn
  );

  return validation;
};

export const checkFormInputPortalCodeValidation = (
  value,
  field,
  validation,
  config
) => {
  const { name, checkValidFn, msgInvalid, msgRequired } = field;
  const numberMaxLength = config?.max;
  const numberMinLength = config?.min;
  const isNumberMaxEqualNumberMin =
    numberMaxLength && numberMinLength && numberMaxLength === numberMinLength;

  validation = handleFieldEmptyValidation(value, name, validation, msgRequired);

  validation = handleFieldValidation(
    value,
    name,
    validation,
    msgInvalid,
    checkValidFn
  );

  if (value) {
    if (value.length < numberMinLength) {
      validation = {
        ...validation,
        [name]: msgInvalid,
      };
    }

    if (isNumberMaxEqualNumberMin && value.length < numberMinLength) {
      validation = {
        ...validation,
        [name]: msgInvalid,
      };
    }
  }

  return validation;
};

export const findCountry = (value) => COUNTRIES.find((c) => c?.value === value);
export const findCountryWithCountryName = (countryName) =>
  COUNTRIES.find((c) => c?.label === countryName);

export const fetchCountryConfig = async (countryCode) => {
  try {
    const { data } = await api.getCountryConfig(countryCode);

    return data;
  } catch (error) {
    console.error(error?.message);

    return null;
  }
};
