import { getOccupationOptions } from 'components/common/func';
import { CONFIG_OCCUPATION_EXTRAS } from 'constants/common';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import CustomSearchDropdown from 'components/common/CustomSearchDropdown';
import {
  FX_ADD_TRANSACTION_OCCUPATION_NAME,
  FX_ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS,
  FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME,
} from 'constants/fx';

const CustomOccupationDropdown = ({
  information,
  setInformation,
  validation,
  setValidation,
  isDisabled,
}) => {
  const { state } = useStore();
  const { configOccupation } = state;

  const [occupations, setOccupations] = useState(null);

  useEffect(() => {
    setOccupations({
      [FX_ADD_TRANSACTION_OCCUPATION_NAME]:
        getOccupationOptions(configOccupation),
      [FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]: configOccupation,
    });
  }, [configOccupation]);

  const isPreviousOccupationField = (field) =>
    field.name === FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME;

  const isHidePreviousOccupation = (field) =>
    isPreviousOccupationField(field) &&
    (!CONFIG_OCCUPATION_EXTRAS.find(
      (occupation) =>
        occupation === information[FX_ADD_TRANSACTION_OCCUPATION_NAME]?.key
    ) ||
      isDisabled);

  return (
    <>
      {FX_ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS.map((field) => (
        <CustomOccupationDropdownStyled
          key={field.name}
          $isHidden={isHidePreviousOccupation(field)}
        >
          <CustomSearchDropdown
            field={field}
            isConfigRequired={true}
            data={occupations?.[field.name]}
            keyFind="key"
            keyTranslate="value"
            noResultText="form_text_no_result"
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            isDisabled={isDisabled}
          />
        </CustomOccupationDropdownStyled>
      ))}
    </>
  );
};

const CustomOccupationDropdownStyled = styled.div`
  position: relative;
  width: 100%;

  display: ${(props) => props.$isHidden && 'none'};
`;

export default CustomOccupationDropdown;
