import { TABLET_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import React from 'react';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import { FreeMode, Mousewheel, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import FXTopRateItem from '../../FXTopRateItem';

const FXRatesSection = ({ FXRatesData }) => {
  const { t } = useLang();

  if (!FXRatesData) {
    return null;
  }
  return (
    <Wrapper>
      <SwiperStyled
        direction={'horizontal'}
        slidesPerView={'auto'}
        freeMode={true}
        scrollbar={true}
        mousewheel={true}
        grabCursor={true}
        modules={[FreeMode, Mousewheel, Scrollbar]}
      >
        {FXRatesData.map((rate, index) => (
          <SwiperSlide key={index} style={{ width: 'auto' }}>
            <FXTopRateItem
              key={rate.currency}
              currency={rate.currencyCode}
              rate={parseFloat(rate.rate)}
              rateData={rate}
            />
          </SwiperSlide>
        ))}
      </SwiperStyled>
      {t('label_buy_rate_per_aud_full')}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: calc(100% + 24px);
  height: fit-content;

  font-family: var(--ff-primary);
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: var(--ds-c-black);
  margin-bottom: 16px;
`;

const SwiperStyled = styled(Swiper)`
  height: fit-content;
  padding-bottom: 12px;
  padding-right: 108px;
  z-index: 0;

  & .swiper-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 21px;

    & .swiper-slide {
      // height: fit-content;
      cursor: pointer;

      @media screen and (min-width: ${TABLET_MIN_WIDTH}px) {
        flex: 1;
      }
    }
  }

  @media screen and (min-width: ${TABLET_MIN_WIDTH}px) {
    padding-right: 24px;
  }

  & .swiper-scrollbar-horizontal {
    display: none;
  }
`;

export default FXRatesSection;
