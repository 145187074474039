import { fetchCountries } from 'components/common/func';
import { COUNTRY_FLAG_REFERENCES } from 'constants';
import { format } from 'helpers';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const ExchangeRateItem = ({ country, rates, isActive, onClick }) => {
  const { t } = useLang();

  const [countries, setCountries] = useState(null);

  useEffect(() => {
    fetchCountries((data) => {
      setCountries(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCountryNameByCode = (countryCode) => {
    if (isEmpty(countries)) return null;
    const country = countries.find((country) => country.code === countryCode);
    return country ? country.name : '';
  };

  const receivedCurrencies = (num) => {
    if (num === 1) {
      return t('label_received_currency', {
        number: num,
      });
    }
    return t('label_received_currencies', {
      number: num,
    });
  };

  if (Object.keys(rates).length === 0) return null;
  if (isActive) {
    return (
      <ItemWrap $isActive={isActive} onClick={onClick}>
        <CountryName $isActive={isActive}>
          <FlagIcon
            src={COUNTRY_FLAG_REFERENCES[country]}
            $isActive={isActive}
          />
          {getCountryNameByCode(country)}
        </CountryName>
        <CurrenciesWrap>
          {receivedCurrencies(Object.keys(rates).length)}
          <Currencies $isActive={isActive}>
            {Object.keys(rates).length === 0 ? (
              <div>No rates available</div>
            ) : (
              Object.keys(rates).map((currencyCode) => {
                const rateInfo = rates[currencyCode];
                return (
                  <div key={currencyCode}>
                    <span>{rateInfo.currency.code}</span>{' '}
                    {format.toAmountStr2(rateInfo.rate)}
                  </div>
                );
              })
            )}
          </Currencies>
        </CurrenciesWrap>
      </ItemWrap>
    );
  }
  return (
    <ItemWrap onClick={onClick}>
      <CountryName>
        <FlagIcon src={COUNTRY_FLAG_REFERENCES[country]} />
        {getCountryNameByCode(country)}
      </CountryName>
      {receivedCurrencies(Object.keys(rates).length)}
      <Currencies>
        {Object.keys(rates).map((currencyCode) => {
          const rateInfo = rates[currencyCode];
          return <span key={currencyCode}>{rateInfo.currency.code}</span>;
        })}
      </Currencies>
      <Link>{t('link_view_details')}</Link>
    </ItemWrap>
  );
};

const ItemWrap = styled.div`
  padding: 18px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  // min-height: 212px;
  flex: 1;

  border-radius: 8px;
  background: var(--ds-c-white);
  border: 1px solid var(--ds-c-blue-remox-light);
  color: var(--ds-c-black);
  cursor: pointer;
  user-select: none;

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: left;

  ${(props) =>
    props.$isActive
      ? `
        flex: 2;
        background: var(--ds-c-black);
        color: var(--ds-c-white);
        padding: 12px 10px 12px 10px;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.12);
    `
      : `
        align-items: stretch;
        position: relative;
    `};
`;

const CountryName = styled.h4`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  font-family: var(--ff-primary);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0;

  ${(props) =>
    props.$isActive
      ? `
        flex-direction: row;
        align-items: center;
        color: var(--ds-c-yellow);
      `
      : `
        flex: 1;
        margin-bottom: 12px;
      `};
`;

const FlagIcon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border: 1px solid var(--ds-c-black);
  border-radius: 50%;

  ${(props) =>
    props.$isActive
      ? `
        width: 40px;
        height: 40px;
    `
      : ``};
`;

const CurrenciesWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Currencies = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  margin: 8px 0 0 0;
  font-weight: 700;

  span {
    border: 1px solid var(--ds-c-black);
    border-radius: 8px;
    padding: 0 5px;
    line-height: 20px;
  }

  ${(props) =>
    props.$isActive
      ? `
      flex-direction: column;
      font-size: 16px;
      gap: 6px;
      
      span {
        border: 1px solid var(--ds-c-white);
        background: var(--ds-c-white);
        color: var(--ds-c-black);
        height: 22px;
        font-size: 12px;
        padding: 2px 5px;
      }
      `
      : `
      margin-bottom: 15px;
    `};
`;

const Link = styled.div`
  width: 100%;
  color: var(--ds-c-blue-hyperlink-default);
  text-align: center;
`;

export default ExchangeRateItem;
