import { useState } from 'react';
import useLang from 'hooks/useLang';
import styled from 'styled-components';
import ExchangeRateItem from '../../ExchangeRateItem/ver2';
import { DESKTOP_MIN_WIDTH, } from 'constants';

const ExchangeRateSection = ({ remittanceRatesData }) => {
  const { t } = useLang();
  const [current, setCurrent] = useState(0);

  if (!remittanceRatesData) {
    return null;
  }

  return (
    <Wrapper>
      <RatesWrap>
        {Object.keys(remittanceRatesData).map((country, index) => (
          <>
            <ExchangeRateItem
              key={country}
              country={country}
              rates={remittanceRatesData[country]}
              isActive={index === current}
              onClick={() => setCurrent(index)}
            />
          </>
        ))}
      </RatesWrap>
      {t('label_exchange_rate_per_aud')}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  height: fit-content;

  font-family: var(--ff-primary);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--ds-c-black);

  padding-bottom: 40px;
`;

const RatesWrap = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 10px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    gap: 21px;
  }
`;

export default ExchangeRateSection;
