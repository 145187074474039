import LoadingScreen from 'components/common/LoadingScreen';
import {
  fetchConfigOccupation,
  fetchConfigPurpose,
  fetchConfigPurposeOfBusiness,
  fetchConfigRelationship,
  fetchConfigRelationshipOfBusiness,
  fetchConfigSourcesOfWealth,
  fetchResources,
} from 'config';
import {
  HHMT_THEME,
  THEME_REFERENCE,
  THEME_STYLESHEET_REFERENCE,
} from 'constants';
import useSmartLook from 'hooks/smartlook/useSmartLook';
import useFirebase from 'hooks/useFirebase';
import useLang from 'hooks/useLang';
import useLoadCSS from 'hooks/useLoadCSS';
import usePageYOffset from 'hooks/usePageYOffset';
import useRetriveNotificationsRealtimeDB from 'hooks/useRetriveNotificationsRealtimeDB';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Router from './router';

function App() {
  const { locate } = useLang();
  const { requestPushApiSubcribe } = useFirebase();
  const { recordAPI } = useSmartLook({ isInit: false });

  const [isResourcesLoaded, setResourcesLoaded] = useState(false);
  const [pageYOffset, setPageYOffset] = useState(0);

  useLoadCSS(
    THEME_STYLESHEET_REFERENCE[
      THEME_REFERENCE[process.env.REACT_APP_APP_NAME || HHMT_THEME]
    ]
  );

  useEffect(() => {
    fetchResources(() => setResourcesLoaded(true));
  }, []);

  useEffect(() => {
    if (isResourcesLoaded && locate) {
      Promise.all([
        fetchConfigOccupation(locate),
        fetchConfigRelationship(locate),
        fetchConfigRelationshipOfBusiness(locate),
        fetchConfigPurpose(locate),
        fetchConfigPurposeOfBusiness(locate),
        fetchConfigSourcesOfWealth(locate),
      ]);

      requestPushApiSubcribe();
    }
  }, [isResourcesLoaded, locate]);

  usePageYOffset(setPageYOffset);

  useRetriveNotificationsRealtimeDB();

  useEffect(() => {
    return () => {
      recordAPI.close();
    };
  }, []);

  if (!isResourcesLoaded)
    return (
      <LoadingScreen
        pageYOffset={pageYOffset}
        loadingStyles={{ background: '#fff' }}
      />
    );

  return (
    <AppStyled id="application-root">
      <Router />
    </AppStyled>
  );
}

const AppStyled = styled.div`
  height: fit-content;
  width: 100%;

  @media screen and (min-width: 1280px) {
    background-repeat: no-repeat;
    background-color: var(--c-primary);
  }

  @media screen and (min-width: 1280px) {
    background-size: auto 92%;
  }

  @media screen and (min-width: 1440px) {
    background-size: auto 93%;
  }

  @media screen and (min-width: 1600px) {
    background-size: auto 93%;
  }

  @media screen and (min-width: 1680px) {
    background-size: auto 94%;
  }

  @media screen and (min-width: 1920px) {
    background-size: auto 94%;
  }

  @media screen and (min-width: 2560px) {
    background-size: auto 96%;
  }

  @media screen and (min-width: 3840px) {
    background-size: auto 97%;
  }

  @media screen and (min-width: 5120px) {
    background-size: cover;
  }
`;

export default App;
