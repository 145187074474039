/* eslint-disable no-unused-vars */
import ArrowIcon from 'assets/ver2/icons/arrow-down-icon.svg';
import DoubleArrowIcon from 'assets/ver2/icons/double-arrow-icon.svg';
import {
  DESKTOP_MIN_WIDTH,
  EN_LANG,
  TABLET_MIN_WIDTH,
  VI_LANG,
} from 'constants';
import {
  FX_ADD_TRANSACTION_PICKUP_DATE,
  FX_ADD_TRANSACTION_PICKUP_DATE_DATA,
} from 'constants/fx';
import { addDays } from 'date-fns';
import { enUS, vi } from 'date-fns/locale';
import { datePicker } from 'helpers';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useLang from 'hooks/useLang';
import { useEffect } from 'react';
import { Calendar } from 'react-date-range';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';

const LOCALE_REFERENCE = {
  [EN_LANG]: enUS,
  [VI_LANG]: vi,
};

const CustomPickupDateDatepickerSelected = ({
  dates,
  date,
  minDate,
  maxDate,
  disabledDates,
  isDateInCurrentDates,
  onClearValidation,
  styles = {},
}) => {
  const { locate } = useLang();

  const { setPickupDate } = useFxFormAddTransactionStore();

  const handleOnSelect = (dateUTC) => {
    const dateStr = datePicker.dateUTCToDateStr(dateUTC);
    const pickupDateData = dates?.find((date) => date?.date === dateStr);

    const newPickupDate = {
      [FX_ADD_TRANSACTION_PICKUP_DATE]: dateStr,
      [FX_ADD_TRANSACTION_PICKUP_DATE_DATA]: pickupDateData,
    };
    setPickupDate(newPickupDate);

    onClearValidation();
  };

  useEffect(() => {
    const previewEle = document.getElementsByClassName(
      'rdrMonthAndYearPickers'
    )?.[0];
    if (previewEle) {
      const previewValue = previewEle.innerHTML;
      let previewValueTransform = previewValue;
      if (!previewValue.includes(',')) {
        previewValueTransform = previewValue.replace(' ', ', ');
      }
      previewEle.innerHTML = previewValueTransform;
    }
  }, []);

  return (
    <>
      <CalendarStyled
        style={{ ...styles }}
        $isDateInCurrentDates={isDateInCurrentDates}
      >
        <Calendar
          date={date ? addDays(new Date(date), 0) : new Date()}
          minDate={addDays(new Date(minDate), 0)}
          maxDate={addDays(new Date(maxDate), 0)}
          disabledDates={disabledDates}
          onChange={handleOnSelect}
          locale={LOCALE_REFERENCE[locate]}
          months={1}
          dateFormat="MM/DD/YYYY"
          dayDisplayFormat="d"
          monthDisplayFormat="LLLL"
          weekdayDisplayFormat="EEEEEE"
          showMonthArrow={false}
          showMonthAndYearPickers={false}
        />
      </CalendarStyled>
    </>
  );
};

const CalendarStyled = styled.div`
  width: 100%;
  max-width: 100%;
  height: fit-content;
  display: flex;
  margin-top: 2px;
  padding: 16px 0px;
  background: var(--ds-c-white);
  box-shadow: unset;
  border-radius: 8px;
  max-width: 375px;

  @media screen and (min-width: ${TABLET_MIN_WIDTH}px) {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
    max-width: 375px;
    padding: 16px;
    height: 100%;
  }

  & .rdrCalendarWrapper {
    width: 100%;
    height: 100%;
    background: var(--ds-c-white);
  }

  & .rdrMonthAndYearWrapper {
    padding: 0px 0px 10px 0px;
    height: fit-content;
    background: var(--ds-c-white);

    button {
      background: url(${DoubleArrowIcon}) no-repeat;
      background-position: center;
      height: 17px;
      width: 17px;
      margin: 0;

      & i {
        display: none;
      }
    }

    button.rdrNextButton {
      transform: rotate(180deg);
    }

    & .rdrMonthAndYearPickers {
      ${paragraphMixin};
      height: 17px;
      width: 100%;
      display: flex;
      justify-content: center;

      span {
        margin: 0px;

        select {
          font-family: var(--ff-primary);
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #0e1012;
          padding-top: 0px;
          padding-bottom: 0px;
          background: url(${ArrowIcon}) no-repeat right;

          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      & .rdrMonthPicker {
        order: 1;

        select {
          padding-left: 30px;
          padding-right: 22px;
          max-width: 134px;
        }
      }

      & .rdrYearPicker {
        order: -1;

        select {
          padding-left: 38.5px;
          padding-right: 22px;

          option {
            &:hover {
              background: var(--ds-c-grey-light);
            }

            &:active {
              color: var(--ds-c-grey-dark);
            }
          }
        }
      }
    }
  }

  & .rdrMonthsVertical {
    flex-direction: row;
    background: var(--ds-c-white);

    & .rdrMonth {
      padding: 0;
      width: 100%;

      & .rdrWeekDays {
        display: flex;
        justify-content: center;
        align-items:
        height: 42px;

        span {
          font-family: var(--ff-primary);
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;

          color: var(--ds-c-blue-hyperlink-default);
          width: 49px;
          max-width: 49px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-inline: 4px;

          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      & .rdrDays {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          width: 49px;
          height: 42px;

          span {
            top: 0;
            left: 0;
            border-radius: 8px;
            width: 49px;
            height: 42px;

            span {
              font-family: var(--ff-primary);
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              color: var(--ds-c-black);

              display: flex;
              justify-content: center;
              align-items: center;

              @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }

          &:hover {
            span {
              // background-color: var(--bg-primary);
              background-color: unset;
            }
          }

          &:active,
          &:focus,
          &:target,
          &:visited,
          &:focus-visible {
            border: none;
            box-shadow: none;
            outline: none;
            background-color: var(--bg-primary);
            border-radius: 8px;
          }
        }

        button.rdrDayPassive {
          span {
            span {
              color: var(--ds-c-blue-remox-light);
              background-color: var(--ds-c-white);
            }
          }
        }

        button.rdrDayDisabled {
          background-color: var(--ds-c-white);
          cursor: default;
          span {
            span {
              color: var(--ds-c-blue-remox-light);
            }
          }
          &:hover {
            span {
              background-color: unset;
            }
          }
        }

        button.rdrDayToday {
          span {
            span {
              &::after {
                background: none;
                // border: var(--border-secondary);
                border: none;
                border-radius: 8px;
                width: 49px;
                height: 42px;
                top: 0;
                left: 0;
                transform: unset;
              }
            }
          }

          span.rdrSelected {
            background-color: var(--ds-c-white) !important;
            border: var(--border-secondary) !important;
          }
        }

        button.rdrDay {
          span.rdrSelected {
            background-color: ${(props) =>
              props.$isDateInCurrentDates
                ? 'var(--bg-primary)'
                : 'transparent'};
          }
        }

        button.rdrDayHovered {
          span {
            span {
              font-weight: 500;
            }

            &::after {
              border: none;
              box-shadow: none;
              outline: none;
              background-color: transparent;
              border-radius: 8px;
              width: 49px;
              height: 42px;
            }
          }
        }

        button.rdrDay {
          span.rdrDayEndPreview {
            border: none;
            box-shadow: none;
            outline: none;
            background-color: transparent;
            border-radius: 8px;
            width: 49px;
            height: 42px;
          }
        }

        button.rdrDayDisabled {
          span {
            span {
              color: var(--ds-c-blue-remox-light) !important;
              background-color: var(--ds-c-white) !important;
            }
          }
        }
      }
    }
  }

  & .rdrDayPassive {
    opacity: 0;
  }
`;

export default CustomPickupDateDatepickerSelected;
