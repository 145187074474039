/* eslint-disable no-unused-vars */
import LoginHHMTBanner from 'assets/images/login-hhmt-banner.png';
import LoginRemoxBanner from 'assets/images/login-remox-banner.png';
import EventAvailableModal from 'components/Events/components/EventAvailableModal';
import EventAvailableModalMobile from 'components/Events/components/EventAvailableModal/mobile';
import {
  ACCOUNT_ACTIVATION_PREFIX,
  CALCULATOR_FORM_PREFIX,
  DESKTOP_MIN_WIDTH,
  EVENT_PREFIX,
  FORGOT_PASSWORD_PREFIX,
  FX_CALCULATOR_FORM_PREFIX,
  FX_PAYMENT_PROCESS_PREFIX,
  HHMT_ACOUNT_ACTIVATION_TITLE,
  HHMT_FORGOT_PASSWORD_TITLE,
  HHMT_LOGIN_TITLE,
  HHMT_REGISTRATION_TITLE,
  HHMT_RESET_PASSWORD_TITLE,
  HHMT_TITLE,
  LOGIN_PREFIX,
  NOT_FOUND_PREFIX,
  NOT_FOUND_TITLE,
  PAYMENT_PROCESS_PREFIX,
  RECEIVERS_EDIT_SUFIX,
  REGISTER_PREFIX,
  REMOX_ACOUNT_ACTIVATION_TITLE,
  REMOX_FORGOT_PASSWORD_TITLE,
  REMOX_LOGIN_TITLE,
  REMOX_REGISTRATION_TITLE,
  REMOX_RESET_PASSWORD_TITLE,
  REMOX_TITLE,
  RESET_PASSWORD_PREFIX,
} from 'constants';
import useAuth from 'hooks/useAuth';
import { useDetectDevice } from 'hooks/useDetectDevice';
import usePage from 'hooks/usePage';
import usePageYOffset from 'hooks/usePageYOffset';
import usePreventPushBottom from 'hooks/usePreventPushBottom';
import useRefreshTokenTrigger from 'hooks/useRefreshTokenTrigger';
import useScaleMobileViewport from 'hooks/useScaleMobileViewport';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import useTokenExpired from 'hooks/useTokenExpired';
import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { domUtils } from 'utils';
import BackdropGlobal from '../BackdropGlobal';
import Footer from '../Footer';
import Footer2 from '../Footer2';
import Header from '../Header/ver2';
import HeaderMobile from '../Header/ver2/mobile';
import LoadingScreen from '../LoadingScreen';
import PendingScreen from '../PendingScreen';
import PortalModal from '../PortalModal';
import RefreshTokenPopup from '../RefreshTokenPopup';

const pathsHideFooter = [
  LOGIN_PREFIX,
  FORGOT_PASSWORD_PREFIX,
  RESET_PASSWORD_PREFIX,
  ACCOUNT_ACTIVATION_PREFIX,
  REGISTER_PREFIX,
  RECEIVERS_EDIT_SUFIX,
  PAYMENT_PROCESS_PREFIX,
  FX_PAYMENT_PROCESS_PREFIX,
  CALCULATOR_FORM_PREFIX,
  FX_CALCULATOR_FORM_PREFIX,
  EVENT_PREFIX,
];

const pathsShowFooter2 = [
  LOGIN_PREFIX,
  FORGOT_PASSWORD_PREFIX,
  RESET_PASSWORD_PREFIX,
  ACCOUNT_ACTIVATION_PREFIX,
];

const pathsHideHeader = [
  PAYMENT_PROCESS_PREFIX,
  CALCULATOR_FORM_PREFIX,
  FX_PAYMENT_PROCESS_PREFIX,
  FX_CALCULATOR_FORM_PREFIX,
  EVENT_PREFIX,
];

const Layout = ({ children }) => {
  const { state } = useStore();
  const { backdrop, isLoading, isPending, eventAvailable, portalModal } = state;
  const { isAuthenticated } = useAuth();
  const { isHHMTTheme } = useTheme();
  const { isDesktop, isMobile, isTablet } = useDetectDevice();

  const { isShowPortalModal } = portalModal || {};

  const { pathname } = useLocation();
  const isFooterHidden = pathsHideFooter?.find((path) => {
    if (path === RECEIVERS_EDIT_SUFIX && pathname.includes(path)) {
      return true;
    }

    if (path === CALCULATOR_FORM_PREFIX && pathname.includes(path)) {
      return true;
    }

    if (path === FX_CALCULATOR_FORM_PREFIX && pathname.includes(path)) {
      return true;
    }

    if (path === FX_PAYMENT_PROCESS_PREFIX && pathname.includes(path)) {
      return true;
    }

    if (path === PAYMENT_PROCESS_PREFIX && pathname.includes(path)) {
      return true;
    }

    if (path === EVENT_PREFIX && pathname.includes(path)) {
      return true;
    }

    return pathname === path;
  });
  const isShowFooter2 = pathsShowFooter2?.find((path) => {
    return pathname === path;
  });

  const isHeaderHidden = pathsHideHeader?.find((path) => {
    if (path === CALCULATOR_FORM_PREFIX && pathname.includes(path)) {
      return true;
    }

    if (path === FX_CALCULATOR_FORM_PREFIX && pathname.includes(path)) {
      return true;
    }

    if (path === FX_PAYMENT_PROCESS_PREFIX && pathname.includes(path)) {
      return true;
    }

    if (path === PAYMENT_PROCESS_PREFIX && pathname.includes(path)) {
      return true;
    }

    if (path === EVENT_PREFIX && pathname.includes(path)) {
      return true;
    }

    return pathname === path;
  });

  const [pageYOffset, setPageYOffset] = useState(0);

  const {
    isLoginPage,
    isForgotPasswordPage,
    isResetPasswordPage,
    isAccountActivationPage,
    isDashboardPage,
    isTransactionsPage,
    isReceiversPage,
    isEditReceiverPage,
    isAddReceiverPage,
    isAddTransactionPage,
    isEventPage,
    isFxDashboardPage,
    isFxTransactionsPage,
    isFxAddTransactionPage,
  } = usePage();

  const isShowEventModal = isAuthenticated && !isEventPage && eventAvailable;

  const getDocumentTitleReference = (pathname) => {
    if (pathname.includes(RESET_PASSWORD_PREFIX)) {
      return isHHMTTheme
        ? HHMT_RESET_PASSWORD_TITLE
        : REMOX_RESET_PASSWORD_TITLE;
    }

    if (pathname.includes(ACCOUNT_ACTIVATION_PREFIX)) {
      return isHHMTTheme
        ? HHMT_ACOUNT_ACTIVATION_TITLE
        : REMOX_ACOUNT_ACTIVATION_TITLE;
    }

    if (pathname.includes(LOGIN_PREFIX)) {
      return isHHMTTheme ? HHMT_LOGIN_TITLE : REMOX_LOGIN_TITLE;
    }

    if (pathname.includes(FORGOT_PASSWORD_PREFIX)) {
      return isHHMTTheme
        ? HHMT_FORGOT_PASSWORD_TITLE
        : REMOX_FORGOT_PASSWORD_TITLE;
    }

    if (pathname.includes(NOT_FOUND_PREFIX)) {
      return NOT_FOUND_TITLE;
    }

    if (pathname.includes(REGISTER_PREFIX)) {
      return isHHMTTheme ? HHMT_REGISTRATION_TITLE : REMOX_REGISTRATION_TITLE;
    }

    return isHHMTTheme ? HHMT_TITLE : REMOX_TITLE;
  };
  useEffect(() => {
    domUtils.addDocumentTitle(getDocumentTitleReference(pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const appRootEle = document.getElementById('application-root');
    const bodyEle = document.getElementsByTagName('body')[0];
    const customContainerEle =
      document.getElementsByClassName('custom-container')[0];

    if (
      isDesktop &&
      (isLoginPage ||
        isForgotPasswordPage ||
        isResetPasswordPage ||
        isAccountActivationPage)
    ) {
      appRootEle.style.backgroundPositionX = `center`;
      appRootEle.style.backgroundPositionY = `72px`;
      customContainerEle.style.paddingBottom = `0px`;
      customContainerEle.style.display = `grid`;

      if (isHHMTTheme) {
        appRootEle.style.backgroundImage = `url(${LoginHHMTBanner})`;
        appRootEle.style.backgroundColor = `var(--ds-c-grey-dark)`;

        return;
      }

      appRootEle.style.backgroundImage = `url(${LoginRemoxBanner})`;
      appRootEle.style.backgroundColor = `var(--ds-c-blue-dark)`;

      return;
    }

    if (
      isDesktop &&
      (isDashboardPage ||
        isAddTransactionPage ||
        isTransactionsPage ||
        isAddReceiverPage ||
        isReceiversPage ||
        isFxAddTransactionPage ||
        isFxDashboardPage ||
        isFxTransactionsPage)
    ) {
      appRootEle.style.backgroundImage = 'none';
      appRootEle.style.backgroundColor = isHHMTTheme
        ? `var(--ds-c-bg-light-grey)`
        : 'var(--ds-c-bg-light-grey)';
      bodyEle.style.backgroundColor = isHHMTTheme
        ? `var(--ds-c-bg-light-grey)`
        : 'var(--ds-c-bg-light-grey)';
      customContainerEle.style.paddingBottom = `48px`;

      return;
    }

    appRootEle.style.backgroundImage = 'none';
    appRootEle.style.backgroundColor = '#ffffff';
    bodyEle.style.backgroundColor = '#ffffff';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isHHMTTheme,
    isDesktop,
    isLoginPage,
    isForgotPasswordPage,
    isResetPasswordPage,
    isAccountActivationPage,
    isDashboardPage,
    isTransactionsPage,
    isReceiversPage,
    isEditReceiverPage,
    isAddReceiverPage,
    isAddTransactionPage,
    isAddTransactionPage,
    isFxDashboardPage,
    isFxAddTransactionPage,
  ]);

  useTokenExpired();

  useRefreshTokenTrigger();

  usePageYOffset(setPageYOffset);

  useScaleMobileViewport();

  usePreventPushBottom();

  if (isMobile || isTablet) {
    return (
      <>
        {isLoading && <LoadingScreen pageYOffset={pageYOffset} />}

        <PendingScreen pageYOffset={pageYOffset} isOpen={isPending} />

        {isAuthenticated && <RefreshTokenPopup />}

        {isShowEventModal && (
          <EventAvailableModalMobile isOpen={isShowEventModal} />
        )}

        {isShowPortalModal && <PortalModal />}

        <ContainerStyled className="custom-container">
          {backdrop?.isShow && <BackdropGlobal pageYOffset={pageYOffset} />}
          {isHeaderHidden ? null : <HeaderMobile />}
          {children}
        </ContainerStyled>
      </>
    );
  }
  return (
    <>
      {isLoading && <LoadingScreen pageYOffset={pageYOffset} />}

      <PendingScreen pageYOffset={pageYOffset} isOpen={isPending} />

      {isAuthenticated && <RefreshTokenPopup />}

      {isShowEventModal && <EventAvailableModal isOpen={isShowEventModal} />}

      {isShowPortalModal && <PortalModal />}

      {isHeaderHidden ? null : <Header />}

      <ContainerStyled
        className="custom-container"
        $isScreenFit={
          isLoginPage ||
          isForgotPasswordPage ||
          isResetPasswordPage ||
          isAccountActivationPage
        }
      >
        {children}
      </ContainerStyled>

      {isFooterHidden ? null : <Footer />}

      {isShowFooter2 ? <Footer2 /> : null}
    </>
  );
};

const ContainerStyled = styled(Container)`
  width: 100%;
  max-width: 100%;
  height: fit-content;
  margin-inline: auto;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    max-width: ${DESKTOP_MIN_WIDTH}px;
    height: ${(props) => props.$isScreenFit && '100vh'};
  }

  @media screen and (min-width: 1440px) {
    max-width: 1440px;
  }

  @media screen and (min-width: 1920px) {
    height: ${(props) => props.$isScreenFit && 'calc(100vh - 93px)'};
  }
`;

export default Layout;
