/* eslint-disable no-unused-vars */
import {
  default as IntroductionHHMTIcon,
  default as IntroductionRemoxIcon,
} from 'assets/ver2/icons/introduction-hhmt-grey-fill-icon.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import { METHOD_DEPOSIT, METHOD_EPAYMENT, METHOD_PAYID } from 'constants';
import useLang from 'hooks/useLang';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

import InstructionPopup from 'components/Transactions/components/InstructionPopup';
import InstructionPopupMobile from 'components/Transactions/components/InstructionPopup/mobile';
import TransactionStatus from 'components/Transactions/components/TransactionStatus';
import {
  CURRENCY_FLAG_REFERENCES,
  CURRENCY_NAME_REFERENCE,
  DESKTOP_MIN_WIDTH,
  METHOD_TRANSLATE_REFERECES,
  MOBILE_MIN_WIDTH,
  PAYMENT_METHOD_REFERENCE,
  STATUS_CANCELLED,
  STATUS_COMPLETED,
  STATUS_DELIVERING,
  STATUS_ON_HOLD,
  STATUS_PROCESSING,
  STATUS_SUBMITTED,
  TABLET_MAX_WIDTH,
} from 'constants';
import { PICKUP_LOCATION_TYPE_1, PICKUP_LOCATION_TYPE_2 } from 'constants/fx';
import { CloseBlueIcon } from 'constants/icon';
import { format } from 'helpers';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useTheme from 'hooks/useTheme';
import { isEmpty } from 'lodash';
import { Fragment, useState } from 'react';
import { paragraphMixin } from 'styles';
import { stringUtils } from 'utils';
import CalculatorForm from './CalculatorForm';
import ReceiptDetails from './ReceiptDetails';

const FormTransactionDetails = ({
  isOpen,
  onClose,
  onClick,
  isReceipt = false,
}) => {
  const { t, locate } = useLang();
  const { isHHMTTheme } = useTheme();
  const { isDesktop } = useDetectDevice();

  const [isOpenInstructionPopup, setOpenInstructionPopup] = useState(false);

  // GET ORDER
  // {
  //   "orderRef": "E1D6NY2",
  //   "orderTotal": "1694.89",
  //   "orderStatus": "SUBMITTED",
  //   "createdDate": "31/07/2024",
  //   "createdTime": "14:04",
  //   "orderType": "BUY",
  //   "paymentStatus": "UNPAID",
  //   "shippingStatus": "",
  //   "collectMethod": "PICK UP",
  //   "pickupLocationCode": "",
  //   "preferredPickupDate": "15/08/2024",
  //   "deliveryAddress": {
  //       "fullAddress": "undefined, undefined undefined undefined",
  //       "street": null,
  //       "suburb": null,
  //       "state": null,
  //       "postCode": null,
  //       "countryCode": null,
  //       "businessName": "SYDNEY PARLIAMENT HOUSE LPO",
  //       "addressType": null,
  //       "direction": null
  //   },
  //   "preferredDeliveryDate": null,
  //   "customer": {
  //       "firstName": "THI HOANG ANH",
  //       "lastName": "NGUYEN",
  //       "dob": "17/06/1981",
  //       "email": "hau.nguyen@hhmt.com.au",
  //       "mobile": "0444444444",
  //       "gender": "FEMALE",
  //       "residentialAddress": {
  //           "fullAddress": "88 LINNINGS RD, 4306 HAIGSLEA QLD",
  //           "street": "88 LINNINGS RD",
  //           "suburb": "HAIGSLEA",
  //           "state": "QLD",
  //           "postCode": "4306",
  //           "countryCode": "AUS",
  //           "countryName": "AUSTRALIA",
  //           "countryId": "14"
  //       }
  //   },
  //   "subTotal": "1694.89",
  //   "deliveryFee": "10.0",
  //   "surcharge": "0",
  //   "notes": "",
  //   "paymentMethod": "DEPOSIT",
  //   "paymentAmount": "1694.89",
  //   "currencies": [
  //       {
  //           "amount": "1111",
  //           "code": "USD",
  //           "rate": "0.6555",
  //           "rateToken": "fa02d800fee13a0dc3cce84ee1956d41dc8dc7a0c9addb3287f243d0e2a0f0ca47fa4ff1576d683c0adb73be8a607853",
  //           "audAmount": "1694.89",
  //           "currencyName": "US DOLLAR",
  //           "countryCode": "USA"
  //       }
  //   ],
  //   "purpose": null,
  //   "occupation": null,
  //   "sourceOfWealth": null,
  //   "collectEntity": "AUSPOST",
  //   "sendingSatchelDate": "12/08/2024",
  //   "feeType": null,
  //   "previousOccupation": null,
  //   "orgBookingId": 73647,
  //   "orgBookingName": null,
  //   "createUserName": null,
  //   "discount": {
  //       "id": "VOL7N2ERZMP9RW4X",
  //       "code": "FXLAUNCH-UNDER2K",
  //       "type": "PROMOTION",
  //       "discountedFee": 10,
  //       "discountAmount": 10,
  //       "token": "38EF95BE1225C16C00765AA28B6396C3",
  //       "shortDescription": null,
  //       "description": null,
  //       "totalAmountAfterDiscount": 1694.89,
  //       "subtotalAfterDiscount": 1684.89,
  //       "expired": false
  //   },
  //   "epaymentId": null,
  //   "creditCardNo": null,
  //   "creditCardType": null,
  //   "dueDateTime": "08:04 PM, 31/07/2024",
  //   "statusDescription": {
  //       "VI": "Giao dịch đang chờ xác nhận.\nChúng tôi sẽ xử lý giao dịch của bạn sau khi nhận được thanh toán\nNếu bạn chưa thanh toán cho giao dịch này, vui lòng chuyển khoản trước 08:04 PM, 31/07/2024 để giao dịch không bị hủy, hoặc nhấn vào \"Other payments\" để sử dụng các phương thức thanh toán khác.",
  //       "EN": "Transaction is submitted successfully. \nWe will process once we receive your payment. \nIf you have not paid, please pay no later than 08:04 PM, 31/07/2024 via Bank Transfer or using <0>\"Other payments\"</0> to avoid cancellation."
  //   },
  //   "createdDateTime": "31/07/2024 14:04:00"
  // }

  const { transactionDetails } = useFxFormAddTransactionStore();
  const { isFxAsGuest, getPickupLocationName } = useFxAddTransaction();

  const {
    orderRef,
    orderStatus: status,
    customer,
    createdDate,
    statusDescription,
    paymentMethod: paymentMethodName,
    currencies: currencyOrders,
    deliveryAddress,
    preferredPickupDate: pickupDateSelected,
    notes: orderNote,
    creditCardNo,
    collectEntity,
  } = transactionDetails || {};
  const pickupLocationSelected = {
    ...deliveryAddress,
    name: deliveryAddress?.businessName,
    entity: collectEntity,
  };
  const pickupLocationType =
    collectEntity === 'AUSPOST'
      ? PICKUP_LOCATION_TYPE_2
      : PICKUP_LOCATION_TYPE_1;
  const paid = false;

  const isPaymentMethodByDeposit = paymentMethodName === METHOD_DEPOSIT;
  const isPaymentMethodByPayId = paymentMethodName === METHOD_PAYID;
  const isPaymentMethodByEPayment = paymentMethodName === METHOD_EPAYMENT;

  const renderHeaderTop = () => {
    if (isDesktop) return null;
    return (
      <HeaderTop>
        <SectionTitle>{t('label_transaction_summary')}</SectionTitle>
        <ButtonCommon
          color="var(--ds-c-blue)"
          background="var(--ds-c-white)"
          isFill={false}
          styles={{
            paddingInline: 0,
          }}
          buttonIconStyles={{
            height: '29px',
            marginLeft: '0px',
            gap: '0px',
            paddingInline: 0,
          }}
          iconSrc={CloseBlueIcon}
          onClick={onClose}
        />
      </HeaderTop>
    );
  };

  const renderRefStatusSection = () => {
    return (
      <RefStatusWrap>
        <RefText>
          <span>REF NO.</span> {`${orderRef}`}
        </RefText>
        <TransactionStatus
          transaction={{
            status,
          }}
        />
      </RefStatusWrap>
    );
  };

  const renderIntructionText = () => {
    return (
      <IntructionText
        dangerouslySetInnerHTML={{
          __html: stringUtils.replaceKeyword(
            stringUtils.stringTransform(
              statusDescription?.[locate?.toUpperCase()]
            ),
            [
              { key: '<0>', value: '<b>' },
              { key: '</0>', value: '</b>' },
            ],
            ''
          ),
        }}
      />
    );
  };

  const renderCustomer = () => {
    if (isEmpty(customer)) return null;
    const customerFullName = `${customer?.firstName} ${customer?.lastName}`;
    return (
      <FieldWrap
        styles={{
          alignItems: 'start',
          padding: '10px 0',
        }}
        style={{
          marginInline: '-16px',
          paddingInline: '16px',
          backgroundColor: 'var(--ds-c-bg-light-grey)',
          marginBottom: 0,
        }}
      >
        <FieldLabel>{t('label_customer_name')}</FieldLabel>
        <FieldValue>{customerFullName}</FieldValue>
      </FieldWrap>
    );
  };

  const renderCreatedDate = () => {
    if (isEmpty(createdDate)) return null;
    return (
      <FieldWrap
        styles={{
          alignItems: 'start',
          padding: '10px 0',
        }}
        style={{
          marginInline: '-16px',
          paddingInline: '16px',
          backgroundColor: 'var(--ds-c-bg-light-grey)',
          marginBottom: 0,
        }}
      >
        <FieldLabel>{t('label_created_date')}</FieldLabel>
        <FieldValue>{createdDate}</FieldValue>
      </FieldWrap>
    );
  };

  const handleOnToggleInstructionPopup = () => {
    setOpenInstructionPopup(!isOpenInstructionPopup);
  };
  const paymentMethodReference = {
    [STATUS_SUBMITTED]: () => {
      if (isPaymentMethodByEPayment) {
        return {
          text: '-',
          spanText: null,
          iconSrc: null,
        };
      }
      if (isPaymentMethodByDeposit) {
        return {
          text: t(
            METHOD_TRANSLATE_REFERECES[
              PAYMENT_METHOD_REFERENCE[METHOD_DEPOSIT].label
            ]
          ),
          spanText: null,
          iconSrc: null,
        };
      }
      if (isPaymentMethodByPayId) {
        return {
          text: t(PAYMENT_METHOD_REFERENCE[METHOD_PAYID].label),
          spanText: null,
          iconSrc: null,
        };
      }
      return {
        text: '-',
        spanText: null,
        iconSrc: null,
      };
    },
    [STATUS_PROCESSING]: () => {
      if (isPaymentMethodByDeposit) {
        return {
          text: t(
            METHOD_TRANSLATE_REFERECES[
              PAYMENT_METHOD_REFERENCE[METHOD_DEPOSIT].label
            ]
          ),
          spanText: null,
          iconSrc: null,
        };
      }
      if (isPaymentMethodByPayId) {
        return {
          text: t(PAYMENT_METHOD_REFERENCE[METHOD_PAYID].label),
          spanText: null,
          iconSrc: null,
        };
      }
      if (isPaymentMethodByEPayment) {
        return {
          text: `${t('label_visa_end_with')} ${
            creditCardNo?.split('*')?.slice(-1)[0]
          }`,
          spanText: null,
          iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_EPAYMENT].iconSrc,
        };
      }
      return {
        text: '-',
        spanText: null,
        iconSrc: null,
      };
    },
    [STATUS_DELIVERING]: () => {
      if (isPaymentMethodByDeposit) {
        return {
          text: t(
            METHOD_TRANSLATE_REFERECES[
              PAYMENT_METHOD_REFERENCE[METHOD_DEPOSIT].label
            ]
          ),
          spanText: null,
          iconSrc: null,
        };
      }
      if (isPaymentMethodByPayId) {
        return {
          text: t(PAYMENT_METHOD_REFERENCE[METHOD_PAYID].label),
          spanText: null,
          iconSrc: null,
        };
      }
      if (isPaymentMethodByEPayment) {
        return {
          text: `${t('label_visa_end_with')} ${
            creditCardNo?.split('*')?.slice(-1)[0]
          }`,
          spanText: null,
          iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_EPAYMENT].iconSrc,
        };
      }
      return {
        text: '-',
        spanText: null,
        iconSrc: null,
      };
    },
    [STATUS_COMPLETED]: () => {
      if (isPaymentMethodByDeposit) {
        return {
          text: t(
            METHOD_TRANSLATE_REFERECES[
              PAYMENT_METHOD_REFERENCE[METHOD_DEPOSIT].label
            ]
          ),
          spanText: null,
          iconSrc: null,
        };
      }
      if (isPaymentMethodByPayId) {
        return {
          text: t(PAYMENT_METHOD_REFERENCE[METHOD_PAYID].label),
          spanText: null,
          iconSrc: null,
        };
      }
      if (isPaymentMethodByEPayment) {
        return {
          text: `${t('label_visa_end_with')} ${
            creditCardNo?.split('*')?.slice(-1)[0]
          }`,
          spanText: null,
          iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_EPAYMENT].iconSrc,
        };
      }
      return {
        text: '-',
        spanText: null,
        iconSrc: null,
      };
    },
    [STATUS_CANCELLED]: () => {
      if (paid) {
        if (isPaymentMethodByDeposit) {
          return {
            text: t(
              METHOD_TRANSLATE_REFERECES[
                PAYMENT_METHOD_REFERENCE[METHOD_DEPOSIT].label
              ]
            ),
            spanText: null,
            iconSrc: null,
          };
        }
        if (isPaymentMethodByPayId) {
          return {
            text: t(PAYMENT_METHOD_REFERENCE[METHOD_PAYID].label),
            spanText: null,
            iconSrc: null,
          };
        }
        if (isPaymentMethodByEPayment) {
          return {
            text: `${t('label_visa_end_with')} ${
              creditCardNo?.split('*')?.slice(-1)[0]
            }`,
            spanText: null,
            iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_EPAYMENT].iconSrc,
          };
        }
      }
      return {
        text: '-',
        spanText: null,
        iconSrc: null,
      };
    },
    [STATUS_ON_HOLD]: () => {
      if (paid) {
        if (isPaymentMethodByDeposit) {
          return {
            text: t(
              METHOD_TRANSLATE_REFERECES[
                PAYMENT_METHOD_REFERENCE[METHOD_DEPOSIT].label
              ]
            ),
            spanText: null,
            iconSrc: null,
          };
        }
        if (isPaymentMethodByPayId) {
          return {
            text: t(PAYMENT_METHOD_REFERENCE[METHOD_PAYID].label),
            spanText: null,
            iconSrc: null,
          };
        }
        if (isPaymentMethodByEPayment) {
          return {
            text: `${t('label_visa_end_with')} ${
              creditCardNo?.split('*')?.slice(-1)[0]
            }`,
            spanText: null,
            iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_EPAYMENT].iconSrc,
          };
        }
      }
      return {
        text: '-',
        spanText: null,
        iconSrc: null,
      };
    },
  };
  const isShowIntroductionIcon = [METHOD_DEPOSIT, METHOD_PAYID].includes(
    paymentMethodName
  );
  const introductionIconReference = {
    [STATUS_SUBMITTED]: () => {
      if (isPaymentMethodByEPayment) {
        return false;
      }

      return true;
    },
    [STATUS_PROCESSING]: () => {
      if (isShowIntroductionIcon) {
        return true;
      }

      return false;
    },
    [STATUS_DELIVERING]: () => {
      if (isShowIntroductionIcon) {
        return true;
      }

      return false;
    },
    [STATUS_COMPLETED]: () => {
      if (isShowIntroductionIcon) {
        return true;
      }

      return false;
    },
    [STATUS_CANCELLED]: () => {
      if (!paid) {
        return false;
      }

      return true;
    },
    [STATUS_ON_HOLD]: () => {
      if (!paid) {
        return false;
      }

      return true;
    },
  };
  const renderPaymentValue = () => (
    <>
      {paymentMethodReference[status]?.()?.iconSrc ? (
        <>
          <img src={paymentMethodReference[status]?.()?.iconSrc} alt="" />{' '}
          {introductionIconReference[status]?.() && (
            <img
              src={isHHMTTheme ? IntroductionHHMTIcon : IntroductionRemoxIcon}
              alt=""
              onClick={handleOnToggleInstructionPopup}
              style={{
                marginLeft: '8px',
                cursor: 'pointer',
                paddingBottom: '0px',
              }}
            />
          )}
          {paymentMethodReference[status]?.()?.text && (
            <span className="credit-card">
              {paymentMethodReference[status]?.()?.text}
            </span>
          )}
        </>
      ) : (
        <>
          {paymentMethodReference[status]?.()?.text}
          {introductionIconReference[status]?.() && (
            <img
              src={isHHMTTheme ? IntroductionHHMTIcon : IntroductionRemoxIcon}
              alt=""
              onClick={handleOnToggleInstructionPopup}
              style={{
                marginLeft: '8px',
                cursor: 'pointer',
                paddingBottom: '4px',
              }}
            />
          )}
          {paymentMethodReference[status]?.()?.spanText && (
            <>
              <br />
              <span>{paymentMethodReference[status]?.().spanText}</span>
            </>
          )}
        </>
      )}
    </>
  );
  const renderPaymentMethod = () => {
    if (isEmpty(paymentMethodName)) return null;
    return (
      <FieldWrap
        styles={{
          alignItems: 'start',
          padding: '10px 0',
        }}
        style={{
          marginInline: '-16px',
          paddingInline: '16px',
          backgroundColor: 'var(--ds-c-bg-light-grey)',
          marginBottom: 0,
        }}
      >
        <FieldLabel>{t('label_payment_method')}</FieldLabel>
        <FieldValue>{renderPaymentValue()}</FieldValue>
      </FieldWrap>
    );
  };

  const renderCurrencyItem = (order) => {
    if (isEmpty(order)) return null;

    const {
      code: currencyCode,
      amount: buyAmount,
      audAmount: payAmount,
      rate: rateAmount,
    } = order || {};
    return (
      <CurrencyItemWrap>
        <GridItem>
          <FlagIcon
            style={{ position: 'unset' }}
            src={CURRENCY_FLAG_REFERENCES[currencyCode]}
          />
        </GridItem>
        <GridItem>
          <CurrencyCodeBuyAmount>
            <CurrencyChip>{currencyCode}</CurrencyChip>
            <b>{format.toAmountCentStr(buyAmount)}</b>
          </CurrencyCodeBuyAmount>
          <CurrencyNameText>
            {CURRENCY_NAME_REFERENCE[currencyCode]}
          </CurrencyNameText>
        </GridItem>
        <GridItem>
          <PayAmountText>
            {`AUD ${format.toAmountCentStr(payAmount)}`}
          </PayAmountText>
          <RateText>{`(1 AUD = ${format.toAmountCentStr(
            rateAmount
          )})`}</RateText>
        </GridItem>
      </CurrencyItemWrap>
    );
  };
  const renderCurrencyOrders = () => {
    if (isEmpty(currencyOrders)) return null;

    return (
      <>
        <FieldWrap>
          <FieldLabel>{t('fx_txn_details_order')}</FieldLabel>
          <FieldValue></FieldValue>
        </FieldWrap>
        <CurrencyOrdersWrap>
          {currencyOrders?.map((order, index) => (
            <Fragment key={index}>{renderCurrencyItem(order)}</Fragment>
          ))}
        </CurrencyOrdersWrap>
      </>
    );
  };

  const renderPickupLocation = () => {
    if (isEmpty(pickupLocationSelected)) return null;
    return (
      <FieldWrap
        styles={{
          alignItems: 'start',
          padding: '10px 0',
        }}
        style={{
          marginInline: '-16px',
          paddingInline: '16px',
          backgroundColor: 'var(--ds-c-bg-light-grey)',
          marginBottom: 0,
        }}
      >
        <FieldLabel>{t('fx_add_transaction_pickup_location')}</FieldLabel>
        <FieldValue>
          {
            getPickupLocationName(
              pickupLocationSelected,
              pickupLocationType
            ).split('-')[0]
          }
          {getPickupLocationName(
            pickupLocationSelected,
            pickupLocationType
          ).split('-')[1] && (
            <>
              {` - `}
              <br />
              {
                getPickupLocationName(
                  pickupLocationSelected,
                  pickupLocationType
                ).split('-')[1]
              }
            </>
          )}
        </FieldValue>
      </FieldWrap>
    );
  };

  const renderPickupDate = () => {
    if (isEmpty(pickupDateSelected)) return null;
    return (
      <FieldWrap
        styles={{
          alignItems: 'start',
          padding: '10px 0',
        }}
        style={{
          marginInline: '-16px',
          paddingInline: '16px',
          backgroundColor: 'var(--ds-c-bg-light-grey)',
          marginBottom: 0,
        }}
      >
        <FieldLabel>{t('fx_add_transaction_pickup_date_details')}</FieldLabel>
        <FieldValue>{pickupDateSelected}</FieldValue>
      </FieldWrap>
    );
  };

  const renderOrderNote = () => {
    if (isEmpty(orderNote)) return null;
    return (
      <>
        <FieldWrap
          styles={{
            alignItems: 'start',
            padding: '10px 0',
          }}
          style={{
            marginBottom: 0,
          }}
        >
          <FieldLabel style={{ minWidth: '100%' }}>
            {t('fx_txn_details_order_note')}
          </FieldLabel>
          <FieldValue></FieldValue>
        </FieldWrap>
        <FieldValue style={{ textAlign: 'left', padding: 0 }}>
          {orderNote}
        </FieldValue>
      </>
    );
  };

  const renderActionsButton = () => {
    if (isFxAsGuest) {
      return (
        <>
          <ButtonCommon
            value={t('button_close')}
            onClick={() => {}}
            styles={{
              margin: '0px',
              width: '50%',
              opacity: 0,
              cursor: 'default',
              display: !isDesktop && 'none',
            }}
            isDisabled={isEmpty(onClose)}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
          <ButtonCommon
            value={t('button_back')}
            onClick={onClick}
            styles={{
              margin: '0px',
              width: !isDesktop ? '100%' : '50%',
            }}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
          />
        </>
      );
    }

    if (status === STATUS_SUBMITTED) {
      if (!isDesktop)
        return (
          <ButtonCommon
            value={t('button_other_payments')}
            onClick={onClick}
            styles={{
              margin: '0px',
              width: '100%',
            }}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
          />
        );
      if (isDesktop)
        return (
          <>
            <ButtonCommon
              value={t('button_close')}
              onClick={onClose}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
            />
            <ButtonCommon
              value={t('button_other_payments')}
              onClick={onClick}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              color="var(--c-primary)"
              background="var(--bg-primary)"
              isFill={true}
            />
          </>
        );
    }

    return (
      <ButtonCommon
        value={t('button_close')}
        onClick={onClose}
        styles={{
          margin: '0px',
          width: '50%',
          marginInline: 'auto',
        }}
        color="var(--c-primary)"
        background="var(--bg-primary)"
      />
    );
  };

  const renderCalculatorFormDesktop = () => {
    if (!isDesktop) return null;
    if (isEmpty(currencyOrders)) return null;
    return (
      <CalculatorForm
        isCalculatorFormAccordion={true}
        isCalculatorFormAccordionExpandable={true}
        isExpandDefault={true}
      />
    );
  };
  const renderCalculatorFormMobile = () => {
    if (isDesktop) return null;
    if (isEmpty(currencyOrders)) return null;
    return (
      <ModalCalculatorForm>
        <CalculatorForm
          isCalculatorFormAccordion={true}
          isCalculatorFormAccordionExpandable={true}
          isExpandDefault={true}
        />
      </ModalCalculatorForm>
    );
  };

  if (isReceipt)
    return (
      <>
        {isOpenInstructionPopup && (
          <>
            {isDesktop ? (
              <InstructionPopup
                isOpen={isOpenInstructionPopup}
                onClose={handleOnToggleInstructionPopup}
                method={paymentMethodName}
              />
            ) : (
              <InstructionPopupMobile
                isOpen={isOpenInstructionPopup}
                onClose={handleOnToggleInstructionPopup}
                method={paymentMethodName}
              />
            )}
          </>
        )}
        <ReceiptDetails
          header={
            <ModalHeader $isReceipt={isReceipt}>
              {renderRefStatusSection()}
              {renderIntructionText()}
            </ModalHeader>
          }
          body={
            <ModalBody $isReceipt={isReceipt}>
              {renderCustomer()}
              {renderCreatedDate()}
              {renderPaymentMethod()}
              {renderCurrencyOrders()}
              {renderPickupLocation()}
              {renderPickupDate()}
              {renderOrderNote()}
            </ModalBody>
          }
          calculator={<CalculatorForm isCalculatorFormAccordion={true} />}
          footer={
            <ModalFooter $isReceipt={isReceipt}>
              {renderActionsButton()}
            </ModalFooter>
          }
        />
      </>
    );
  return (
    <>
      {isOpenInstructionPopup && (
        <>
          {isDesktop ? (
            <InstructionPopup
              isOpen={isOpenInstructionPopup}
              onClose={handleOnToggleInstructionPopup}
              method={paymentMethodName}
            />
          ) : (
            <InstructionPopupMobile
              isOpen={isOpenInstructionPopup}
              onClose={handleOnToggleInstructionPopup}
              method={paymentMethodName}
            />
          )}
        </>
      )}
      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader>
          {renderHeaderTop()}
          {renderRefStatusSection()}
          {renderIntructionText()}
        </ModalHeader>
        <ModalBody>
          {renderCustomer()}
          {renderCreatedDate()}
          {renderPaymentMethod()}
          {renderCurrencyOrders()}
          {renderPickupLocation()}
          {renderPickupDate()}
          {renderOrderNote()}
          {renderCalculatorFormDesktop()}
        </ModalBody>
        {renderCalculatorFormMobile()}
        <ModalFooter>{renderActionsButton()}</ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    & .modal-dialog {
      position: absolute;
      margin: 0;
      top: 0px;
      height: 100vh;
      width: 100%;
      max-width: 100%;
      align-items: end;
      transition: ease-out 0.3s !important;
      transform: none;

      & .modal-content {
        padding: 0px;
        border-radius: 0px;
        box-shadow: none;
        background: var(--ds-c-white);
        width: 100%;
        height: calc(100vh - 70px);
        margin-inline: auto;
        border: none;
      }
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & .modal-dialog {
      transform: none;
    }
    & .modal-content {
      padding: 16px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 470px;
      height: fit-content;
      margin-inline: auto;
    }
  }
`;

const ModalHeader = styled(Modal.Header)`
  padding: 0;
  margin-inline: ${(props) => (props.$isReceipt ? '0px' : '16px')};
  margin-bottom: 0;
  display: block;
  padding-bottom: 16px;
  border-bottom: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-inline: 0px;
  }
`;

const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  padding-inline: 16px;
  margin-inline: -16px;
  margin-bottom: 16px;
  height: 50px;
  width: calc(100% + 32px);
  border-bottom: 1px solid var(--ds-c-blue-remox-light);
`;

const SectionTitle = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--ds-c-blue-disabled);

  margin: 0;
  padding: 0;
`;
const RefStatusWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;
const RefText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--ds-c-blue-hyperlink-default);
  text-align: left;

  margin: 0;
  padding: 0;

  & span {
    font-family: var(--ff-primary);
    font-weight: 600;
    color: var(--ds-c-black);
  }
`;
const IntructionText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-black);
  white-space: break-spaces;

  margin: 0;
  padding: 0;

  & span {
    color: var(--ds-c-green-default);
  }
`;

const ModalBody = styled(Modal.Body)`
  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    padding: 0;
    padding-inline: ${(props) => (props.$isReceipt ? '0px' : '16px')};
    padding-bottom: 152px;
  }
  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding: 0;
    margin-bottom: 16px;
  }
`;
const FieldWrap = styled.div`
  display: flex;
  align-items: ${(props) => props.styles?.alignItems || 'center'};
  justify-content: start;
  height: fit-content;
  padding: ${(props) => props.styles?.padding};
  margin-bottom: 4px;
`;
const FieldLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue);

  margin: 0;
  padding: ${(props) => props.styles?.padding || '0'};
  min-width: 150px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: right;
  color: var(--ds-c-black);

  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 8px;

  & span {
    color: var(--ds-c-grey-neutral);
  }

  & span.rate {
    color: var(--ds-c-blue);
  }

  & span.credit-card {
    text-transform: initial;
    color: var(--ds-c-grey-dark);
  }

  & span.payment-method-txt {
    color: var(--ff-primary);
    line-height: 25px;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const CurrencyOrdersWrap = styled.div`
  width: 100%;
  height: fit-content;
`;
const CurrencyItemWrap = styled.div`
  width: 100%;
  min-height: 60px;
  height: 60px;
  border-bottom: 1px solid var(--ds-bg-2);

  display: grid;
  grid-template-columns: 30px calc(60% - 30px) calc(40% - 30px);
  grid-gap: 12px;
`;
const GridItem = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  padding-block: 8px;

  &:nth-child(1) {
    justify-content: start;
    align-items: center;
  }
  &:nth-child(2) {
  }
  &:last-child {
    justify-content: end;
    align-items: start;
  }
`;
const CurrencyNameText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--ds-c-grey-dark);
  text-align: left;

  margin: 0;
  padding: 0;
`;
const CurrencyCodeBuyAmount = styled.div`
  ${paragraphMixin};
  font-weight: 500;

  display: flex;
  gap: 4px;
`;
const PayAmountText = styled.p`
  ${paragraphMixin};
  text-align: right;
`;
const RateText = styled.p`
  ${paragraphMixin};
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  font-weight: 500;
`;

const ModalCalculatorForm = styled.div`
  position: fixed;
  bottom: 77px;
  left: 0px;
  width: 100%;
  padding: 0;
  display: flex;
`;

const ModalFooter = styled(Modal.Footer)`
  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    ${(props) =>
      props.$isReceipt
        ? `
        border: none;
        padding: 0;
        display: flex;
        justify-content: space-between;
      `
        : `
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #eef2f5;
        background: var(--ds-c-white);
        border-radius: 0px;
        border-top: 1px solid var(--ds-bg-2);
      `}
  }
  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    border: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
`;

export const CurrencyChip = styled.span`
  width: fit-content;
  height: 20px;
  padding-inline: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ds-c-blue-dark);
  border-radius: 100px;

  font-family: var(--ff-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: #fff;
`;
const FlagIcon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border: 1px solid var(--ds-bg-2);
  border-radius: 50%;
`;

export default FormTransactionDetails;
