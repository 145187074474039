import { api } from 'api';
import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import AdditionalInformation from 'components/FormAddTransaction/components/AdditionalInformation/ver2';
import CancelAddTransactionPopup from 'components/FormAddTransaction/components/CancelAddTransactionPopup';
import PaymentMethod from 'components/FormAddTransaction/components/PaymentMethod/ver2';
import ReceiverPayoutMethod from 'components/FormAddTransaction/components/ReceiverPayoutMethod/ver2';
import Remittance from 'components/FormAddTransaction/components/Remittance/ver2';
import { fetchReceiverRecordInformation } from 'components/FormAddTransaction/func';
import { fetchReceiverInformation } from 'components/Receivers/func';
import { getLookupFeeAmountLatest } from 'components/Transactions/func';
import CollapseSidebarProcessStepsLayout from 'components/common/CollapseSidebarProcessStepsLayout';
import InformPopup from 'components/common/InformPopup';
import {
  ADD_TRANSACTION_MESSAGE_NAME,
  ADD_TRANSACTION_METHOD_BANK_NAME,
  ADD_TRANSACTION_METHOD_EWALLET_NAME,
  ADD_TRANSACTION_METHOD_PICKUP_NAME,
  ADD_TRANSACTION_OCCUPATION_NAME,
  ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME,
  ADD_TRANSACTION_PURPOSE_NAME,
  ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME,
  ADD_TRANSACTION_RECEIVER_GET_NAME,
  ADD_TRANSACTION_RECEIVER_NAME,
  ADD_TRANSACTION_RELATIONSHIP_NAME,
  ADD_TRANSACTION_SOURCE_NAME,
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  DESKTOP_MIN_WIDTH,
  METHOD_BANK,
  METHOD_EWALLET,
  METHOD_HOME,
  METHOD_PICKUP,
  PAYMENT_CREADIT_CARD_NO,
  PAYMENT_CREADIT_CARD_TYPE,
  RESET_ADD_TRANSACTION_INFORMATION_STORE,
  STATUS_CANCELLED,
  STATUS_FAIL,
  STATUS_SUCCESS,
  TRANSACTIONS_PREFIX,
} from 'constants';
import Cookies from 'helpers/cookies';
import useAddTransaction, {
  FX_ADD_TRANSACTION_TOTAL_NUMBER_OF_STEPS,
  MAKE_PAYMENT_KEY,
} from 'hooks/form-add-transaction/useAddTransaction';
import useSmartLook from 'hooks/smartlook/useSmartLook';
import useAuth from 'hooks/useAuth';
import useCountryConfig from 'hooks/useCountryConfig';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  SET_ADD_TRANSACTION_CURRENT_STEP,
  SET_ADD_TRANSACTION_INFORMATION,
  SET_CURRENT_RECEIVER,
  SET_SHOW_PENDING,
  SET_TOKEN_TRANSACTION,
} from 'store/action';
import styled from 'styled-components';
import { numberUtils, objectUtils } from 'utils';

const AddTransactionPage = () => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const {
    currentReceiver,
    addTransactionInformation,
    tokenTransaction,
    addTransactionCurrentStep: currentStep,
  } = state;
  const { remittance } = addTransactionInformation || {};
  const { isAddAnotherReceivingCurrency } = remittance || {};
  const navigate = useNavigate();
  const { token } = useAuth();
  const countryConfig = useCountryConfig();
  const { getNavbarsAndBodyContentOfSidebar } = useAddTransaction();

  const [isOpenCancelPopup, setOpenCancelPopup] = useState(false);
  const [isOpenInformPopup, setOpenInformPopup] = useState(false);
  const [isOpenBackPopup, setOpenBackPopup] = useState(false);

  const query = useQuery();
  const rid = query.get('rid');
  const refNumber = query.get('refNumber');
  const paymentStatus = query.get('paymentStatus');
  const isMakePayment = Cookies.get(MAKE_PAYMENT_KEY) || refNumber;
  const isDisabled =
    paymentStatus === STATUS_FAIL ||
    paymentStatus === STATUS_CANCELLED ||
    isMakePayment;

  useSmartLook({ isInit: true });

  const paymentMintProcess = async (token, refNumber, tokenTransaction) => {
    const homeUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    const successPath = `${homeUrl}/tran?refNumber=${refNumber}&&paymentStatus=${STATUS_SUCCESS}`;
    const failPath = `${homeUrl}/tran?refNumber=${refNumber}&&paymentStatus=${STATUS_FAIL}`;

    try {
      const { data } = await api.processMint(
        token,
        refNumber,
        tokenTransaction
      );

      if (data) {
        const { success } = data;

        dispatch({ type: SET_TOKEN_TRANSACTION, payload: '' });

        dispatch({ type: SET_SHOW_PENDING, payload: false });

        if (success) {
          window.location.href = successPath;
        } else {
          window.location.href = failPath;
        }
      }
    } catch (error) {
      console.error(error?.message);

      dispatch({ type: SET_TOKEN_TRANSACTION, payload: '' });

      dispatch({ type: SET_SHOW_PENDING, payload: false });

      window.location.href = failPath;
    }
  };

  const getTransactionInformationInitial = async (
    token,
    currentReceiver,
    refNumber
  ) => {
    let informationStore = { ...addTransactionInformation };

    try {
      if (!currentReceiver) {
        const { data: dataOccupation } = await api.getOccupation(token);

        const { data } = await api.getTran(token, refNumber);

        if (data && dataOccupation) {
          const {
            payout,
            fee,
            remittance,
            relationship,
            purpose,
            source,
            message,
            paymentMethod,
            creaditCardNo,
            creaditCardType,
            discount,
            discountDetails,
          } = data;

          await fetchReceiverRecordInformation(token, refNumber);

          informationStore = {
            ...informationStore,
            receiverPayoutMethod: {
              [ADD_TRANSACTION_PAYOUT_METHOD_NAME]: payout?.dmCode,
            },
            remittance: {
              [ADD_TRANSACTION_YOU_SEND_NAME]: Number(
                remittance[0].sendAmount || 0
              ),
              [ADD_TRANSACTION_RECEIVER_GET_NAME]: Number(
                remittance[0].receiveAmount || 0
              ),
              [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: Number(
                remittance[1]?.sendAmount || 0
              ),
              [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: Number(
                remittance[1]?.receiveAmount || 0
              ),
              isAddAnotherReceivingCurrency: remittance.length === 2,
              currentCurrencySelected: {
                countryCode: remittance[0]?.currency?.countryCode,
                dmCode: payout?.dmCode,
                currencyCode: remittance[0]?.currency?.code,
              },
              rate: remittance[0]?.rate,
              currentRateStore: remittance[0]?.rate,
              remittanceId: remittance[0]?.id,
              currentCurrencySelectedAnother: remittance[1]?.currency
                ? {
                    countryCode: remittance[1]?.currency?.countryCode,
                    dmCode: payout?.dmCode,
                    currencyCode: remittance[1]?.currency?.code,
                  }
                : null,
              rateAnother: remittance[1] ? remittance[1]?.rate : null,
              currentRateAnotherStore: remittance[1]
                ? remittance[1]?.rate
                : null,
              remittanceAnotherId: remittance[1]?.id,
              feeAmount: fee || null,
            },
            additionalInformation: {
              [ADD_TRANSACTION_RELATIONSHIP_NAME]:
                objectUtils.convertToKeyValue(relationship),
              [ADD_TRANSACTION_OCCUPATION_NAME]: objectUtils.convertToKeyValue(
                dataOccupation.job
              ),
              [ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]:
                objectUtils.convertToKeyValue(dataOccupation?.previousJob),
              [ADD_TRANSACTION_PURPOSE_NAME]:
                objectUtils.convertToKeyValue(purpose),
              [ADD_TRANSACTION_SOURCE_NAME]:
                objectUtils.convertToKeyValue(source),
              [ADD_TRANSACTION_MESSAGE_NAME]: message,
            },
            paymentMethod: {
              [ADD_TRANSACTION_PAYMENT_METHOD_NAME]: paymentMethod,
              [PAYMENT_CREADIT_CARD_NO]: creaditCardNo?.slice(-4),
              [PAYMENT_CREADIT_CARD_TYPE]: creaditCardType,
              paymentMethodTxn: paymentMethod,
              discountsAvailable: [],
              discountApplied: discount || null,
              discountDetails: discount ? discountDetails : null,
            },
          };

          if (payout?.dmCode === METHOD_PICKUP) {
            informationStore = {
              ...informationStore,
              receiverPayoutMethod: {
                [ADD_TRANSACTION_PAYOUT_METHOD_NAME]: payout?.dmCode,
                [ADD_TRANSACTION_METHOD_PICKUP_NAME]: payout?.pickupOffice,
              },
            };
          }

          if (payout?.dmCode === METHOD_BANK) {
            informationStore = {
              ...informationStore,
              receiverPayoutMethod: {
                [ADD_TRANSACTION_PAYOUT_METHOD_NAME]: payout?.dmCode,
                [ADD_TRANSACTION_METHOD_BANK_NAME]: payout?.bank,
              },
            };
          }

          if (payout?.dmCode === METHOD_EWALLET) {
            informationStore = {
              ...informationStore,
              receiverPayoutMethod: {
                [ADD_TRANSACTION_PAYOUT_METHOD_NAME]: payout?.dmCode,
                [ADD_TRANSACTION_METHOD_EWALLET_NAME]: payout?.ewallet,
              },
            };
          }
        }
      } else {
        const dmCode =
          informationStore.receiverPayoutMethod[
            ADD_TRANSACTION_PAYOUT_METHOD_NAME
          ];

        const sendAmount = numberUtils.mathRound(
          informationStore.remittance[ADD_TRANSACTION_YOU_SEND_NAME] || 0
        );
        const sendAnotherAmount = numberUtils.mathRound(
          informationStore.remittance[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] ||
            0
        );
        const sendTotalAmount = numberUtils.mathRound(
          sendAmount + sendAnotherAmount
        );

        if (dmCode === METHOD_HOME) {
          informationStore = {
            ...informationStore,
            receiverPayoutMethod: {
              ...informationStore.receiverPayoutMethod,
              [ADD_TRANSACTION_RECEIVER_NAME]: currentReceiver,
              currentPayoutItemSelectedIndex: 0,
            },
          };
        }

        if (dmCode === METHOD_PICKUP) {
          const itemId =
            informationStore.receiverPayoutMethod[
              ADD_TRANSACTION_METHOD_PICKUP_NAME
            ]?.pickUpOfficeId;

          informationStore = {
            ...informationStore,
            receiverPayoutMethod: {
              ...informationStore.receiverPayoutMethod,
              [ADD_TRANSACTION_RECEIVER_NAME]: currentReceiver,
              currentPayoutItemSelectedIndex: itemId,
            },
          };
        }

        if (dmCode === METHOD_BANK) {
          const itemId =
            informationStore.receiverPayoutMethod[
              ADD_TRANSACTION_METHOD_BANK_NAME
            ]?.id;

          informationStore = {
            ...informationStore,
            receiverPayoutMethod: {
              ...informationStore.receiverPayoutMethod,
              [ADD_TRANSACTION_RECEIVER_NAME]: currentReceiver,
              currentPayoutItemSelectedIndex: itemId,
            },
          };
        }

        if (dmCode === METHOD_EWALLET) {
          const itemId =
            informationStore.receiverPayoutMethod[
              ADD_TRANSACTION_METHOD_EWALLET_NAME
            ]?.id;

          informationStore = {
            ...informationStore,
            receiverPayoutMethod: {
              ...informationStore.receiverPayoutMethod,
              [ADD_TRANSACTION_RECEIVER_NAME]: currentReceiver,
              currentPayoutItemSelectedIndex: itemId,
            },
          };
        }

        if (!isDisabled && parseInt(sendTotalAmount)) {
          informationStore = {
            ...informationStore,
            remittance: await getLookupFeeAmountLatest({
              sendAmount: sendTotalAmount,
              dmCode,
              isOnlyCurrency: !isAddAnotherReceivingCurrency,
              information: { ...informationStore.remittance },
              countryCode: currentReceiver?.country?.code,
            }),
          };
        }
      }

      dispatch({
        type: SET_ADD_TRANSACTION_INFORMATION,
        payload: informationStore,
      });
    } catch (error) {
      console.error(error?.message);

      handleOnClickCancelPopup();
    }
  };

  useEffect(() => {
    if (!currentReceiver && rid) {
      fetchReceiverInformation(token, rid);
    }
  }, [currentReceiver, query, token]);

  useEffect(() => {
    if (refNumber) {
      getTransactionInformationInitial(token, currentReceiver, refNumber);
    }

    let currentStep = 0;

    if (isMakePayment) {
      currentStep = 3;

      if (paymentStatus === STATUS_CANCELLED) {
        currentStep = 3;

        setOpenInformPopup(true);

        dispatch({
          type: SET_ADD_TRANSACTION_CURRENT_STEP,
          payload: currentStep,
        });

        return;
      }

      if (paymentStatus === STATUS_FAIL) {
        currentStep = 3;

        setOpenInformPopup(true);

        dispatch({
          type: SET_ADD_TRANSACTION_CURRENT_STEP,
          payload: currentStep,
        });
      }

      if (paymentStatus === STATUS_SUCCESS) {
        Cookies.remove(MAKE_PAYMENT_KEY);

        currentStep = 4;

        dispatch({
          type: SET_ADD_TRANSACTION_CURRENT_STEP,
          payload: currentStep,
        });

        return;
      }

      if (!refNumber && !currentReceiver) {
        Cookies.remove(MAKE_PAYMENT_KEY);

        window.location.reload();
      }

      dispatch({
        type: SET_ADD_TRANSACTION_CURRENT_STEP,
        payload: currentStep,
      });

      return;
    }

    if (paymentStatus === STATUS_CANCELLED) {
      currentStep = 3;

      setOpenInformPopup(true);

      dispatch({
        type: SET_ADD_TRANSACTION_CURRENT_STEP,
        payload: currentStep,
      });

      return;
    }

    if (paymentStatus === STATUS_FAIL) {
      currentStep = 3;

      setOpenInformPopup(true);

      dispatch({
        type: SET_ADD_TRANSACTION_CURRENT_STEP,
        payload: currentStep,
      });
    }

    if (paymentStatus === STATUS_SUCCESS) {
      Cookies.remove(MAKE_PAYMENT_KEY);

      currentStep = 4;

      dispatch({
        type: SET_ADD_TRANSACTION_CURRENT_STEP,
        payload: currentStep,
      });

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReceiver, query, token, isMakePayment]);

  useEffect(() => {
    if (tokenTransaction && refNumber) {
      dispatch({ type: SET_SHOW_PENDING, payload: true });

      paymentMintProcess(token, refNumber, tokenTransaction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenTransaction, query]);

  useEffect(() => {
    return () => {
      dispatch({
        type: SET_ADD_TRANSACTION_INFORMATION,
        payload: RESET_ADD_TRANSACTION_INFORMATION_STORE,
      });
      dispatch({
        type: SET_CURRENT_RECEIVER,
        payload: null,
      });
      dispatch({
        type: SET_ADD_TRANSACTION_CURRENT_STEP,
        payload: 0,
      });
      Cookies.remove(MAKE_PAYMENT_KEY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnToggleCancelPopup = () => {
    if (isMakePayment) {
      handleOnToggleBackPopup();
      return;
    }

    setOpenCancelPopup(!isOpenCancelPopup);
  };

  const handleOnClickCancelPopup = () => {
    setOpenCancelPopup(false);

    Cookies.remove(MAKE_PAYMENT_KEY);

    dispatch({
      type: SET_ADD_TRANSACTION_INFORMATION,
      payload: RESET_ADD_TRANSACTION_INFORMATION_STORE,
    });

    dispatch({ type: SET_CURRENT_RECEIVER, payload: null });

    dispatch({ type: SET_ADD_TRANSACTION_CURRENT_STEP, payload: 0 });

    navigate(`${TRANSACTIONS_PREFIX}`);
  };

  const handleOnToggleBackPopup = () => {
    setOpenBackPopup(!isOpenBackPopup);
  };

  const stepContentReference = {
    0: (
      <ReceiverPayoutMethod
        countryConfig={countryConfig}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        isDisabled={isDisabled}
        rid={rid}
      />
    ),
    1: (
      <Remittance
        countryConfig={countryConfig}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        isDisabled={isDisabled}
      />
    ),
    2: (
      <AdditionalInformation
        countryConfig={countryConfig}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        isDisabled={isDisabled}
      />
    ),
    3: (
      <PaymentMethod
        countryConfig={countryConfig}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        isDisabled={isDisabled}
      />
    ),
    4: (
      <PaymentMethod
        countryConfig={countryConfig}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        isDisabled={isDisabled}
      />
    ),
  };

  return (
    <>
      {isOpenCancelPopup && (
        <CancelAddTransactionPopup
          isOpen={isOpenCancelPopup}
          onClose={handleOnToggleCancelPopup}
          onClick={handleOnClickCancelPopup}
        />
      )}
      {isOpenInformPopup && (
        <InformPopup
          isOpen={isOpenInformPopup}
          imageSrc={NoticeIcon}
          imagesStyles={{
            width: '124px',
            height: '100px',
            marginBottom: '16px',
          }}
          text={t('popup_payment_fail')}
          textStyle={{
            color: 'var(--ds-c-grey-dark)',
          }}
          content={t('popup_payment_fail_desc')}
          buttonLabel1={null}
          buttonLabel2={t('button_ok')}
          onClose={() => setOpenInformPopup(false)}
          onClick={() => setOpenInformPopup(false)}
          isBackdropOverlap={false}
        />
      )}
      {isOpenBackPopup && (
        <InformPopup
          isOpen={isOpenBackPopup}
          content={t('popup_confirm_back_transaction')}
          buttonLabel1={t('button_no')}
          buttonLabel2={t('button_yes')}
          onClose={handleOnToggleBackPopup}
          onClick={handleOnClickCancelPopup}
          isBackdropOverlap={false}
        />
      )}
      {currentStep <= FX_ADD_TRANSACTION_TOTAL_NUMBER_OF_STEPS && (
        <AddTransactionStyled>
          <CollapseSidebarProcessStepsLayout
            titleSidebar={t('add_transaction_send_money_label')}
            navbars={getNavbarsAndBodyContentOfSidebar()}
            currentStep={currentStep}
            stepContentReference={stepContentReference}
            isPreview={isDisabled}
            isSuccess={currentStep === 4}
          />
        </AddTransactionStyled>
      )}
    </>
  );
};

const AddTransactionStyled = styled.div`
  margin: 70px 0px 0px 0px;
  padding: 16px;
  height: calc(100vh - 70px);
  width: 100%;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin: unset;
    margin-top: 0px;
    padding: unset;
    padding-top: 96px;
    height: fit-content;
    min-height: 100vh;
  }
`;

export default AddTransactionPage;
