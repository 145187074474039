import {
  TRANSACTION_STATUS_CLASS_REFERENCE,
  TRANSACTION_STATUS_TRANSLATE_REFERENCE,
} from 'constants';
import useLang from 'hooks/useLang';
import Badge from 'react-bootstrap/Badge';
import styled from 'styled-components';

const TransactionStatus = ({ transaction }) => {
  const { t } = useLang();

  return (
    <BadgeStyled
      className={TRANSACTION_STATUS_CLASS_REFERENCE[transaction.status]}
      pill
    >
      {t(TRANSACTION_STATUS_TRANSLATE_REFERENCE[transaction.status])}
    </BadgeStyled>
  );
};

const BadgeStyled = styled(Badge)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  text-align: center;
  white-space: break-spaces;

  min-width: 116px;
  max-width: 116px;
  height: 22px;
  border-radius: 20px !important;
  padding-inline: 0px;
  padding-block: 4px;

  &.submitted {
    color: #ffffff;
    background-color: var(--ds-c-red-alert) !important;
  }

  &.delivering {
    color: #ffffff;
    background-color: var(--ds-c-yellow) !important;
  }

  &.processing {
    color: #ffffff;
    background-color: var(--ds-c-blue-hyperlink-default) !important;
  }

  &.completed {
    color: #ffffff;
    background-color: var(--ds-c-green-default) !important;
  }

  &.cancelled {
    color: #ffffff;
    background-color: var(--ds-c-grey-neutral) !important;
  }

  &.on-hold {
    color: #ffffff;
    background-color: var(--ds-c-blue) !important;
  }
`;

export default TransactionStatus;
