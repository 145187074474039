import SearchIcon from 'assets/icons/search-icon.svg';
import {
  fetchReceiversFiltered,
  getBodyPayloadFiltered,
} from 'components/Receivers/func';
import {
  BUSINESS_OF_RECEIVER_TYPE,
  DEBOUNCE_SEC,
  DESKTOP_MIN_WIDTH,
  ENTER_KEY_DOWN,
  PAGE_DEFAULT,
  RECEIVER_TABLE_SORT_ORDER_INITIAL,
} from 'constants';
import useAuth from 'hooks/useAuth';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { arrayUtils } from 'utils';

const RECEIVER_SEARCH_INPUT_FIELD = 'receiver-search-input-field';

const SearchReceiver = ({
  applyFiltered,
  receivers,
  setReceivers,
  searchValue,
  setSearchValue,
  setTotalPages,
  setCurrentPage,
  setSortOrder,
  setTotalElements,
  setFetching = () => {},
  currentTab,
}) => {
  const { t } = useLang();
  const { token } = useAuth();

  const { debounce } = useDebounceCallback((value) => {
    if (value) {
      value = value.trim();

      let values = value.split(' ');

      if (!arrayUtils.isArrayEmpty(values)) {
        values = arrayUtils.removeElement(values, '');

        value = values.join(' ');
      }

      setSearchValue(value);
    }

    if (!value) {
      if (isEmpty(receivers)) return;

      let bodyPayload = getBodyPayloadFiltered(applyFiltered);

      bodyPayload = {
        ...bodyPayload,
        ...(currentTab === 1 && { gender: BUSINESS_OF_RECEIVER_TYPE }),
      };

      setReceivers(null);
      fetchReceiversFiltered({
        token,
        body: bodyPayload,
        page: PAGE_DEFAULT,
        setReceivers,
        setCurrentPage,
        setTotalPages,
        setTotalElements,
      });
    }
  }, DEBOUNCE_SEC);

  const handleOnChange = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    debounce(value);
  };

  const searchByName = async (value) => {
    let bodyPayload = getBodyPayloadFiltered(applyFiltered);

    bodyPayload = {
      ...bodyPayload,
      name: value,
      ...(currentTab === 1 && { gender: BUSINESS_OF_RECEIVER_TYPE }),
    };

    setReceivers(null);
    fetchReceiversFiltered({
      token,
      body: bodyPayload,
      page: PAGE_DEFAULT,
      setReceivers,
      setCurrentPage,
      setTotalPages,
      setTotalElements,
      setFetching,
    });
  };
  const handleSearchReceiver = () => {
    setFetching(true);

    searchByName(searchValue);

    setSortOrder(RECEIVER_TABLE_SORT_ORDER_INITIAL);
  };

  const handleOnKeyDown = (e) => {
    const { key } = e;

    if (key === ENTER_KEY_DOWN) {
      handleSearchReceiver();
    }
  };

  return (
    <SearchReceiverStyled>
      <SearchInput
        name={RECEIVER_SEARCH_INPUT_FIELD}
        type="text"
        value={searchValue || ''}
        placeholder={t('receivers_search_by_receiver_name')}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
      />
      <SearchIconStyled
        src={SearchIcon}
        width={24}
        height={24}
        alt=""
        onClick={handleSearchReceiver}
      />
    </SearchReceiverStyled>
  );
};

const SearchReceiverStyled = styled.div`
  position: relative;
  height: 44px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: unset;
  }
`;

const SearchInput = styled.input`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  border: 1px solid #b0b6bd;
  border-radius: 12px;
  background: var(--ds-c-white);
  width: calc(100% - 52px);
  height: 44px;
  padding: 10px;
  padding-right: 32px;
  float: left;

  &:focus,
  &:focus-visible {
    border: 1px solid #b0b6bd !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #b0b6bd;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    width: 207px;
  }
`;
const SearchIconStyled = styled.img`
  position: absolute;
  top: 10px;
  left: calc(100% - 84px);
  cursor: pointer;
  user-select: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    left: 174px;
  }
`;

export default SearchReceiver;
