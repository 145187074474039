import ExchangeRateIcon from 'assets/icons/exchange-rate-icon.svg';
import FavReceiverIcon from 'assets/icons/fav-receiver.svg';
import MobileBankingIcon from 'assets/icons/mobile-banking.svg';
import MasterCardIcon from 'assets/icons/payments/master-card-icon.svg';
import PayidIcon from 'assets/icons/payments/payid-icon.svg';
import PoliIcon from 'assets/icons/payments/poli-icon.svg';
import VisaIcon from 'assets/icons/payments/visa-icon.svg';
import Discount40 from 'assets/icons/percents/40.gif';
import Discount50 from 'assets/icons/percents/50.gif';
import Discount60 from 'assets/icons/percents/60.gif';
import Discount70 from 'assets/icons/percents/70.gif';
import Discount80 from 'assets/icons/percents/80.gif';
import HHMTInstructionBankStep1 from 'assets/images/instruction/bank/hhmt-step-1.png';
import HHMTInstructionBankStep2 from 'assets/images/instruction/bank/hhmt-step-2.png';
import HHMTInstructionBankStep3 from 'assets/images/instruction/bank/hhmt-step-3.png';
import HHMTInstructionBankStep4 from 'assets/images/instruction/bank/hhmt-step-4.png';
import RemoxInstructionBankStep1 from 'assets/images/instruction/bank/remox-step-1.png';
import RemoxInstructionBankStep2 from 'assets/images/instruction/bank/remox-step-2.png';
import RemoxInstructionBankStep3 from 'assets/images/instruction/bank/remox-step-3.png';
import RemoxInstructionBankStep4 from 'assets/images/instruction/bank/remox-step-4.png';
import HHMTInstructionPayIdStep1 from 'assets/images/instruction/payid/hhmt-step-1.png';
import HHMTInstructionPayIdStep2 from 'assets/images/instruction/payid/hhmt-step-2.png';
import HHMTInstructionPayIdStep3 from 'assets/images/instruction/payid/hhmt-step-3.png';
import HHMTInstructionPayIdStep4 from 'assets/images/instruction/payid/hhmt-step-4.png';
import RemoxInstructionPayIdStep1 from 'assets/images/instruction/payid/remox-step-1.png';
import RemoxInstructionPayIdStep2 from 'assets/images/instruction/payid/remox-step-2.png';
import RemoxInstructionPayIdStep3 from 'assets/images/instruction/payid/remox-step-3.png';
import RemoxInstructionPayIdStep4 from 'assets/images/instruction/payid/remox-step-4.png';

export const AMOUNT_FROM_DEFAULT = 20;
export const AMOUNT_TO_DEFAULT = 10000;

export const PAGE_DEFAULT = 1;
export const SIZE_PAGE_LIMIT_DEFAULT = 10;

export const SEARCH_BY_DEFAULT = 'receiverName';
export const FX_SEARCH_BY_DEFAULT = 'orderRef';

export const DASHBOARD_TABS = [
  {
    label: 'dashboard_tab_recent_transaction',
  },
  {
    label: 'dashboard_tab_favorite_receiver',
  },
];
export const DASHBOARD_MOBILE_TABS = [
  {
    label: 'label_today_exchange_rate',
    icon: ExchangeRateIcon,
  },
  {
    label: 'dashboard_tab_recent_transaction',
    icon: MobileBankingIcon,
  },
  {
    label: 'dashboard_tab_favorite_receiver',
    icon: FavReceiverIcon,
  },
];
export const FX_CALCULATOR_TABS = [
  {
    label: 'label_you_buy',
  },
  {
    label: 'label_you_sell',
  },
];

export const RECENT_TRANSACTION_TABLE_HEADING = {
  Reference: 'refNumber',
  Receiver: 'receiverFullname',
  Country: 'countryName',
  Status: 'status',
  'Sending Date': 'createDateStr',
  'Sending amount': 'amount',
};

export const TRANSACTION_TABLE_HEADING = {
  Reference: 'refNumber',
  Receiver: 'receiverFullname',
  Country: 'countryCode',
  Status: 'status',
  'Sending Date': 'createDate',
  'Sending amount': 'amount',
};

export const TRANSACTION_TABLE_HEADING_TRANSLATE = {
  Reference: 'label_ref_number',
  Receiver: 'label_receiver_name',
  Country: 'label_country',
  Status: 'label_status',
  'Sending Date': 'label_sending_date',
  'Sending amount': 'label_sending_amount',
};

export const TRANSACTION_TABLE_HEADING_UNABLE_SORT = ['Reference', 'Status'];

export const STATUS_SUBMITTED = 'SUBMITTED';
export const STATUS_DELIVERING = 'DELIVERING';
export const STATUS_PROCESSING = 'PROCESSING';
export const STATUS_COMPLETED = 'COMPLETED';
export const STATUS_CANCELLED = 'CANCELLED';
export const STATUS_ON_HOLD = 'ON HOLD';
export const STATUS_PENDING = 'PENDING';
export const STATUS_RETURNED = 'RETURNED';
export const STATUS_BOOKING = 'BOOKING';
export const STATUS_REJECTED = 'REJECTED';
export const TRANSACTION_STATUS_CLASS_REFERENCE = {
  [STATUS_SUBMITTED]: 'submitted',
  [STATUS_DELIVERING]: 'delivering',
  [STATUS_PROCESSING]: 'processing',
  [STATUS_COMPLETED]: 'completed',
  [STATUS_CANCELLED]: 'cancelled',
  [STATUS_ON_HOLD]: 'on-hold',
};
export const TRANSACTION_STATUS_TRANSLATE_REFERENCE = {
  [STATUS_SUBMITTED]: 'transactions_status_8',
  [STATUS_DELIVERING]: 'transactions_status_2',
  [STATUS_PROCESSING]: 'transactions_status_3',
  [STATUS_COMPLETED]: 'transactions_status_4',
  [STATUS_CANCELLED]: 'transactions_status_5',
  [STATUS_ON_HOLD]: 'transactions_status_7',
};

export const METHOD_PICKUP = 'PICKUP';
export const METHOD_BANK = 'BANK';
export const METHOD_HOME = 'HOME';
export const METHOD_DEPOSIT = 'DEPOSIT';
export const METHOD_POLI = 'POLI';
export const METHOD_EPAYMENT = 'EPAYMENT';
export const METHOD_EWALLET = 'EWALLET';
export const METHOD_PAYID = 'PAYID';

export const PAYOUT_METHOD_REFERENCE = {
  [METHOD_PICKUP]: 'CASH PICKUP',
  [METHOD_BANK]: 'BANK TRANSFER',
  [METHOD_HOME]: 'HOME DELIVERY',
  [METHOD_EWALLET]: 'E-WALLET',
};

export const METHOD_TRANSLATE_REFERECES = {
  'E-WALLET': 'label_ewallet',
  'BANK TRANSFER': 'label_bank_transfer',
  'CASH PICKUP': 'label_cash_pickup',
  'HOME DELIVERY': 'label_home_delivery',
};

export const PAYMENT_METHOD_REFERENCE = {
  [METHOD_DEPOSIT]: {
    label: 'BANK TRANSFER',
    iconSrc: null,
  },
  [METHOD_PAYID]: {
    label: 'label_payid',
    iconSrc: PayidIcon,
  },
  [METHOD_POLI]: {
    label: null,
    iconSrc: PoliIcon,
  },
  [METHOD_EPAYMENT]: {
    label: null,
    iconSrc: VisaIcon,
    iconSrc2: MasterCardIcon,
  },
};

export const PAYMENT_METHOD_TEXT_REFERENCE = {
  [METHOD_DEPOSIT]: 'label_bank_transfer',
  [METHOD_PAYID]: 'label_payid',
  [METHOD_EPAYMENT]: 'label_credit_debit_card',
};

export const SENDING_AMOUNT_DISCOUNT_PERCENTS = [
  {
    range: [10.0, 200.0],
    percent: '40%',
    imgSrc: Discount40,
    tooltip: `popup_discount_tooltip_1`,
  },
  {
    range: [200.01, 400.0],
    percent: '50%',
    imgSrc: Discount50,
    tooltip: `popup_discount_tooltip_2`,
  },
  {
    range: [400.01, 800.0],
    percent: '50%',
    imgSrc: Discount50,
    tooltip: `popup_discount_tooltip_2`,
  },
  {
    range: [800.01, 1400.0],
    percent: '60%',
    imgSrc: Discount60,
    tooltip: `popup_discount_tooltip_3`,
  },
  {
    range: [1400.0, 1800.0],
    percent: '70%',
    imgSrc: Discount70,
    tooltip: `popup_discount_tooltip_4`,
  },
  {
    range: [1800.01, 3000.0],
    percent: '70%',
    imgSrc: Discount70,
    tooltip: `popup_discount_tooltip_4`,
  },
  {
    range: [3000.01, 4200.0],
    percent: '80%',
    imgSrc: Discount80,
    tooltip: `popup_discount_tooltip_5`,
  },
  {
    range: [4200.01, 6000.0],
    percent: '80%',
    imgSrc: Discount80,
    tooltip: `popup_discount_tooltip_5`,
  },
  {
    range: [6000.01, 7000.0],
    percent: '80%',
    imgSrc: Discount80,
    tooltip: `popup_discount_tooltip_5`,
  },
  {
    range: [7000.01, 8000.0],
    percent: '80%',
    imgSrc: Discount80,
    tooltip: `popup_discount_tooltip_5`,
  },
  {
    range: [8000.01, 9000.0],
    percent: '80%',
    imgSrc: Discount80,
    tooltip: `popup_discount_tooltip_5`,
  },
  {
    range: [9000.01, 10000.0],
    percent: '80%',
    imgSrc: Discount80,
    tooltip: `popup_discount_tooltip_5`,
  },
  {
    range: [10000.01],
    percent: '80%',
    imgSrc: Discount80,
    tooltip: `popup_discount_tooltip_5`,
  },
];

export const INSTRUCTION_BANK_IMAGES_CAROUSEL = [
  {
    desc: 'popup_instruction_bank_transfer_step_1',
    imgHHMTSrc: HHMTInstructionBankStep1,
    imgRemoxSrc: RemoxInstructionBankStep1,
  },
  {
    desc: 'popup_instruction_bank_transfer_step_2',
    imgHHMTSrc: HHMTInstructionBankStep2,
    imgRemoxSrc: RemoxInstructionBankStep2,
  },
  {
    desc: 'popup_instruction_bank_transfer_step_3',
    imgHHMTSrc: HHMTInstructionBankStep3,
    imgRemoxSrc: RemoxInstructionBankStep3,
  },
  {
    desc: 'popup_instruction_bank_transfer_step_4',
    imgHHMTSrc: HHMTInstructionBankStep4,
    imgRemoxSrc: RemoxInstructionBankStep4,
  },
];

export const INSTRUCTION_PAYID_IMAGES_CAROUSEL = [
  {
    desc: 'popup_instruction_payid_step_1',
    imgHHMTSrc: HHMTInstructionPayIdStep1,
    imgRemoxSrc: RemoxInstructionPayIdStep1,
  },
  {
    desc: 'popup_instruction_payid_step_2',
    imgHHMTSrc: HHMTInstructionPayIdStep2,
    imgRemoxSrc: RemoxInstructionPayIdStep2,
  },
  {
    desc: 'popup_instruction_payid_step_3',
    imgHHMTSrc: HHMTInstructionPayIdStep3,
    imgRemoxSrc: RemoxInstructionPayIdStep3,
  },
  {
    desc: 'popup_instruction_payid_step_4',
    imgHHMTSrc: HHMTInstructionPayIdStep4,
    imgRemoxSrc: RemoxInstructionPayIdStep4,
  },
];

export const TRANSACTION_TABLE_SORT_ORDER_INITIAL = {
  Receiver: null,
  Country: null,
  Status: null,
  'Sending Date': null,
  'Sending amount': null,
};

export const TRANSACTIONS_FILTER_INITIAL = {
  countriesSelected: null,
  statusSelected: null,
  dateFromSelected: '',
  dateToSelected: '',
  amountFrom: null,
  amountTo: null,
};
