import CarouselPrev from 'assets/ver2/icons/carousel-control-prev.svg';
import CarouselNext from 'assets/ver2/icons/carousel-control-next.svg';
import { format } from 'helpers';
import useSmartLook from 'hooks/smartlook/useSmartLook';
import { useCameraCheck } from 'hooks/useCameraCheck';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import styled from 'styled-components';
import {
  COMMON_PHONE,
  DESKTOP_MIN_WIDTH,
  HHMT_PHONE,
  HHMT_START_REGISTER_REQUIREDS,
  LINK_REGISTRATION_HHMT_VIDEO_EMBED,
  LINK_REGISTRATION_REMOX_VIDEO_EMBED,
  REMOX_PHONE,
  REMOX_START_REGISTER_REQUIREDS,
} from '../../../../constants';
import ButtonCommon from '../../../common/ButtonCommon';
import AllowCameraAccessPopup from '../../AllowCameraAccessPopup/mobile';

const StartRegisterMobile = ({ setStarted }) => {
  const { t } = useLang();
  const { isTablet } = useDetectDevice();
  const { isHHMTTheme } = useTheme();

  const [index, setIndex] = useState(0);
  const [isOpenAllowCameraAccessPopup, setOpenAllowCameraAccessPopup] =
    useState(true);
  const { checkAllowTakePhoto } = useCameraCheck();
  const { recordAPI } = useSmartLook({ isInit: false });

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleOnToggleAllowCameraAccessPopup = () => {
    setOpenAllowCameraAccessPopup(!isOpenAllowCameraAccessPopup);
  };

  const handleOnStartRegister = () => {
    setStarted(true);

    recordAPI.start();
  };

  const terms = t('label_terms_conditions_text_01');
  const hasTerms = terms !== 'label_terms_conditions_text_01' ? true : false;

  return (
    <StartRegisterMobileStyled>
      {!checkAllowTakePhoto && isOpenAllowCameraAccessPopup && (
        <AllowCameraAccessPopup
          isOpen={!checkAllowTakePhoto && isOpenAllowCameraAccessPopup}
          onClose={handleOnToggleAllowCameraAccessPopup}
        />
      )}
      <Paragraph
        dangerouslySetInnerHTML={{ __html: t('registration_text_watch_video') }}
      />
      {hasTerms && (
        <Terms>
          *{t('label_terms_conditions_text_01')}
        </Terms>
      )}
      <VideoEmbed>
        <iframe
          width={isTablet ? 528 : '100%'}
          height={isTablet ? 300 : 185}
          src={
            isHHMTTheme
              ? t(LINK_REGISTRATION_HHMT_VIDEO_EMBED)
              : t(LINK_REGISTRATION_REMOX_VIDEO_EMBED)
          }
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </VideoEmbed>

      <Paragraph style={{ marginTop: '24px' }}>
        {t('registration_text_check_below_information')}
      </Paragraph>
      <CarouselStyled activeIndex={index} onSelect={handleSelect} controls={true} >
        {(isHHMTTheme
          ? HHMT_START_REGISTER_REQUIREDS
          : REMOX_START_REGISTER_REQUIREDS
        ).map((item, i) => (
          <CarouselItem key={i}>
            <ImageStyled>
              <img className="d-block" src={item.imgUrl} alt="carousel-item" />
            </ImageStyled>
            <Carousel.Caption>
              <p>{t(item.label)}</p>
            </Carousel.Caption>
          </CarouselItem>
        ))}
      </CarouselStyled>
      <CallUs style={{ width: isTablet && '528px' }}>
        {t('registration_text_call_us')}{' '}
        <span>
          <a href={`tel:${isHHMTTheme ? HHMT_PHONE : REMOX_PHONE}`}>
            {isHHMTTheme
              ? format.toMobileNumberStr(HHMT_PHONE)
              : format.toMobileNumberStr(REMOX_PHONE)}
          </a>{' '}
          -{' '}
          <a href={`tel:${COMMON_PHONE}`}>
            {format.toMobileNumberStr(COMMON_PHONE)}
          </a>
        </span>
      </CallUs>
      <StartFixed>
        <ButtonCommon
          value={t('button_start')}
          onClick={handleOnStartRegister}
          styles={{
            margin: '0px',
            width: '100%',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
        />
      </StartFixed>
    </StartRegisterMobileStyled>
  );
};

const StartRegisterMobileStyled = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  padding-bottom: 136px;
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const Terms = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);
  font-style: italic;
  margin-bottom: 8px;
`;

const CallUs = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  & span {
    margin: 0;
  }

  & a {
    color: #002FF1;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      color: var(--ds-c-blue) !important;
    }
  }
`;

const CarouselStyled = styled(Carousel)`
  width: 100%;
  height: 200px;
  background-color: var(--ds-c-white);

  & .carousel-inner {
    height: 200px;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 24px;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
  }

  .carousel-control-prev-icon {
    background-image: url(${CarouselPrev});
  }

  .carousel-control-next-icon {
    background-image: url(${CarouselNext});
  }

  & .carousel-indicators {
    margin-bottom: 0;
    bottom: -20px;

    button {
      background: var(--bg-primary-disabled) !important;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      border: none;
    }

    & .active {
      background: var(--bg-primary) !important;
    }
  }
`;
const CarouselItem = styled(Carousel.Item)`
  height: 100%;
  background-color: var(--ds-c-white);

  & .carousel-caption {
    bottom: 0px;
    top: 108px;
    padding: 0;

    p {
      font-family: var(--ff-primary);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;

      color: var(--ds-c-grey-dark);
      margin-bottom: 0px;
    }
  }
`;
const ImageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  img {
    width: 80px;
    height: 80px;
  }
`;

const VideoEmbed = styled.div`
  display: flex;
  justify-content: center;
`;

const StartFixed = styled.div`
  position: fixed;
  bottom: 0px;
  z-index: 2;
  background: var(--ds-c-white);
  width: 100%;
  margin-inline: -24px;
  padding: 16px 24px;
  border-top: 1px solid var(--ds-c-grey-disabled);
`;

export default StartRegisterMobile;
