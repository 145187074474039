import { format } from 'helpers';
import useSmartLook from 'hooks/smartlook/useSmartLook';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';
import {
  COMMON_PHONE,
  DESKTOP_MIN_WIDTH,
  HHMT_PHONE,
  HHMT_START_REGISTER_REQUIREDS,
  LINK_REGISTRATION_HHMT_VIDEO_EMBED,
  LINK_REGISTRATION_REMOX_VIDEO_EMBED,
  REMOX_PHONE,
  REMOX_START_REGISTER_REQUIREDS,
} from '../../../constants';
import ButtonCommon from '../../common/ButtonCommon';

const StartRegister = ({ setStarted }) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const { recordAPI } = useSmartLook({ isInit: false });

  const handleOnStartRegister = () => {
    setStarted(true);

    recordAPI.start();
  };

  const terms = t('label_terms_conditions_text_01');
  const hasTerms = terms !== 'label_terms_conditions_text_01' ? true : false;

  return (
    <StartRegisterStyled>
      <Paragraph
        dangerouslySetInnerHTML={{ __html: t('registration_text_watch_video') }}
      />
      {hasTerms && (
        <Terms>
          *{t('label_terms_conditions_text_01')}
        </Terms>
      )}
      <VideoEmbed>
        <iframe
          width="528"
          height="300"
          src={
            isHHMTTheme
              ? t(LINK_REGISTRATION_HHMT_VIDEO_EMBED)
              : t(LINK_REGISTRATION_REMOX_VIDEO_EMBED)
          }
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </VideoEmbed>

      <Paragraph style={{ marginTop: '24px', width: '624px' }}>
        {t('registration_text_check_below_information')}
      </Paragraph>
      <ItemRequireWrap>
        {(isHHMTTheme
          ? HHMT_START_REGISTER_REQUIREDS
          : REMOX_START_REGISTER_REQUIREDS
        ).map((item, i) => (
          <ItemRequired key={i}>
            <ImageStyled>
              <img className="d-block" src={item.imgUrl} alt="carousel-item" />
            </ImageStyled>
            <ItemLabel>{t(item.label)}</ItemLabel>
          </ItemRequired>
        ))}
      </ItemRequireWrap>
      <StartFixed>
        <ButtonCommon
          value={t('button_start')}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          styles={{
            margin: '0px',
            marginInline: 'auto',
            width: '315px',
          }}
          onClick={handleOnStartRegister}
          isUseKeyDown
          isFill={true}
        />
      </StartFixed>
      <CallUs>
        {t('registration_text_call_us')}
        <span>
          <a href={`tel:${isHHMTTheme ? HHMT_PHONE : REMOX_PHONE}`}>
            {isHHMTTheme
              ? t('registration_text_hhmt_landline_1')
              : t('registration_text_remox_landline')}
          </a>{' '}
          -{' '}
          <a href={`tel:${COMMON_PHONE}`}>
            {format.toMobileNumberStr(COMMON_PHONE)}
          </a>
        </span>
      </CallUs>
    </StartRegisterStyled>
  );
};

const StartRegisterStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin-top: 0px;
  width: 880px;
  height: fit-content;
  margin-inline: auto;
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  color: var(--ds-c-grey-dark);
  margin-bottom: 24px;
`;

const Terms = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  font-style: italic;

  color: var(--ds-c-grey-dark);
  margin-bottom: 24px;
`;

const VideoEmbed = styled.div`
  height: 300px;
  margin-bottom: 16px;
`;

const CallUs = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;

  & span {
    margin: 0;
    margin-left: 4px;
  }

  & a {
    color: #002FF1;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      color: var(--ds-c-blue-hover) !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ItemRequireWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;
const ItemRequired = styled.div`
  width: 220px;
  height: 180px;
  background-color: var(--ds-c-white);
`;
const ImageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;

  img {
    width: 80px;
    height: 80px;
  }
`;
const ItemLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 200px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 0px;
  margin-inline: auto;
  padding: 0 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const StartFixed = styled.div`
  width: 100%;
  height: 40px;
  background: var(--ds-c-white);
  margin-top: 24px;
`;

export default StartRegister;
