/* eslint-disable no-unused-vars */
import { LogUtil } from 'components/common/LogUtil';
import { HHMT_THEME, isDevelopmentEnv } from 'constants';
import useStore from 'hooks/useStore';
import { useEffect } from 'react';
import Smartlook from 'smartlook-client';

const TAG = 'smartlook';

const useSmartLook = ({ isInit = false, email = '' }) => {
  const { state } = useStore();
  const { currentUser } = state;

  const emailIdentify = email || currentUser?.email;

  const isSmartLookInit = isInit && !isDevelopmentEnv;

  const getSmartLookProjectKey = () => {
    const isHHMT = process.env.REACT_APP_APP_NAME === HHMT_THEME;

    if (isHHMT) {
      return process.env.REACT_APP_SMARTLOOK_PROJECT_KEY_HHMT;
    } else {
      return process.env.REACT_APP_SMARTLOOK_PROJECT_KEY_REMOX;
    }
  };

  const triggerInit = () => {
    Smartlook.init(getSmartLookProjectKey(), { cookies: false }, () => {
      LogUtil.log(TAG, 'Smartlook is now initialized!');
    });
  };

  const triggerIdentifyVisitor = (userId) => {
    if (userId) {
      Smartlook.identify(userId);
      LogUtil.log(TAG, 'Smartlook is identified');
      return;
    }

    LogUtil.log(TAG, 'Smartlook is NOT identify');
  };

  const triggerSensitiveData = () => {
    Smartlook.record({ emails: true, forms: true, numbers: true });
    LogUtil.log(TAG, 'Smartlook is sensitive data');
  };

  const triggerRecordStart = () => {
    triggerInit();
    triggerIdentifyVisitor(emailIdentify);
    triggerSensitiveData();
  };

  const triggerRecordPause = () => {
    Smartlook.pause();
    LogUtil.log(TAG, 'Smartlook is now paused!');
  };

  const triggerRecordResume = () => {
    Smartlook.resume();
    LogUtil.log(TAG, 'Smartlook is now resume!');
  };

  const triggerEndRecordCurrentUserSession = () => {
    Smartlook.anonymize();
    LogUtil.log(TAG, 'Smartlook is now ending record current user session!');
  };

  const triggerRecordPauseAndEndRecordCurrentUserSessio = () => {
    triggerRecordPause();
    triggerEndRecordCurrentUserSession();
  };

  const recordAPI = {
    identify: triggerIdentifyVisitor,
    start: triggerRecordStart,
    pause: triggerRecordPause,
    resume: triggerRecordResume,
    close: triggerRecordPauseAndEndRecordCurrentUserSessio,
  };

  useEffect(() => {
    if (isSmartLookInit) {
      recordAPI.start();
    }
  }, [isSmartLookInit]);

  return {
    recordAPI,
  };
};

export default useSmartLook;
