/* eslint-disable no-unused-vars */
import { api } from 'api';
import {
  default as IntroductionHHMTIcon,
  default as IntroductionRemoxIcon,
} from 'assets/ver2/icons/introduction-hhmt-grey-fill-icon.svg';
import { getNumberUnmaskStr } from 'components/FormEditReceiver/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  METHOD_BANK,
  METHOD_DEPOSIT,
  METHOD_EPAYMENT,
  METHOD_EWALLET,
  METHOD_HOME,
  METHOD_PAYID,
  METHOD_PICKUP,
  METHOD_POLI,
  METHOD_TRANSLATE_REFERECES,
  PAYMENT_METHOD_REFERENCE,
  SENDING_AMOUNT_DISCOUNT_PERCENTS,
  STATUS_CANCELLED,
  STATUS_COMPLETED,
  STATUS_DELIVERING,
  STATUS_ON_HOLD,
  STATUS_PROCESSING,
  STATUS_SUBMITTED,
} from 'constants';
import { CloseBlueIcon } from 'constants/icon';
import { format, formatter } from 'helpers';
import useCountryConfig from 'hooks/useCountryConfig';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import useThrottle from 'hooks/useThrottle';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { SET_TRANSACTION_DETAILS } from 'store/action';
import styled from 'styled-components';
import { numberUtils, stringUtils } from 'utils';
import CustomCalculatorFormAccordion from '../../../CustomCalculatorFormAccordion';
import InstructionPopupMobile from '../../../InstructionPopup/mobile';
import TransactionStatus from '../../../TransactionStatus';

const FormTransactionDetailsMobile = ({
  isOpen,
  information,
  setInformation,
  transactions,
  setTransactions,
  applyFiltered,
  searchValue,
  currentSearchBy,
  currentPage,
  setCurrentPage,
  setTotalElements,
  setTotalPages,
  onClose,
  onClick,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { isHHMTTheme } = useTheme();
  const throttle = useThrottle();
  const { isDesktop } = useDetectDevice();

  const { transactionDetails } = state;

  const {
    refNumber,
    status,
    fullName,
    fullAddress,
    mobile,
    phone,
    payout,
    receiveAmount,
    currencyCode,
    rateValue,
    countryCode,
    currencyCodeAnother,
    receiveAmountAnother,
    rateValueAnother,
    payment,
    sendingDate,
    sendAmount,
    paid,
    feeAmount,
    surchargeAmount,
    totalPayAmount,
    discountAmount,
    dueDateTime,
    discountApplied,
  } = information || transactionDetails;

  const isDiscountApplied = Boolean(Number(discountAmount));

  const countryConfig = useCountryConfig(countryCode);
  const config = useGetConfig({ countryConfig, dmCode: payout?.dmCode });
  const {
    mobile: mobileConfig,
    phone: phoneConfig,
    accountNumber: accountNumberConfig,
    ewalletNumber: ewalletNumberConfig,
  } = config;
  const accountNumberFormat = formatter.formatParse(
    accountNumberConfig?.format
  );
  const ewalletNumberFormat = formatter.formatParse(
    ewalletNumberConfig?.format
  );

  const [isOpenInstructionPopup, setOpenInstructionPopup] = useState(false);
  const [originalFee, setOriginalFee] = useState(0);

  const mobileFormat = formatter.formatParse(mobileConfig?.format);
  const mobileNumberMask = formatter.mask(
    getNumberUnmaskStr(mobile, mobileFormat),
    mobileFormat
  );

  const phoneFormat = formatter.formatParse(phoneConfig?.format);
  const phoneNumberMask = formatter.mask(
    getNumberUnmaskStr(phone, phoneFormat),
    phoneFormat
  );

  // payout code
  const isPayoutMethodByHome = payout.dmCode === METHOD_HOME;
  const isPayoutMethodByPickup = payout.dmCode === METHOD_PICKUP;
  const isPayoutMethodByBank = payout.dmCode === METHOD_BANK;
  const isPayoutMethodByEWallet = payout.dmCode === METHOD_EWALLET;

  // payment method
  const isPaymentMethodByDeposit = payment.paymentMethod === METHOD_DEPOSIT;
  const isPaymentMethodByPayId = payment.paymentMethod === METHOD_PAYID;
  const isPaymentMethodByEPayment = payment.paymentMethod === METHOD_EPAYMENT;

  useEffect(() => {
    return () => {
      dispatch({
        type: SET_TRANSACTION_DETAILS,
        payload: null,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOriginalFee = async (countryCode) => {
    try {
      const { data } = await api.lookupOriginalFee(
        countryCode,
        payout.dmCode,
        sendAmount,
        receiveAmountAnother ? 'AUD' : currencyCode
      );

      if (data) {
        setOriginalFee(numberUtils.mathRound(data?.feeAmount));
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    if (countryCode) {
      throttle(() => {
        fetchOriginalFee(countryCode);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  const discountPercent = SENDING_AMOUNT_DISCOUNT_PERCENTS.find((o) => {
    if (o.range.length === 1 && sendAmount >= o.range[0]) return o;

    if (sendAmount >= o.range[0] && sendAmount <= o.range[1]) return o;

    return null;
  });

  const isShowDiscountPercent = Boolean(
    originalFee && originalFee !== feeAmount && !currencyCodeAnother
  );

  const instructionTextReference = {
    [STATUS_SUBMITTED]: () => {
      if (isPaymentMethodByDeposit) {
        return stringUtils.replaceBreakLine(
          t('transactions_summary_desc_13', {
            dueDateTime,
          })
        );
      }

      if (isPaymentMethodByPayId) {
        return stringUtils.replaceBreakLine(
          t('transactions_summary_desc_17', {
            dueDateTime,
          })
        );
      }

      if (isPaymentMethodByEPayment) {
        return stringUtils.replaceBreakLine(
          t('transactions_summary_desc_18', {
            dueDateTime,
          })
        );
      }

      return null;
    },
    [STATUS_PROCESSING]: () => {
      return stringUtils.replaceBreakLine(t('transactions_summary_desc_4'));
    },
    [STATUS_DELIVERING]: () => {
      if (isPayoutMethodByBank) {
        return stringUtils.replaceBreakLine(t('transactions_summary_desc_19'));
      }

      if (isPayoutMethodByHome) {
        return stringUtils.replaceBreakLine(t('transactions_summary_desc_5'));
      }

      if (isPayoutMethodByPickup) {
        return stringUtils.replaceBreakLine(
          t('transactions_summary_desc_6', {
            payoutPin: payout?.payoutPIN,
          })
        );
      }

      if (isPayoutMethodByEWallet) {
        return stringUtils.replaceBreakLine(t('transactions_summary_desc_20'));
      }

      return null;
    },
    [STATUS_COMPLETED]: () => {
      if (isPayoutMethodByBank) {
        return stringUtils.replaceBreakLine(t('transactions_summary_desc_8'));
      }

      if (isPayoutMethodByPickup) {
        return stringUtils.replaceBreakLine(t('transactions_summary_desc_12'));
      }

      if (isPayoutMethodByHome) {
        return stringUtils.replaceBreakLine(t('transactions_summary_desc_15'));
      }

      if (isPayoutMethodByEWallet) {
        return stringUtils.replaceBreakLine(t('transactions_summary_desc_21'));
      }

      return null;
    },
    [STATUS_CANCELLED]: () => {
      if (!paid) {
        return stringUtils.replaceBreakLine(t('transactions_summary_desc_9'));
      }

      return stringUtils.replaceBreakLine(t('transactions_summary_desc_10'));
    },
    [STATUS_ON_HOLD]: () => {
      return stringUtils.replaceBreakLine(t('transactions_summary_desc_11'));
    },
  };

  const payoutMethodReference = {
    [METHOD_PICKUP]: {
      text: payout.pickupOffice?.fullAddress,
      spanText: '',
    },
    [METHOD_BANK]: {
      text: payout.bank?.bankName,
      spanText: `${t('label_name').toUpperCase()}: ${
        payout.bank?.accountName
      } <br/> ${t('label_number').toUpperCase()}: ${
        payout.bank?.accountNumber
          ? formatter.mask(
              getNumberUnmaskStr(
                payout.bank?.accountNumber,
                accountNumberFormat
              ),
              accountNumberFormat
            )
          : ''
      }`,
    },
    [METHOD_HOME]: {
      text: fullAddress,
      spanText: '',
    },
    [METHOD_EWALLET]: {
      text: payout.ewallet?.ewalletProviderName,
      spanText: `ID: ${
        payout.ewallet?.ewalletNumber
          ? formatter.mask(
              getNumberUnmaskStr(
                payout.ewallet?.ewalletNumber,
                ewalletNumberFormat
              ),
              ewalletNumberFormat
            )
          : ''
      }`,
    },
  };

  const paymentMethodReference = {
    [STATUS_SUBMITTED]: () => {
      if (
        payment.paymentMethod === METHOD_POLI ||
        payment.paymentMethod === METHOD_EPAYMENT
      ) {
        return {
          text: '-',
          spanText: null,
          iconSrc: null,
        };
      }

      if (payment.paymentMethod === METHOD_DEPOSIT) {
        return {
          text: t(
            METHOD_TRANSLATE_REFERECES[
              PAYMENT_METHOD_REFERENCE[METHOD_DEPOSIT].label
            ]
          ),
          spanText: null,
          iconSrc: null,
        };
      }

      if (payment.paymentMethod === METHOD_PAYID) {
        return {
          text: t(PAYMENT_METHOD_REFERENCE[METHOD_PAYID].label),
          spanText: null,
          iconSrc: null,
        };
      }
    },
    [STATUS_PROCESSING]: () => {
      if (payment.paymentMethod === METHOD_POLI) {
        return {
          text: null,
          spanText: null,
          iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_POLI].iconSrc,
        };
      }

      if (payment.paymentMethod === METHOD_DEPOSIT) {
        return {
          text: t(
            METHOD_TRANSLATE_REFERECES[
              PAYMENT_METHOD_REFERENCE[METHOD_DEPOSIT].label
            ]
          ),
          spanText: null,
          iconSrc: null,
        };
      }

      if (payment.paymentMethod === METHOD_PAYID) {
        return {
          text: t(PAYMENT_METHOD_REFERENCE[METHOD_PAYID].label),
          spanText: null,
          iconSrc: null,
        };
      }

      if (payment.paymentMethod === METHOD_EPAYMENT) {
        return {
          text: `${t('label_visa_end_with')} ${
            payment.creaditCardNo?.split('*')?.slice(-1)[0]
          }`,
          spanText: null,
          iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_EPAYMENT].iconSrc,
        };
      }
    },
    [STATUS_DELIVERING]: () => {
      if (payment.paymentMethod === METHOD_POLI) {
        return {
          text: null,
          spanText: null,
          iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_POLI].iconSrc,
        };
      }

      if (payment.paymentMethod === METHOD_DEPOSIT) {
        return {
          text: t(
            METHOD_TRANSLATE_REFERECES[
              PAYMENT_METHOD_REFERENCE[METHOD_DEPOSIT].label
            ]
          ),
          spanText: null,
          iconSrc: null,
        };
      }

      if (payment.paymentMethod === METHOD_PAYID) {
        return {
          text: t(PAYMENT_METHOD_REFERENCE[METHOD_PAYID].label),
          spanText: null,
          iconSrc: null,
        };
      }

      if (payment.paymentMethod === METHOD_EPAYMENT) {
        return {
          text: `${t('label_visa_end_with')} ${
            payment.creaditCardNo?.split('*')?.slice(-1)[0]
          }`,
          spanText: null,
          iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_EPAYMENT].iconSrc,
        };
      }
    },
    [STATUS_COMPLETED]: () => {
      if (payment.paymentMethod === METHOD_POLI) {
        return {
          text: null,
          spanText: null,
          iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_POLI].iconSrc,
        };
      }

      if (payment.paymentMethod === METHOD_DEPOSIT) {
        return {
          text: t(
            METHOD_TRANSLATE_REFERECES[
              PAYMENT_METHOD_REFERENCE[METHOD_DEPOSIT].label
            ]
          ),
          spanText: null,
          iconSrc: null,
        };
      }

      if (payment.paymentMethod === METHOD_PAYID) {
        return {
          text: t(PAYMENT_METHOD_REFERENCE[METHOD_PAYID].label),
          spanText: null,
          iconSrc: null,
        };
      }

      if (payment.paymentMethod === METHOD_EPAYMENT) {
        return {
          text: `${t('label_visa_end_with')} ${
            payment.creaditCardNo?.split('*')?.slice(-1)[0]
          }`,
          spanText: null,
          iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_EPAYMENT].iconSrc,
        };
      }
    },
    [STATUS_CANCELLED]: () => {
      if (paid) {
        if (payment.paymentMethod === METHOD_POLI) {
          return {
            text: null,
            spanText: null,
            iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_POLI].iconSrc,
          };
        }

        if (payment.paymentMethod === METHOD_DEPOSIT) {
          return {
            text: t(
              METHOD_TRANSLATE_REFERECES[
                PAYMENT_METHOD_REFERENCE[METHOD_DEPOSIT].label
              ]
            ),
            spanText: null,
            iconSrc: null,
          };
        }

        if (payment.paymentMethod === METHOD_PAYID) {
          return {
            text: t(PAYMENT_METHOD_REFERENCE[METHOD_PAYID].label),
            spanText: null,
            iconSrc: null,
          };
        }

        if (payment.paymentMethod === METHOD_EPAYMENT) {
          return {
            text: `${t('label_visa_end_with')} ${
              payment.creaditCardNo?.split('*')?.slice(-1)[0]
            }`,
            spanText: null,
            iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_EPAYMENT].iconSrc,
          };
        }
      }

      return {
        text: '-',
        spanText: null,
        iconSrc: null,
      };
    },
    [STATUS_ON_HOLD]: () => {
      if (paid) {
        if (payment.paymentMethod === METHOD_POLI) {
          return {
            text: null,
            spanText: null,
            iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_POLI].iconSrc,
          };
        }

        if (payment.paymentMethod === METHOD_DEPOSIT) {
          return {
            text: t(
              METHOD_TRANSLATE_REFERECES[
                PAYMENT_METHOD_REFERENCE[METHOD_DEPOSIT].label
              ]
            ),
            spanText: null,
            iconSrc: null,
          };
        }

        if (payment.paymentMethod === METHOD_PAYID) {
          return {
            text: null,
            spanText: null,
            iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_PAYID].iconSrc,
          };
        }

        if (payment.paymentMethod === METHOD_EPAYMENT) {
          return {
            text: `${t('label_visa_end_with')} ${
              payment.creaditCardNo?.split('*')?.slice(-1)[0]
            }`,
            spanText: null,
            iconSrc: PAYMENT_METHOD_REFERENCE[METHOD_EPAYMENT].iconSrc,
          };
        }
      }

      return {
        text: '-',
        spanText: null,
        iconSrc: null,
      };
    },
  };

  const isShowSurchargeField = payment?.paymentMethod === METHOD_EPAYMENT;

  const totalLabelReference = {
    [STATUS_SUBMITTED]: () => {
      return t('label_total_to_pay');
    },
    [STATUS_PROCESSING]: () => {
      return t('label_total_paid');
    },
    [STATUS_DELIVERING]: () => {
      return t('label_total_paid');
    },
    [STATUS_COMPLETED]: () => {
      return t('label_total_paid');
    },
    [STATUS_CANCELLED]: () => {
      if (paid) {
        return t('label_total_paid');
      }

      return t('label_total_to_pay');
    },
    [STATUS_ON_HOLD]: () => {
      return t('label_total_paid');
    },
  };

  const isShowIntroductionIcon = [METHOD_DEPOSIT, METHOD_PAYID].includes(
    payment.paymentMethod
  );

  const introductionIconReference = {
    [STATUS_SUBMITTED]: () => {
      if (
        payment.paymentMethod === METHOD_POLI ||
        payment.paymentMethod === METHOD_EPAYMENT
      ) {
        return false;
      }

      return true;
    },
    [STATUS_PROCESSING]: () => {
      if (isShowIntroductionIcon) {
        return true;
      }

      return false;
    },
    [STATUS_DELIVERING]: () => {
      if (isShowIntroductionIcon) {
        return true;
      }

      return false;
    },
    [STATUS_COMPLETED]: () => {
      if (isShowIntroductionIcon) {
        return true;
      }

      return false;
    },
    [STATUS_CANCELLED]: () => {
      if (!paid) {
        return false;
      }

      return true;
    },
    [STATUS_ON_HOLD]: () => {
      if (!paid) {
        return false;
      }

      return true;
    },
  };

  const renderActionsButton = () => {
    if (status === STATUS_SUBMITTED) {
      return (
        <>
          <ButtonCommon
            value={t('button_other_payments')}
            onClick={onClick}
            styles={{
              margin: '0px',
              width: '100%',
            }}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
          />
        </>
      );
    }

    return (
      <ButtonCommon
        value={t('button_close')}
        onClick={onClose}
        styles={{
          margin: '0px',
          width: isDesktop ? '50%' : '100%',
          float: 'right',
          marginInline: 'auto',
        }}
        color="var(--c-primary)"
        background="var(--bg-primary)"
      />
    );
  };

  const renderPaymentValue = () => (
    <>
      {paymentMethodReference[status]?.()?.iconSrc ? (
        <>
          <img src={paymentMethodReference[status]?.()?.iconSrc} alt="" />
          {introductionIconReference[status]?.() && (
            <img
              src={isHHMTTheme ? IntroductionHHMTIcon : IntroductionRemoxIcon}
              alt=""
              onClick={handleOnToggleInstructionPopup}
              style={{
                marginLeft: '8px',
                cursor: 'pointer',
                paddingBottom: '0px',
              }}
            />
          )}
          {paymentMethodReference[status]?.()?.text && (
            <span className="credit-card">
              {paymentMethodReference[status]?.()?.text}
            </span>
          )}
        </>
      ) : (
        <>
          {paymentMethodReference[status]?.()?.text}
          {introductionIconReference[status]?.() && (
            <img
              src={isHHMTTheme ? IntroductionHHMTIcon : IntroductionRemoxIcon}
              alt=""
              onClick={handleOnToggleInstructionPopup}
              style={{
                marginLeft: '8px',
                cursor: 'pointer',
                paddingBottom: '4px',
              }}
            />
          )}
          {paymentMethodReference[status]?.()?.spanText && (
            <>
              <br />
              <span>{paymentMethodReference[status]?.().spanText}</span>
            </>
          )}
        </>
      )}
    </>
  );

  const handleOnToggleInstructionPopup = () => {
    setOpenInstructionPopup(!isOpenInstructionPopup);
  };

  const renderContactNumber = () => {
    if (mobileConfig?.visible && phoneConfig?.visible) {
      if (mobileNumberMask && phoneNumberMask) {
        return `${mobileNumberMask || '-'} <br/> ${phoneNumberMask || '-'}`;
      }

      if (mobileNumberMask && !phoneNumberMask) {
        return `${mobileNumberMask || '-'}`;
      }

      if (!mobileNumberMask && phoneNumberMask) {
        return `${phoneNumberMask || '-'}`;
      }

      return `${mobileNumberMask || '-'}`;
    }

    if (mobileConfig?.visible && !phoneConfig?.visible) {
      return `${mobileNumberMask || '-'}`;
    }

    if (!mobileConfig?.visible && phoneConfig?.visible) {
      return `${phoneNumberMask || '-'}`;
    }

    return '-';
  };

  const labelPayoutMethodReference = {
    [METHOD_HOME]: t('label_delivery_to'),
    [METHOD_PICKUP]: t('label_pickup_at'),
    [METHOD_BANK]: t('label_credit_to'),
    [METHOD_EWALLET]: t('label_credit_to'),
  };

  return (
    <>
      <style>
        {`
          .modal-backdrop {
            display: none;
          }
        `}
      </style>

      {isOpenInstructionPopup && (
        <InstructionPopupMobile
          isOpen={isOpenInstructionPopup}
          onClose={handleOnToggleInstructionPopup}
          method={payment.paymentMethod}
        />
      )}

      <ModalStyled show={isOpen} onHide={() => {}} animation={false}>
        <ModalHeader>
          <HeaderTop>
            <SectionTitle>{t('label_transaction_summary')}</SectionTitle>
            <ButtonCommon
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
              isFill={false}
              styles={{
                paddingInline: 0,
              }}
              buttonIconStyles={{
                height: '29px',
                marginLeft: '0px',
                gap: '0px',
                paddingInline: 0,
              }}
              iconSrc={CloseBlueIcon}
              onClick={onClose}
            />
          </HeaderTop>
          <RefStatusWrap>
            <RefText>
              <span>REF NO.</span> {`${refNumber}`}
            </RefText>
            <TransactionStatus transaction={information} />
          </RefStatusWrap>
          <IntructionText
            dangerouslySetInnerHTML={{
              __html: instructionTextReference[status]?.(),
            }}
          />
        </ModalHeader>
        <ModalBody>
          <FieldWrap
            styles={{
              alignItems: 'start',
              padding: '10px 0',
            }}
          >
            <FieldLabel>{t('label_receiver')}</FieldLabel>
            <FieldValue>{fullName}</FieldValue>
          </FieldWrap>
          {(mobileConfig?.visible || phoneConfig?.visible) && (
            <FieldWrap
              styles={{
                alignItems: 'start',
                padding: '10px 0',
              }}
            >
              <FieldLabel>{t('label_contact_number')}</FieldLabel>
              <FieldValue
                dangerouslySetInnerHTML={{ __html: renderContactNumber() }}
              />
            </FieldWrap>
          )}
          <FieldWrap
            styles={{
              alignItems: 'start',
              padding: '10px 0',
            }}
            style={{
              marginInline: '-16px',
              paddingInline: '16px',
              backgroundColor: '#FBFBFB',
            }}
          >
            <FieldLabel>{labelPayoutMethodReference[payout.dmCode]}</FieldLabel>
            <FieldValue>
              {payoutMethodReference[payout.dmCode]?.text}
              {Boolean(payoutMethodReference[payout.dmCode]?.spanText) && (
                <>
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: payoutMethodReference[payout.dmCode].spanText,
                    }}
                  />
                </>
              )}
            </FieldValue>
          </FieldWrap>
          <FieldWrap
            styles={{
              alignItems: 'start',
              padding: '10px 0',
            }}
          >
            <FieldLabel>{t('label_receiving_amount')}</FieldLabel>
            <FieldValue>
              {receiveAmount &&
                `${format.toAmountCentStr(receiveAmount)} ${currencyCode}`}
              <br />
              <span className="rate">{`(1 AUD = ${format.toAmountCentStr(
                rateValue
              )} ${currencyCode})`}</span>
              {Boolean(currencyCodeAnother) && (
                <>
                  <br />
                  <br />
                  {`${format.toAmountCentStr(
                    receiveAmountAnother
                  )} ${currencyCodeAnother}`}
                  <br />
                  <span className="rate">{`(1 AUD = ${format.toAmountCentStr(
                    rateValueAnother
                  )} ${currencyCodeAnother})`}</span>
                </>
              )}
            </FieldValue>
          </FieldWrap>
          <FieldWrap
            styles={{
              alignItems: 'start',
              padding: '10px 0',
            }}
          >
            <FieldLabel>{t('label_payment_method')}</FieldLabel>
            <FieldValue
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              {renderPaymentValue()}
            </FieldValue>
          </FieldWrap>
          <FieldWrap>
            <FieldLabel>{t('label_sending_date')}</FieldLabel>
            <FieldValue>{sendingDate}</FieldValue>
          </FieldWrap>
        </ModalBody>
        <ModalCalculatorForm>
          <CustomCalculatorFormAccordion
            status={status}
            totalLabelReference={totalLabelReference}
            totalPayAmount={totalPayAmount}
            sendAmount={sendAmount}
            feeAmount={feeAmount}
            isShowDiscountPercent={isShowDiscountPercent}
            discountPercent={discountPercent}
            originalFee={originalFee}
            isShowSurchargeField={isShowSurchargeField}
            surchargeAmount={surchargeAmount}
            isDiscountApplied={isDiscountApplied}
            discountAmount={discountAmount}
            discountApplied={discountApplied}
          />
        </ModalCalculatorForm>
        <ModalFooter>{renderActionsButton()}</ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  z-index: 0;
  background-color: #ffffff;

  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  & .modal-dialog {
    position: absolute;
    top: 70px;
    margin: 0;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    transform: none;
  }

  & .modal-content {
    padding: 0px;
    border-radius: 0px;
    box-shadow: none;
    background: var(--ds-c-white);
    width: 100%;
    height: calc(100vh - 120px);
    margin-inline: auto;
    border: none;
  }
`;
const ModalHeader = styled(Modal.Header)`
  padding: 0;
  margin-bottom: 0;
  display: block;
  padding-bottom: 16px;
  border-bottom: none;
`;

const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  border-bottom: 1px solid var(--ds-c-blue-remox-light);
`;

const SectionTitle = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--ds-c-blue);

  margin: 0;
  padding: 0;
`;
const RefStatusWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 16px 16px 0 16px;
`;
const RefText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--ds-c-blue-hyperlink-default);
  text-align: left;

  margin: 0;
  padding: 0;

  & span {
    font-family: var(--ff-primary);
    font-weight: 600;
    color: var(--ds-c-black);
  }
`;
const IntructionText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey);
  white-space: break-spaces;

  margin: 0;
  padding: 0 16px 0 16px;
  color: var(--ds-c-black);

  & span {
    color: var(--ds-c-green-default);
  }
`;

const ModalBody = styled(Modal.Body)`
  padding: 0 16px;
  padding-bottom: 145px;
`;
const FieldWrap = styled.div`
  display: flex;
  align-items: ${(props) => props.styles?.alignItems || 'center'};
  justify-content: start;
  min-height: 40px;
  height: fit-content;
  padding: ${(props) => props.styles?.padding};
`;
const FieldLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue);

  margin: 0;
  padding: 0;
  min-width: 150px;
`;
const FieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: right;
  color: var(--ds-c-black);

  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 8px;

  & span {
    color: var(--ds-c-grey-neutral);
  }

  & span.rate {
    color: var(--ds-c-blue);
  }

  & span.credit-card {
    text-transform: initial;
    color: var(--ds-c-grey-dark);
    margin-left: 4px;
  }
`;

const ModalCalculatorForm = styled.div`
  position: fixed;
  bottom: 77px;
  left: 0px;
  width: 100%;
  padding: 0;
  display: flex;
`;

const ModalFooter = styled(Modal.Footer)`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  padding: 16px 0 16px 0;
  border-top: 1px solid #eef2f5;
  padding-inline: 16px;
  background: var(--ds-c-white);
  border-radius: 0px
}
`;

export default FormTransactionDetailsMobile;
