import { fetchTransactionInformation } from 'components/FXTransactions/func';
import useAuth from 'hooks/useAuth';
import useStore from 'hooks/useStore';
import { useState } from 'react';
import styled from 'styled-components';
import { arrayUtils, objectUtils } from 'utils';
import NoResult from '../NoResult';
import NoTransaction from '../NoTransaction';
import TransactionItem from './components/TransactionItem';

import Endpoint from 'components/common/Endpoint';
import FormTransactionDetails from 'components/fx/components/FormTransactionDetails';
import { FX_TRANSACTION_ADD_PREFIX } from 'constants';
import Cookies from 'helpers/cookies';
import { FX_MAKE_PAYMENT_KEY } from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useCommonStore from 'hooks/useCommonStore';
import { isEmpty, isNull } from 'lodash';
import { useNavigate } from 'react-router-dom';

const TransactionList = ({
  transactions,
  setTransactions,
  searchValue,
  applyFiltered,
  currentSearchBy,
  currentPage,
  setCurrentPage,
  setTotalElements,
  setTotalPages,
  isFetching,
  setFetching,
}) => {
  const { dispatch } = useStore();
  const { token } = useAuth();
  const navigate = useNavigate();

  const [information, setInformation] = useState(null);

  const isShowNoResult =
    !isFetching &&
    isNull(transactions) &&
    (!objectUtils.isObjectEmpty(applyFiltered) || searchValue);
  const isShowNoTransaction = arrayUtils.isArrayEmpty(transactions);
  const isShowBlank = isNull(transactions);

  const { transactionDetails, setTransactionDetails } =
    useFxFormAddTransactionStore();
  const { setLoading } = useCommonStore();

  const handleOnCloseTransactionDetailsPopup = () => {
    setTransactionDetails(null);
  };
  const handleOnOpenTransactionDetailsPopup = async (transaction) => {
    if (isEmpty(transaction)) return;

    const { orderRef, orderStatus } = transaction || {};
    setLoading(true);
    const transactionDetails = await fetchTransactionInformation(
      token,
      orderRef,
      orderStatus
    );
    setTransactionDetails(transactionDetails);
    setLoading(false);
  };
  const handleOnMakePayment = () => {
    if (isEmpty(transactionDetails)) return;
    const { orderRef } = transactionDetails;
    navigate(`${FX_TRANSACTION_ADD_PREFIX}?orderRef=${orderRef}`);
    Cookies.set(FX_MAKE_PAYMENT_KEY, true);
  };

  if (isShowNoResult) {
    return <NoResult />;
  }
  if (isShowNoTransaction) {
    return <NoTransaction />;
  }
  if (isShowBlank) {
    return null;
  }
  return (
    <>
      {!isEmpty(transactionDetails) && (
        <FormTransactionDetails
          isOpen={!isEmpty(transactionDetails)}
          onClose={handleOnCloseTransactionDetailsPopup}
          onClick={handleOnMakePayment}
        />
      )}

      <TransactionListStyled>
        {transactions?.map((tran) => (
          <TransactionItem
            key={tran?.orderRef}
            transaction={tran}
            onClick={() => handleOnOpenTransactionDetailsPopup(tran)}
          />
        ))}
        <Endpoint />
      </TransactionListStyled>
    </>
  );
};

const TransactionListStyled = styled.div`
  width: 100%;
  height: fit-content;
`;

export default TransactionList;
