/* eslint-disable no-unused-vars */
import ArrowDownIcon from 'assets/icons/arrow-down-icon.svg';
import {
  AMOUNT_NUMBER_REGEX,
  CURRENCY_FLAG_REFERENCES,
  DEBOUNCE_SEC,
  FX_CALCULATOR_DESKTOP_MIN_WIDTH,
} from 'constants';
import { CURRENCY_NAME_REFERENCE } from 'constants/currency';
import {
  FX_CALCULATOR_FORM_BUY_NAME,
  FX_CALCULATOR_FORM_PAY_NAME,
  PAY_AMOUNT_INPUT_MAX,
} from 'constants/fx';
import { format } from 'helpers';
import useFxCalculator from 'hooks/fx/useFxCalculator';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useInputMoveCursorToEnd from 'hooks/useInputMoveCursorToEnd';
import useLang from 'hooks/useLang';
import { forwardRef } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { numberUtils } from 'utils';
import SelectCurrencyDrawer from '../SelectCurrencyDrawer';

const FormInputAmountValidation = forwardRef(
  (
    {
      field,
      currencies,
      information,
      setInformation,
      validation,
      setValidation,
      setChangeCurrency,
      setRounding,
    },
    ref
  ) => {
    const { t } = useLang();

    const { getCurrentMinDenomination } = useFxCalculator();

    const { currentCurrencySelected, sellRate } = information || {};
    const { currencyCode } = currentCurrencySelected || {};
    const { rate } = sellRate || {};

    const isYouPayField = field.name === FX_CALCULATOR_FORM_PAY_NAME;
    const isYouBuyField = field.name === FX_CALCULATOR_FORM_BUY_NAME;

    const currentMinDenomination = getCurrentMinDenomination(
      currencyCode,
      currencies
    );

    const { inputMoveCursorToEnd } = useInputMoveCursorToEnd();

    const handleYouPayAmountValidation = (
      information,
      validation,
      isDisable
    ) => {
      let newValidation = { ...validation };

      if (!isDisable) {
        newValidation = handleYouBuyAmountValidation(
          information,
          newValidation,
          true
        );
      }

      return newValidation;
    };

    const handleYouBuyAmountValidation = (
      information,
      validation,
      isDisable = false
    ) => {
      let newValidation = { ...validation };

      if (!isDisable) {
        newValidation = handleYouPayAmountValidation(
          information,
          newValidation,
          true
        );
      }

      return newValidation;
    };

    const handleYouPayOnChange = (value) => {
      if (value > PAY_AMOUNT_INPUT_MAX) {
        value = PAY_AMOUNT_INPUT_MAX;
      }

      let toValue = 0;

      toValue = Math.round(value * rate);

      document.getElementsByName(FX_CALCULATOR_FORM_PAY_NAME)[0].value =
        format.toAmountCentStr(value);
      document.getElementsByName(FX_CALCULATOR_FORM_BUY_NAME)[0].value =
        format.toAmountStr(toValue);

      toValue =
        Math.round((value * rate) / currentMinDenomination) *
        currentMinDenomination;

      value = numberUtils.mathRound(toValue / rate);

      value = Number(value);

      toValue = Number(toValue);

      return [value, toValue];
    };

    const handleYouBuyOnChange = (value) => {
      let convertFromValue = 0;

      convertFromValue = numberUtils.mathRound(value / rate);

      if (convertFromValue > PAY_AMOUNT_INPUT_MAX) {
        convertFromValue = PAY_AMOUNT_INPUT_MAX;
      }

      document.getElementsByName(FX_CALCULATOR_FORM_PAY_NAME)[0].value =
        format.toAmountStr(convertFromValue);
      document.getElementsByName(FX_CALCULATOR_FORM_BUY_NAME)[0].value =
        format.toAmountCentStr(value);

      value =
        Math.round((convertFromValue * rate) / currentMinDenomination) *
        currentMinDenomination;

      convertFromValue = numberUtils.mathRound(value / rate);

      convertFromValue = Number(convertFromValue);

      value = Number(value);

      return [convertFromValue, value];
    };

    const { debounce: handleOnChangeDebounce } = useDebounceCallback(
      (information, setInformation) => {
        setInformation(information);
        setRounding(false);
      },
      DEBOUNCE_SEC
    );

    const handleOnChange = async (e) => {
      const { value, name } = e.target;

      let valueTrim = value ? format.toStrAmount(value.trim()) : '';
      let convertFromValue;
      let toValue;

      setRounding(true);

      if (valueTrim?.length <= 1 && !numberUtils.isNumber(valueTrim)) {
        document.getElementsByName(name)[0].value = '';
        setRounding(false);
        return;
      }

      let newInformation = { ...information };
      let newValidation = {
        ...validation,
        [FX_CALCULATOR_FORM_PAY_NAME]: '',
        [FX_CALCULATOR_FORM_BUY_NAME]: '',
      };

      if (!valueTrim) {
        newValidation = {
          ...newValidation,
          [FX_CALCULATOR_FORM_PAY_NAME]: '',
          [FX_CALCULATOR_FORM_BUY_NAME]: '',
        };
        setValidation(newValidation);

        document.getElementsByName(FX_CALCULATOR_FORM_PAY_NAME)[0].value = '';
        document.getElementsByName(FX_CALCULATOR_FORM_BUY_NAME)[0].value = '';

        newInformation = {
          ...newInformation,
          [FX_CALCULATOR_FORM_PAY_NAME]: '',
          [FX_CALCULATOR_FORM_BUY_NAME]: '',
        };
        handleOnChangeDebounce(newInformation, setInformation);

        setRounding(false);
        return;
      }

      if (!numberUtils.isNumber(valueTrim)) {
        valueTrim = valueTrim.match(AMOUNT_NUMBER_REGEX)[0];

        document.getElementsByName(name)[0].value = valueTrim;
      }

      if (value.includes(' ')) {
        document.getElementsByName(name)[0].value = valueTrim;
      }

      if (isYouPayField) {
        [convertFromValue, toValue] = handleYouPayOnChange(valueTrim);

        newInformation = {
          ...newInformation,
          [FX_CALCULATOR_FORM_PAY_NAME]: convertFromValue,
          [FX_CALCULATOR_FORM_BUY_NAME]: toValue,
        };
      }

      if (isYouBuyField) {
        [convertFromValue, toValue] = handleYouBuyOnChange(valueTrim);

        newInformation = {
          ...newInformation,
          [FX_CALCULATOR_FORM_PAY_NAME]: convertFromValue,
          [FX_CALCULATOR_FORM_BUY_NAME]: toValue,
        };
      }

      if (isYouPayField) {
        newValidation = handleYouPayAmountValidation(
          newInformation,
          newValidation
        );
      }

      if (isYouBuyField) {
        newValidation = handleYouBuyAmountValidation(
          newInformation,
          newValidation
        );
      }

      setValidation(newValidation);

      handleOnChangeDebounce(newInformation, setInformation);
    };

    const handleFormLabelOnClick = (e, name) => {
      e.preventDefault();
      const inputEle = document.getElementsByName(name)[0];

      if (inputEle) {
        inputEle.focus();
      }
    };

    const handleOnChangeCurrency = () => {
      if (isYouPayField) return;
      setChangeCurrency(true);
    };
    const renderCurrencySection = () => {
      if (isYouPayField)
        return (
          <CurrencyLabel>
            <p>{CURRENCY_NAME_REFERENCE['AUD']}</p>
            <p className="currency-code">AUD</p>
          </CurrencyLabel>
        );
      if (isYouBuyField)
        return (
          <CurrencyLabel>
            <SelectCurrencyDrawer
              ref={ref}
              currencies={currencies}
              information={information}
              setInformation={setInformation}
              NavBarToggle={
                <CurrencyDrawer onClick={handleOnChangeCurrency}>
                  <p>{CURRENCY_NAME_REFERENCE[currencyCode]}</p>
                  <img src={ArrowDownIcon} width={16} height={16} />
                </CurrencyDrawer>
              }
            />
            <p className="currency-code">{currencyCode}</p>
          </CurrencyLabel>
        );
      return null;
    };

    const renderFlagIcon = () => {
      if (isYouPayField)
        return <FlagIcon src={CURRENCY_FLAG_REFERENCES['AUD']} />;
      if (isYouBuyField)
        return <FlagIcon src={CURRENCY_FLAG_REFERENCES[currencyCode]} />;
      return null;
    };

    const renderFormInput = () => {
      return (
        <FormControl
          type={field.type}
          inputMode="decimal"
          autoComplete="off"
          name={field.name}
          defaultValue={
            information && information[field.name]
              ? format.toAmountCentStr(information[field.name])
              : ''
          }
          placeholder={field.placeholder}
          isInvalid={validation[field.name]}
          onChange={handleOnChange}
          readOnly={field.isReadOnly}
          onFocus={inputMoveCursorToEnd}
        />
      );
    };

    return (
      <FormGroupStyled>
        <FormLabel onClick={(e) => handleFormLabelOnClick(e, field.name)}>
          {t(field.label)}
          {field.isRequired && <span>*</span>}
        </FormLabel>
        {renderCurrencySection()}
        {renderFormInput()}
        {renderFlagIcon()}
        <FormControlFeedback hidden={false} type="invalid">
          {t(validation[field.name])}
        </FormControlFeedback>
      </FormGroupStyled>
    );
  }
);

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 16px;
  position: relative;

  @media screen and (width: ${FX_CALCULATOR_DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 16px;
  }
`;
const FormLabel = styled(Form.Label)`
  margin: 0;
  margin-bottom: 4px;
  padding: 0;

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--c-fxcalculator-text);

  span {
    color: var(--c-required);
  }
`;
const CurrencyLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  & p {
    margin: 0;
    padding: 0;

    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    text-transform: uppercase;
    color: var(--ds-c-grey-dark);

    &.currency-code {
      font-size: 16px;
      line-height: 20px;
    }

    &.currency-name {
      font-size: 16px;
      line-height: 20px;
    }

    &.currency-code {
      color: var(--ds-c-blue);
      font-weight: 600;
    }
  }
`;

const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: var(--ds-c-blue);
  text-align: right;

  height: 38px;
  border: none;
  border-bottom: 1px solid var(--ds-bg-2);
  border-radius: 0px;
  padding-inline: 0px;
  padding-left: 32px;
  padding-block: 0px;
  padding-bottom: 8px;
  background: var(--ds-c-white);

  &:focus {
    background: var(--ds-c-white) !important;
    border: none;
    border-bottom: 1px solid var(--ds-c-grey-dark) !important;
    color: var(--ds-c-blue);
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: none;
    border-bottom: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-inline: 0px !important;
  }

  &[readonly] {
    background: var(--ds-c-white) !important;
    cursor: not-allowed;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-transform: none;
    color: var(--ds-c-blue);
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 2px;

  @media screen and (width: ${FX_CALCULATOR_DESKTOP_MIN_WIDTH}px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

const FlagIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
  border: 1px solid var(--ds-bg-2);
  border-radius: 50%;
  margin-right: 8px;

  position: absolute;
  bottom: 11px;
  left: 0;
`;

const CurrencyDrawer = styled.div`
  border-radius: 100px;
  border: 1px solid var(--ds-c-blue-hyperlink-default);
  display: flex;
  align-item: center;
  gap: 2px;
  padding-inline: 6px;
  cursor: pointer;

  & p {
    color: var(--ds-c-blue-hyperlink-default);
    font-weight: 600;
  }

  & img {
    filter: var(--ds-f-c-blue-hyperlink);
    object-fit: contain;
    padding: 3.5px;
    width: 16px;
    height: 16px;
  }
`;

export default FormInputAmountValidation;
