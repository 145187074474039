import { datePicker } from 'helpers';

const { RESET_PASSWORD_REPASSWORD_NAME } = require('constants');
const { RESET_PASSWORD_FIELDS } = require('constants');
const { RESET_PASSWORD_PASSWORD_NAME } = require('constants');

const handlePasswordValueValidation = (
  value,
  name,
  information,
  validation
) => {
  if (value && name === RESET_PASSWORD_PASSWORD_NAME) {
    const isValid = Boolean(RESET_PASSWORD_FIELDS[0].checkValidFn(value));

    if (!isValid) {
      validation = {
        ...validation,
        [name]: RESET_PASSWORD_FIELDS[0].msgInvalid,
      };
    }

    if (isValid) {
      validation = {
        ...validation,
        [name]: '',
      };
    }

    if (information[RESET_PASSWORD_REPASSWORD_NAME]) {
      if (value === information[RESET_PASSWORD_REPASSWORD_NAME]) {
        validation = {
          ...validation,
          [RESET_PASSWORD_REPASSWORD_NAME]: '',
        };

        return validation;
      }

      if (value !== information[RESET_PASSWORD_REPASSWORD_NAME]) {
        validation = {
          ...validation,
          [RESET_PASSWORD_REPASSWORD_NAME]:
            RESET_PASSWORD_FIELDS[1].msgNotMatch,
        };
      }
    }
  }

  return validation;
};

const handleRePasswordValueValidation = (
  value,
  name,
  information,
  validation
) => {
  if (value && name === RESET_PASSWORD_REPASSWORD_NAME) {
    const isValid = Boolean(RESET_PASSWORD_FIELDS[1].checkValidFn(value));

    if (!isValid) {
      validation = {
        ...validation,
        [name]: RESET_PASSWORD_FIELDS[1].msgInvalid,
      };

      return validation;
    }

    if (isValid) {
      validation = {
        ...validation,
        [name]: '',
      };
    }

    if (information[RESET_PASSWORD_PASSWORD_NAME]) {
      if (value !== information[RESET_PASSWORD_PASSWORD_NAME]) {
        validation = {
          ...validation,
          [name]: RESET_PASSWORD_FIELDS[1].msgNotMatch,
        };
      }
    }
  }

  return validation;
};

const handleFieldEmptyValidation = (value, name, validation, msgRequired) => {
  if (!value) {
    validation = {
      ...validation,
      [name]: msgRequired,
    };
  }

  return validation;
};

export const checkFormInputValidation = (
  value,
  field,
  information,
  validation
) => {
  const { name, msgRequired } = field;

  validation = handleFieldEmptyValidation(value, name, validation, msgRequired);

  validation = handlePasswordValueValidation(
    value,
    name,
    information,
    validation
  );

  validation = handleRePasswordValueValidation(
    value,
    name,
    information,
    validation
  );

  return validation;
};

export const checkTokenExpired = (token) => {
  const decodeToken = atob(token);
  const tokenExpiryTime = parseInt(decodeToken?.split('|')?.[1] || 0);

  return datePicker.getExpired(new Date(tokenExpiryTime));
};
