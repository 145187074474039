import {
  RESET_BACKDROP_STORE,
  RESET_REGISTER_INFORMATION_STORE,
} from 'constants';
import { createContext, useReducer } from 'react';

import {
  RESET_ADD_TRANSACTION_INFORMATION_STORE,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
  RESET_PORTAL_MODAL_STORE,
} from 'constants';
import {
  ServiceAlertsByCountryCodeDefault,
  ServiceAlertsByPaymentsDefault,
} from 'hooks/service-alerts/model/ServiceAlertsLocalStore';
import {
  SET_ADD_RECEIVER_INFORMATION,
  SET_ADD_TRANSACTION_CURRENT_STEP,
  SET_ADD_TRANSACTION_INFORMATION,
  SET_CONFIG_OCCUPATION,
  SET_CONFIG_PURPOSE,
  SET_CONFIG_PURPOSE_OF_BUSINES,
  SET_CONFIG_RELATIONSHIP,
  SET_CONFIG_RELATIONSHIP_OF_BUSINESS,
  SET_CONFIG_SOURCES_OF_WEALTH,
  SET_CURRENT_BUSINESS_RECEIVER,
  SET_CURRENT_RECEIVER,
  SET_CURRENT_USER,
  SET_EDIT_RECEIVER_CURRENT_STEP,
  SET_EDIT_RECEIVER_INFORMATION,
  SET_EVENT_AVAILABLE,
  SET_EXCHANGE_RATES,
  SET_NEW_NOTIFICATION,
  SET_NOTIFICATIONS,
  SET_OPEN_REFRESH_TOKEN_POPUP,
  SET_PORTAL_MODAL,
  SET_REFRESH_RECEIVER_LIST,
  SET_REGISTER_INFORMATION,
  SET_REMITTANCE_RATES,
  SET_SERVICE_ALERTS_BY_COUNTRY_CODE,
  SET_SERVICE_ALERTS_BY_PAYMENTS,
  SET_SHOW_BACKDROP,
  SET_SHOW_LOADING,
  SET_SHOW_PENDING,
  SET_SHOW_SEARCHING,
  SET_TOKEN_TRANSACTION,
  SET_TRANSACTION_DETAILS,
  SET_MENU_EXPAND,
} from './action';

const initialState = {
  isRefreshToken: false,
  backdrop: RESET_BACKDROP_STORE,
  isLoading: false,
  isPending: false,
  isSearching: false,
  portalModal: RESET_PORTAL_MODAL_STORE,
  currentUser: null,
  notifications: null,
  exchangeRates: null,
  remittanceRates: null,
  serviceAlertsByCountryCode: ServiceAlertsByCountryCodeDefault,
  serviceAlertsByPayments: ServiceAlertsByPaymentsDefault,
  isNewNotification: false,
  currentReceiver: null,
  currentBusinessReceiver: null,
  registerInformation: RESET_REGISTER_INFORMATION_STORE,
  editReceiverInformation: RESET_EDIT_RECEIVER_INFORMATION_STORE,
  editReceiverCurrentStep: 0,
  addTransactionInformation: RESET_ADD_TRANSACTION_INFORMATION_STORE,
  addTransactionCurrentStep: 0,
  transactionDetails: null,
  tokenTransaction: '',
  configOccupation: null,
  configRelationship: null,
  configRelationshipOfBusiness: null,
  configPurpose: null,
  configPurposeOfBusiness: null,
  configSourcesOfWealth: null,
  eventAvailable: null,
  refreshReceiverList: 0,
  isMenuExpand: true,
};

const store = createContext(initialState);
const { Provider } = store;
let stateStore;
let dispatchStore;

const StateProvider = ({ children }) => {
  let [state, dispatch] = useReducer((state, action) => {
    const { type, payload } = action;

    switch (type) {
      case SET_OPEN_REFRESH_TOKEN_POPUP:
        return {
          ...state,
          isRefreshToken: payload,
        };
      case SET_SHOW_LOADING:
        return {
          ...state,
          isLoading: payload,
        };
      case SET_SHOW_PENDING:
        return {
          ...state,
          isPending: payload,
        };
      case SET_SHOW_SEARCHING:
        return {
          ...state,
          isSearching: payload,
        };
      case SET_PORTAL_MODAL:
        return {
          ...state,
          portalModal: payload,
        };
      case SET_SHOW_BACKDROP:
        return {
          ...state,
          backdrop: {
            ...state.backdrop,
            ...payload,
          },
        };
      case SET_CURRENT_USER:
        return { ...state, currentUser: payload };
      case SET_EXCHANGE_RATES:
        return { ...state, exchangeRates: payload };
      case SET_REMITTANCE_RATES:
        return { ...state, remittanceRates: payload };
      case SET_SERVICE_ALERTS_BY_COUNTRY_CODE:
        return { ...state, serviceAlertsByCountryCode: payload };
      case SET_SERVICE_ALERTS_BY_PAYMENTS:
        return { ...state, serviceAlertsByPayments: payload };
      case SET_NOTIFICATIONS:
        return { ...state, notifications: payload };
      case SET_NEW_NOTIFICATION:
        return { ...state, isNewNotification: payload };
      case SET_CURRENT_RECEIVER:
        return { ...state, currentReceiver: payload };
      case SET_CURRENT_BUSINESS_RECEIVER:
        return { ...state, currentBusinessReceiver: payload };
      case SET_REGISTER_INFORMATION:
        return {
          ...state,
          registerInformation: { ...state.registerInformation, ...payload },
        };
      case SET_EDIT_RECEIVER_INFORMATION:
        return {
          ...state,
          editReceiverInformation: {
            ...state.editReceiverInformation,
            ...payload,
          },
        };
      case SET_ADD_RECEIVER_INFORMATION:
        return {
          ...state,
          editReceiverInformation: {
            ...state.editReceiverInformation,
            ...payload,
          },
        };
      case SET_EDIT_RECEIVER_CURRENT_STEP:
        return {
          ...state,
          editReceiverCurrentStep: payload,
        };
      case SET_ADD_TRANSACTION_INFORMATION:
        return {
          ...state,
          addTransactionInformation: {
            ...state.addTransactionInformation,
            ...payload,
          },
        };
      case SET_ADD_TRANSACTION_CURRENT_STEP:
        return {
          ...state,
          addTransactionCurrentStep: payload,
        };
      case SET_TRANSACTION_DETAILS:
        return {
          ...state,
          transactionDetails: payload,
        };
      case SET_TOKEN_TRANSACTION:
        return {
          ...state,
          tokenTransaction: payload,
        };
      case SET_CONFIG_OCCUPATION:
        return {
          ...state,
          configOccupation: payload,
        };
      case SET_CONFIG_RELATIONSHIP:
        return {
          ...state,
          configRelationship: payload,
        };
      case SET_CONFIG_RELATIONSHIP_OF_BUSINESS:
        return {
          ...state,
          configRelationshipOfBusiness: payload,
        };
      case SET_CONFIG_PURPOSE:
        return {
          ...state,
          configPurpose: payload,
        };
      case SET_CONFIG_PURPOSE_OF_BUSINES:
        return {
          ...state,
          configPurposeOfBusiness: payload,
        };
      case SET_CONFIG_SOURCES_OF_WEALTH:
        return {
          ...state,
          configSourcesOfWealth: payload,
        };
      case SET_EVENT_AVAILABLE:
        return {
          ...state,
          eventAvailable: payload,
        };
      case SET_REFRESH_RECEIVER_LIST:
        return { ...state, refreshReceiverList: payload };
      case SET_MENU_EXPAND:
        return {
          ...state,
          isMenuExpand: payload,
        };
      default:
        return state;
    }
  }, initialState);

  stateStore = state;
  dispatchStore = dispatch;

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { StateProvider, dispatchStore, stateStore, store };
