import NoResultIcon from 'assets/ver2/icons/no-result-icon.svg';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import styled from 'styled-components';

const NoResult = () => {
  const { t } = useLang();
  const { isMobile } = useDetectDevice();

  return (
    <NoResultStyled>
      <img
        src={NoResultIcon}
        width={isMobile ? 150 : 200}
        height={isMobile ? 150 : 200}
        alt=""
      />
      <span>{t('label_no_result_search_desc')}</span>
    </NoResultStyled>
  );
};

const NoResultStyled = styled.div`
  display: grid;
  justify-content: center;
  width: 310px;
  position: relative;

  & img {
    margin-inline: auto;
    margin-bottom: 16px;
  }

  & span {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

   color: var(--ds-c-black);
    margin-bottom: 24px;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & img {
      margin-bottom: 24px;
    }

    & span {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export default NoResult;
