import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import { fetchReceiversFiltered } from 'components/Receivers/func';
import { getBodyPayloadFiltered } from 'components/Transactions/func';
import {
  BUSINESS_OF_RECEIVER_TYPE,
  PAGE_DEFAULT,
  PAGINATION_NEXT_BUTTON,
  PAGINATION_PREV_BUTTON,
  RECEIVER_TABLE_HEADING,
  SORT_DESC,
} from 'constants';
import useAuth from 'hooks/useAuth';
import styled from 'styled-components';
import { objectUtils } from 'utils/func';

const Pagination = ({
  totalPages,
  applyFiltered,
  searchValue,
  sortOrder,
  totalElements,
  currentPage,
  setCurrentPage,
  receivers,
  setReceivers,
  current,
  setCurrent,
  setFetching,
  currentTab,
}) => {
  const { token } = useAuth();

  const isDisableNext = currentPage === totalPages;
  const isDisablePrev = currentPage === PAGE_DEFAULT;

  const fetchReceiversPagination = (page) => {
    const currentFieldSort = Object.keys(
      objectUtils.deletePropIsFalsy(sortOrder)
    )[0];
    const sortPayload = {
      sortField: RECEIVER_TABLE_HEADING[currentFieldSort] || 'createDate',
      sortOrder: sortOrder[currentFieldSort] || SORT_DESC,
    };

    let bodyPayload = getBodyPayloadFiltered(applyFiltered);

    bodyPayload = {
      ...bodyPayload,
      name: searchValue,
      ...(currentTab === 1 && { gender: BUSINESS_OF_RECEIVER_TYPE }),
    };

    setReceivers(null);
    fetchReceiversFiltered({
      token,
      body: bodyPayload,
      sort: sortPayload,
      page,
      setReceivers,
      setCurrentPage,
      setFetching,
    });
  };

  const handleNextPage = () => {
    if (currentPage === totalPages) {
      return;
    }

    if (currentPage === totalPages - 1) {
      setCurrent('');
    } else {
      setCurrent(PAGINATION_NEXT_BUTTON);
    }

    setFetching(true);

    fetchReceiversPagination(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage === PAGE_DEFAULT) {
      return;
    }

    if (currentPage === 2) {
      setCurrent('');
    } else {
      setCurrent(PAGINATION_PREV_BUTTON);
    }

    setFetching(true);

    fetchReceiversPagination(currentPage - 1);
  };

  return (
    <PaginationStyled>
      <ArrowDownIcon
        color={current === PAGINATION_PREV_BUTTON ? '#0E1012' : '#B0B6BD'}
        styles={{
          transform: 'rotate(90deg)',
          marginRight: '25px',
          cursor: isDisablePrev ? 'not-allowed' : 'pointer',
        }}
        onClick={handlePrevPage}
      />
      {`${(currentPage - 1) * 10 + 1}-${
        /* eslint-disable-next-line no-extra-boolean-cast */
        Boolean(receivers.length)
          ? (currentPage - 1) * 10 + receivers.length
          : ''
      } of ${totalElements}`}
      <ArrowDownIcon
        color={current === PAGINATION_NEXT_BUTTON ? '#0E1012' : '#B0B6BD'}
        styles={{
          transform: 'rotate(270deg)',
          marginLeft: '25px',
          cursor: isDisableNext ? 'not-allowed' : 'pointer',
        }}
        onClick={handleNextPage}
      />
    </PaginationStyled>
  );
};

const PaginationStyled = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  float: right;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

export default Pagination;
